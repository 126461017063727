//React
import { useEffect, useState } from "react";
//Material UI
import { Button, DialogTitle, Grid, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uuid, Alert } from "axeleratum-sgc-frontend-library";
import { NotificationHttp } from "../../../core/http/notification.http";
import { makeStyles } from "@material-ui/core/styles";
//Componentes
import { AlertConfirm } from "../../from-library/alerts/AlertConfirm";
import { FormModifiedSigners } from "../../team/users/FormModifiedSigners";
import {
	sendSignBlock,
	updateSigner,
} from "../../../core/http/functionRequests";
import { getCurrentUser } from "../../../core/helpers";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	root: {
		minWidth: "650px !important"
	},
	titleSigners: {
		paddingLeft: "46px",
		paddingBottom: "0px",
	},
	userLogo: {
		paddingLeft: "46px !important",
	},
	divider: {
		padding: "0px 12px 0px 12px",
	},
	pendingSigners: {
		width: "auto",
		height: "450px",
		overflowY: "auto",
		overflowX: "hidden",
	},
	footer: {
		marginBottom: "0.6rem",
	},
}));

const objectSignature = {
	link: "Liga por correo",
	email: "Correo con Contraseña",
	rfc: "R.F.C.",
	token: "Token",
};

export const ReminderFunc = (props) => {
	const classes = useStyles();
	const notificationHttp = new NotificationHttp();

	const LINK = "link";
	const {
		onClose,
		hasOrder,
		canceled,
		users,
		hasNom151,
		documentId,
		activity,
	} = props;

	const [signers, setSigners] = useState(users);
	const [selectedTab, setSelectedTab] = useState("1");
	const [signed, setSigned] = useState([]);
	const [notSigned, setNotSigned] = useState([]);
	const [openAlert, setOpenAlert] = useState(false);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("");
	const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
	const [textConfirm, setTextConfirm] = useState("");
	const [userSelected, setUserSelected] = useState(null);
	const [openForm, setOpenForm] = useState(false);
	const [dataForm, setDataForm] = useState(null);
	const [openLoader, setOpenLoader] = useState(false);
	const [allowModify, setAllowModify] = useState(false);
	const [handleTypeConfirm, setHandleTypeConfirm] = useState(null);
	const [showBlockButton, setShowBlockButton] = useState(false);
	const [userId, setUserId] = useState("");

	const formatText = (text) => {
		if (!text) {
			return "";
		}
		if (text.length > 30) {
			return `${text.substring(0, 29)}...`;
		}
		return text;
	};

	const splitUsers = () => {
		const signed = [];
		const notSigned = [];

		if (signers) {
			signers.forEach((element) => {
				if (element.signed) {
					signed.push(element);
				} else {
					notSigned.push(element);
				}
			});
		}
		setSigned(signed);
		setNotSigned(notSigned);
	};

	const handleConfirmCancel = () => {
		setOpenAlertConfirm(false);
		setHandleTypeConfirm(null);
	};

	const requestReminder = (userId) => {
		notificationHttp
			.signReminder({
				documentId,
				activityId: activity.id,
				receiverId: userSelected ?? userId,
			})
			.then(() => {
				setOpenAlert(true);
				setMessage("Tu recordatorio ha sido enviado con exito");
				setTypeMessage("success");
			})
			.catch(() => {
				setOpenAlert(true);
				setMessage(
					"Tuvimos problemas para enviar el recordatorio intentalo mas tarde"
				);
				setTypeMessage("error");
			});
	};

	const requestBlockSignatures = async () => {
		try {
			await sendSignBlock({ receiverId: userId });
			setMessage("Correo enviado");
			setTypeMessage("success");
		} catch (error) {
			console.log(error);
			const msg =
				typeof error === "object"
					? error[0].msg
					: typeof error === "string"
						? error
						: "error enviando correo.";
			setMessage(msg);
			setTypeMessage("error");
		} finally {
			setOpenAlert(true);
			setUserId("");
		}
	};

	const handleConfirm = () => {
		handleConfirmCancel();
		if (handleTypeConfirm === "reminder") {
			requestReminder();
		}
		if (handleTypeConfirm === "block") {
			requestBlockSignatures();
		}
	};

	const signReminder = (receiverId) => {
		setUserSelected(receiverId);

		const totalLinkSigners = signers.filter(
			(el) => el.signType === LINK && !el.signed
		);

		const signer = totalLinkSigners.find((el) => el.userId === receiverId);

		if (!hasOrder && totalLinkSigners.length > 1 && signer) {
			setHandleTypeConfirm("reminder");
			setTextConfirm(
				`Se enviaran recordatorios a todos los firmantes que no hayan todavia firmado y que tengan el mismo metodo de Liga por correo`
			);
			setOpenAlertConfirm(true);
		} else {
			requestReminder(receiverId);
		}
	};

	const handleModify = (item) => {
		setDataForm(item);
		setOpenForm(true);
	};

	const handleClose = () => {
		setOpenForm(false);
		setDataForm(null);
	};

	const updateSignerRequest = async (formData) => {
		try {
			setOpenLoader(true);
			await updateSigner(documentId, formData);


			setSigners((prevSigners) =>
				prevSigners.map((signer) => {
					if (signer.id === formData.id) {
						return {
							...signer,
							signType: formData.authority,
							...formData,
						};
					} else {
						return signer;
					}
				})
			);
			setOpenAlert(true);
			setMessage("Modificación realizada");
			setTypeMessage("success");
			setDataForm(null);
		} catch (error) {
			console.error(error);
			setOpenForm(true);
			setOpenAlert(true);
			setMessage(error.msg ? error.msg : "Error actualizando firmante");
			setTypeMessage("error");
		} finally {
			setOpenLoader(false);
		}
	};

	const handleSubmit = (formData) => {
		setOpenForm(false);
		updateSignerRequest(formData);
	};

	const buttonReminder = (item) => (
		<Button
			variant="contained"
			color="primary"
			onClick={() => signReminder(item.userId)}>
			Recordar
		</Button>
	);

	const onClickBlock = (item) => {
		setUserId(item.userId);
		setHandleTypeConfirm("block");
		setTextConfirm(
			`Se enviará un correo con la liga para firmar en bloque los documentos pendientes de firma que tenga el usuario.`
		);
		setOpenAlertConfirm(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setMessage("");
		setTypeMessage("");
	};



	useEffect(() => {
		splitUsers();
	}, [signers]);

	useEffect(() => {
		const { authorities, userId, showBlockSignatureButton } = getCurrentUser();
		setAllowModify(
			authorities.includes("Seguimiento a actividades generadas por otros") ||
			(activity.username === userId &&
				authorities.includes("Crear Actividad de Firma"))
		);
		setShowBlockButton(showBlockSignatureButton);
	}, []);

	return (
		<>
			<TabContext value={selectedTab}>
				<DialogTitle className={classes.titleSigners}>Firmantes</DialogTitle>
				<TabList onChange={(evt, value) => setSelectedTab(value)}>
					<Tab label="Firmado" value="1" />
					<Tab label="Por Firmar" value="2" />
				</TabList>

				<TabPanel key={"tab-firmantes-1"} value="1" className="tab-size">
					<Grid container spacing={3}>
						{signed.length > 0 ? (
							signed.map((item) => (
								<Grid
									key={uuid()}
									item
									container
									spacing={3}
									alignItems="center">
									<Grid item xs={3} className={classes.userLogo}>
										<div
											className="user-icon-lg"
											title={item.name}
											style={{ background: item.color }}>
											{item.name.charAt(0)}
										</div>
									</Grid>

									<Grid item xs={9}>
										{item.name} <br />
										<span className="primary-text">{item.email}</span>
									</Grid>

									{/* <Grid container xs className={classes.divider}>
										<div className="col-md-12">
											<hr />
										</div>
									</Grid> */}
								</Grid>
							))
						) : (
							<div className="no-signers-available">No hay firmantes</div>
						)}
					</Grid>
				</TabPanel>

				<TabPanel
					key={"tab-firmantes-2"}
					value="2"
					className={classes.pendingSigners}>
					{
						<Grid container spacing={3} style={{ width: canceled ? '450px' : 'auto' }}>
							{notSigned.length > 0 ? (
								notSigned.map((item, index) => (
									<Grid key={uuid()} container item spacing={3} alignItems="center">
										<Grid item xs={canceled || !allowModify ? 3 : 2} className={classes.userLogo}>
											<div
												className="user-icon-lg"
												title={item.name}
												style={{ background: item.color }}>
												{item.name.charAt(0)}
											</div>
										</Grid>

										<Grid item xs={5}>
											{formatText(item.name)} <br />
											<span className="primary-text">
												{formatText(item.email)}
											</span>
										</Grid>

										{!canceled && (
											<Grid item container xs={allowModify ? 5 : 4} spacing={1}>
												<Grid item container spacing={2}>
													{allowModify && (
														<Grid item container xs sm={6}>
															<Button
																variant="contained"
																color="primary"
																onClick={() => handleModify(item)}>
																Modificar
															</Button>
														</Grid>
													)}

													<Grid
														item
														container
														xs
														sm={allowModify ? 6 : 12}
														justifyContent="flex-end">
														{hasOrder && index === 0
															? buttonReminder(item)
															: !hasOrder
																? buttonReminder(item)
																: null}
													</Grid>
												</Grid>
												{item.inBlock > 1 && showBlockButton && (
													<Grid item container direction="column">
														<Button
															variant="contained"
															color="primary"
															onClick={() => onClickBlock(item)}>
															Correo - Firma en bloque
														</Button>
													</Grid>
												)}
											</Grid>
										)}

										<Grid item container xs={12} className={classes.divider}>
											<div className="col-md-12">
												<hr />
											</div>
										</Grid>
									</Grid>
								))
							) : (
								<div className="col-12 center-text">
									No hay firmas restantes
								</div>
							)}
						</Grid>
					}
				</TabPanel>

				<Grid container spacing={4} xs={12} direction="row-reverse" className={classes.footer}>
					<Grid item>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => onClose()}>
							Cerrar
						</Button>
					</Grid>
				</Grid>
			</TabContext>

			{openForm && dataForm && (
				<FormModifiedSigners
					{...dataForm}
					open={openForm}
					setOpen={handleClose}
					hasNom151={hasNom151}
					submitActions={handleSubmit}
				/>
			)}

			{openAlert && typeMessage && message && (
				<Alert
					open={openAlert}
					title={message}
					onConfirm={handleCloseAlert}
					type={typeMessage}
				/>
			)}

			{openAlertConfirm && (
				<AlertConfirm
					open={openAlertConfirm}
					onCancel={handleConfirmCancel}
					onConfirm={handleConfirm}
					textContent={textConfirm}
				/>
			)}

			<Backdrop className={classes.backdrop} open={openLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};
