import React, { Component } from "react";
import AlertInfo from "@material-ui/lab/Alert";
import { Button, Grid } from "@material-ui/core";
import { Calendar } from "react-calendar";
import { Schedule } from "./schedule";
import { CalendarHttp } from "../../../../core/http/calendar.http";
import moment from "moment";
import { Alert } from "axeleratum-sgc-frontend-library";
import { FormActivityNative } from "../forms/form-activity-native";

export class TabCalendar extends Component {
  calendarHttp = new CalendarHttp();

  constructor(props) {
    super(props);

    this.state = {
      load: false,
      loadActivities: false,
      selectedDate: new Date(),
      markDates: [],
      openNew: false,
      upload: false,
      disabled: false,
      openAlert: false,
      typeMessage: "error",
      message: "",
      disabledButon: true,
    };

    this.getAllCalendar();
  }

  getAllCalendar() {
    this.setState({ disabledButon: true });

    this.calendarHttp.getMarks(
      (resp) => {
        this.setState({ disabledButon: false, markDates: resp });
      },
      (error) => {
        this.setState({ disabledButon: false });
      }
    );
  }

  render() {
    const {
      loadActivities,
      selectedDate,
      markDates,
      openNew,
      upload,
      openAlert,
      typeMessage,
      message,
      disabledButon,
    } = this.state;
    const { onSelectActivity, load, activities, onChange, onSave } = this.props;

    return (
      <React.Fragment>
        {load ? (
          <Grid container style={{ justifyContent: "center", width: "100%" }}>
            <AlertInfo className="load-alert" severity="info">
              Cargando calendario...
            </AlertInfo>
          </Grid>
        ) : (
          <React.Fragment>
            <Alert
              open={openAlert}
              title={message}
              onConfirm={() => this.setState({ openAlert: false })}
              type={typeMessage}
            />

            <FormActivityNative
              open={openNew}
              load={upload}
              onSubmit={(activity) => {
                // debugger;
                //this.setState({ openNew: false, upload: false });
                this.setState({
                  loadActivity: true,
                  openActivity: false,
                  openAlert: true,
                  message: "Actividad creada",
                  typeMessage: "success",
                  openNew: false,
                  upload: false,
                });
                this.getAllCalendar();
                onSave(selectedDate);
              }}
              onCancel={() => this.setState({ openNew: false })}
            />

            <div className="row">
              <Button
                className="mr-3 w-100"
                color="primary"
                variant="contained"
                onClick={() => this.setState({ openNew: true })}
                disabled={disabledButon}
              >
                Crear Actividad
              </Button>
            </div>

            <Calendar
              className="margin-calendar"
              value={selectedDate}
              tileClassName={({ date }, view) => {
                if (markDates.includes(moment(date).format("YYYY-MM-DD"))) {
                  return "highlight-calendar";
                } else {
                  return "";
                }
              }}
              onChange={(date) => {
                onChange(moment(date).format("YYYY-MM-DD"));
                this.setState({ selectedDate: date });
              }}
            />

            <div className="row">
              <div className="col-md-6">
                <h4>Pendientes</h4>
              </div>
            </div>

            <Schedule
              load={loadActivities}
              activities={activities}
              onSelect={(data) =>
                onSelectActivity({
                  dateActivity: moment(selectedDate).format("YYYY-MM-DD"),
                  ...data,
                })
              }
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
