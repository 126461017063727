import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(state => state.authReducer.loggedIn);

  return (
    <Route {...rest} render={props => (
      loggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: `/${localStorage.getItem('clientKey') || "KasiaSolutions"}`, state: { from: props.location } }} />
    )} />
  )
}
