import { authHeader, axiosBusinessRequest } from "../../../configs/axios.config";
import { sortData } from "../../utils";



export const findAllBusiness = async () => {
  try {
    const { data } = await axiosBusinessRequest.get('/companies', { headers: authHeader() });

    const enabled = data.filter(el => el.enabled).map(el => ({ id: el.id, name: el.name }));
    const sorted = sortData(enabled);

    return sorted;

  } catch (error) {
    throw { msg: "Error obteniendo las bóvedas" }
  }
}
// axiosBusinessRequest.get('/companies', { headers: authHeader() })

export const allCompaniesByUser = async () => {
  try {
    const { data } = await axiosBusinessRequest.get('/companies/all-companies-by-user', { headers: authHeader() });

    const companiesData = data.map((business) => ({
      id: business.companyId,
      name: business.name,
    }));
    const sorted = sortData(companiesData);

    return sorted;

  } catch (error) {
    console.error(error);
    throw { msg: "Error obteniendo las bóvedas del usuario" }
  }
}