import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { makeStyles, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUser } from "../../core/helpers";
import { AlertComponent } from "../AlertComponent";
import { deleteFromStorageProvider, getSaveRequest } from "../../core/http/functionRequests";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    backgroundColor: "rgba(33, 70, 183, 0.05)",
    height: "138px",
    textAlign: "center",
    paddingTop: "20px",
    "& p": {
      color: "rgba(122, 146, 197, 0.87)",
    },
    border: "1px dashed rgba(92, 123, 240, 0.6)",
    borderRadius: "6px",
  },
  alertMessage: {
    marginLeft: "27px",
    marginTop: "28px",
    marginRight: "28px",
  },
  p: {
    margin: 0,
  },
}));



function DropzoneFiles(field) {
  const { onInitUploadFile, setDocumentName, setDocumentExtension } = field;
  const classes = useStyles();
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [aborted, setAborted] = useState(false)

  const getUploadParams = async ({ file, meta }) => {
    try {
      const { storageProvider } = getCurrentUser();
      const body = new FormData()
      body.append('file', file)

      const extension = /(?:\.([^.]+))?$/.exec(meta.name)[0]
      meta.extension = extension;
      meta.storageProvider = storageProvider;

      if (storageProvider === "kaleido") {
        const sizeLimit = 52428800 // 50 MB
        if (meta.size >= sizeLimit) {
          setOpenAlertConfirm(true)
          return { url: "" }
        }
        return { url: 'https://httpbin.org/post', body, meta }
      }

      const { request, name } = await getSaveRequest(extension)
      meta.blobname = name;

      setDocumentExtension && setDocumentExtension(extension);
      setDocumentName && setDocumentName(name)

      return { ...request, body, meta }
    } catch (error) {
      console.error("No se pudo obtener la url");
      return { url: "" }
    }
  };

  const restoreStates = () => {
    setDocumentName && setDocumentName(null)
    setDocumentExtension && setDocumentExtension(null)
    onInitUploadFile && onInitUploadFile(false)
  }

  const handleChangeStatus = ({ file, meta }, status) => {
    if (status === "preparing") {
      setAborted(false)
      onInitUploadFile && onInitUploadFile(true)
    }

    if (status === "restarted") {
      setAborted(false)
      onInitUploadFile && onInitUploadFile(true)
    }

    if (status === "done") {
      onInitUploadFile && onInitUploadFile(false)
      if (meta.storageProvider === "kaleido") {
        field.input.onChange([file])
      } else {
        field.input.onChange([{ size: file.size, extension: meta.extension, storedName: meta.blobname }])
      }
    }

    if (status === "removed" && !aborted) {
      restoreStates()
      if (meta.storageProvider === "blob") {
        const docName = `${meta.blobname}${meta.extension}`
        deleteFromStorageProvider(docName)
      }
      // request para eliminar documento
    }

    if (status === "aborted") {
      setAborted(true)
      restoreStates()
      // no se necesita borrar el documento en este punto
    }
  };

  return (
    <div>

      <Dropzone
        maxFiles={1}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputContent={() => (
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <p className={classes.p}>De click para buscar en tus archivos</p>
          </Grid>
        )}
        styles={{
          dropzone: {
            overflow: "hidden",
            backgroundColor: "rgba(33, 70, 183, 0.1)",
            border: "1px dashed #5C7BF0",
          },
          dropzoneActive: { borderColor: "green" },
        }}
      />
      {openAlertConfirm &&
        <AlertComponent
          open={openAlertConfirm}
          title={'No se puede subir el documento porque tiene un tamaño mayor a 50 MB'}
          onConfirm={() => setOpenAlertConfirm(false)}
          type={"error"}
        />
      }

    </div>
  );
}

export default DropzoneFiles;
