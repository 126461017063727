import React, { Component } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TemplateUsageForm from './template-usage-form';
import { TemplatesHttp } from '../../core/http/templates.http';
import { DocumentsHttp } from '../../core/http/documents.http';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import FormSavePreview from './form-save-preview';
import { CollaboratorsHttp } from '../../core/http/collaborators.http';
import FormSaveContractPreview from './form-save-contract-preview';

const useStyles = () => ({
  paperFullScreen: {
    background: '#ECF2FF'
  },
  appBar: {
    position: 'relative',
    background: 'inherit',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  formContainer: {
    padding: '6px',
    height: '100vh',
  },
  paperForm: {
    background: '#fff',
    height: '100%',
    overflowY: 'auto',
  },
  templateContainer: {
    overflowY: 'auto',
    minHeight: '500px',
    height: '100vh'
  },
  paperTemplate: {
    paddingLeft: '0px',
    ['@media (min-width:768px)']: { paddingLeft: '10px' },
    background: '#ECF2FF'
  }
});

class TemplateUsage extends Component {

  templateHttp = new TemplatesHttp();
  documentHttp = new DocumentsHttp();
  collaboratorHttp = new CollaboratorsHttp();

  constructor(props) {
    super(props);
    this.state = {
      templateInfo: null,
      bookmarks: null,
      bookmarkValues: null,
      initialBookmarkValues: null,
      tmpDocument: null,
      urlPdf: null,
      alertStatus: false,
      alertStatusError: { show: false, message: '' },
      openDialogSaveDocument: false,
      openDialogSaveContract: false,
      fileToSave: null,
      contractType: 'Contrato',
    };
  }

  componentDidMount() {
    this.getTemplateInfo();
    this.getTemplateMarkers();
  }

  componentWillUnmount() {
    if (this.state.tmpDocument !== null) {
      this.deleteAuxTemplateFile();
    }
  }

  getTemplateInfo() {
    const { templateSelected } = this.props;

    this.documentHttp.getDocumentInfo(templateSelected.id, resp => {
      const info = {
        id: resp ? resp._id : templateSelected.id,
        name: resp ? resp.Name : '',
        nameDisplay: resp ? resp.NameDisplay : '',
        description: resp ? resp.Description : '',
        tags: resp.Tags,
        documentTypeId: resp ? resp.DocumentTypeId : '',
        documentType: resp ? resp.DocumentType.Name : '',
        extension: resp ? resp.Extension : ''
      };

      this.setState({ templateInfo: info });
    }, error => { });
  }

  getTemplateMarkers() {
    const { templateSelected, documentSelected } = this.props;
    const params = {
      companyId: this.props.business.id,
      documentId: templateSelected.id,
      path: `Plantillas/${templateSelected.name}`,
      owner: templateSelected.ownerId
    }

    this.templateHttp.getTemplateMarkers(params, (resp) => {
      const bookmarks = resp.bookmarks.map(item => ({
        value: item,
        label: item.replace(/^\[+|\]+$/g, '')
      }));

      this.setState({
        tmpDocument: resp.fileName,
        bookmarks: bookmarks
      }, () => documentSelected ? this.getBookmarkValues() : this.getPreview([]));
    }, (error) => {
      this.setState({
        alertStatusError: { show: true, message: `Ocurrió un error al leer plantilla.` }
      });
    });
  }

  getBookmarkValues() {
    const { documentSelected } = this.props;
    this.templateHttp.getBookmarkValuesByDocumentId(documentSelected.id, (resp) => {
      const bookmarks = resp && resp.bookmarks ? resp.bookmarks : [];
      const initialBookmarkValues = {};
      bookmarks.forEach(item => {
        initialBookmarkValues[item.field.replace(/^\[+|\]+$/g, '')] = item.value;
      });

      this.setState({ initialBookmarkValues: initialBookmarkValues }, () => this.getPreview(bookmarks));
    }, (error) => {
      this.setState({
        alertStatusError: { show: true, message: `Ocurrió un error al cargar contenido existente.` }
      });
    });
  }

  replaceBookmarks(data) {
    const { tmpDocument } = this.state;

    return new Promise((resolve, reject) => {
      this.templateHttp.replaceBookmarks(tmpDocument, data, (resp) => {
        resolve(resp);
      }, (error) => {
        reject(error);
      });
    })
  }

  getPreview(data) {
    this.replaceBookmarks(data).then((resp) => {
      const url = URL.createObjectURL(new Blob([resp], {
        type: "application/pdf"
      }));
      this.setState({
        urlPdf: url
      });
    }).catch((error) => {
      this.setState({
        alertStatusError: { show: true, message: `Ocurrió un error al obtener vista previa.` }
      });
    });
  }

  onClickPreview(formData) {
    const { bookmarks } = this.state;
    const data = bookmarks.map(bookmark => ({
      field: bookmark.value,
      value: formData[bookmark.label]
    }));

    console.log(bookmarks);

    this.getPreview(data);
  }

  onClickSave(formData) {
    const { bookmarks } = this.state;
    const { documentSelected } = this.props;
    const data = bookmarks.map(bookmark => ({
      field: bookmark.value,
      value: formData[bookmark.label]
    }));

    this.replaceBookmarks(data).then((resp) => {
      const file = new Blob([resp], {
        type: "application/pdf"
      });

      if (documentSelected) {
        this.setState({
          fileToSave: file,
          bookmarkValues: data
        }, () => this.updateDocument(data));
      } else {
        this.setState({
          fileToSave: file,
          bookmarkValues: data
        });
      }
    }).catch((error) => {
      this.setState({
        alertStatusError: { show: true, message: `Ocurrió un error al obtener documento.` }
      });
    });
  }

  onSubmitForm(formData, folder) {
    const formD = new FormData()
    const collaborators = formData.users ? formData.users : [];
    const { fileToSave, templateInfo, contractType } = this.state;

    if (folder === null) {
      this.setState({ alertStatusError: { show: true, message: 'Seleccione una ruta.' } })
      return
    }

    formD.append('document', fileToSave);
    formD.append('extension', '.pdf')
    formD.append('size', fileToSave.size)
    formD.append('name', formData.name ? formData.name.trim() : '');
    formD.append('description', formData.description ? formData.description : '');
    formD.append('path', folder ? folder.path : '');
    formD.append('companyId', this.props.business.id);
    if (folder && folder.path !== '') {
      formD.append('folderId', folder.id);
    }
    formD.append('documentTypeId', templateInfo.documentTypeId);
    formD.append('templateId', templateInfo.id);
    formD.append('owner', this.props.currentUser.userId);

    if (formData.tags) {
      formData.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value)
      })
    }

    if (templateInfo.documentType === contractType) {
      formD.append('endDate', formData.endDate);
    }

    this.documentHttp.createDocument(this.props.business.name,
      formD,
      data => {
        if (collaborators.length > 0) {
          this.submitCollaborators(data._id, collaborators);
        }

        this.saveBookmarkValues(data._id);
      },
      error => {
        this.setState({ alertStatusError: { show: true, message: `Ocurrió un error al guardar` } })
      }
    );
  }

  submitCollaborators(documentId, collaborators) {
    const newUserIds = [];
    const newUserEmails = [];

    collaborators.forEach(item => {
      newUserIds.push(item.value);
      newUserEmails.push(item.email);
    });

    this.collaboratorHttp.saveCollaborators(
      documentId,
      newUserIds,
      newUserEmails, (resp) => {
      }, (error) => {
        this.setState({
          alertStatusError: { show: true, message: 'Ocurrió un error al guardar colaboradores.' }
        })
      });
  }

  deleteAuxTemplateFile() {
    const { tmpDocument } = this.state;
    this.templateHttp.deleteAuxTemplateFile(tmpDocument, (resp) => {
    }, (error) => {
    });
  }

  updateDocument(bookmarkValues) {
    const formD = new FormData();
    const { documentSelected, currentUser } = this.props;
    const { fileToSave, templateInfo } = this.state;

    formD.append('document', fileToSave);
    formD.append('extension', '.pdf')
    formD.append('size', fileToSave.size)
    formD.append('name', documentSelected.name);
    formD.append('oldName', documentSelected.name);
    formD.append('oldExtension', '.pdf');

    formD.append('description', documentSelected.description);
    formD.append('path', documentSelected.path);
    formD.append('folderId', documentSelected.folderId);
    formD.append('documentId', documentSelected.id);
    formD.append('owner', currentUser.userId);
    formD.append('companyId', documentSelected.companyId);
    formD.append('documentTypeId', templateInfo.documentTypeId);
    formD.append('templateId', templateInfo.id);

    if (documentSelected.tags) {
      documentSelected.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item);
      });
    }

    this.documentHttp.editDocument(
      formD,
      (data) => {
        this.updateBookmarkValues(documentSelected.id, bookmarkValues);
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: `Ocurrió un error al guardar.`,
          },
        });
      }
    );
  }

  saveBookmarkValues(documentId) {
    const { bookmarkValues } = this.state;
    const data = {
      documentId: documentId,
      bookmarks: bookmarkValues
    };

    this.templateHttp.saveBookmarkValues(data, (resp) => {
      this.setState({ alertStatus: true });
    }, (error) => {
      this.setState({
        alertStatusError: { show: true, message: 'Ocurrió un error al guardar los marcadores.' }
      });

      this.props.onClose();
    });
  }

  updateBookmarkValues(documentId, bookmarkValues) {
    const data = {
      documentId: documentId,
      bookmarks: bookmarkValues
    };
    this.templateHttp.updateBookmarkValues(data, (resp) => {
      this.setState({ alertStatus: true });
    }, (error) => {
      this.setState({
        alertStatusError: { show: true, message: 'Ocurrió un error al guardar los marcadores.' }
      })
    });
  }

  render() {
    const {
      business,
      title,
      open,
      onClose,
      classes,
      documentSelected,
    } = this.props;

    const {
      bookmarks,
      urlPdf,
      alertStatusError,
      alertStatus,
      templateInfo,
      contractType,
      openDialogSaveDocument,
      openDialogSaveContract,
      initialBookmarkValues,
    } = this.state;


    return (
      <React.Fragment>
        <Dialog
          fullScreen
          maxWidth={false}
          open={open}
          onClose={onClose}
          classes={{ paperFullScreen: classes.paperFullScreen }}
        >
          <Grid container>
            <Grid item sm={5} md={4} className={classes.formContainer}>
              <Paper elevation={3} className={classes.paperForm}>
                <AppBar elevation={0} className={classes.appBar}>
                  <Toolbar style={{ paddingRight: '0px' }}>
                    <IconButton edge="start" color="primary" onClick={onClose}>
                      <ArrowBackIcon color="primary" />
                    </IconButton>
                    <Typography color="primary" variant="h6">
                      {title ?
                        title
                        : templateInfo && templateInfo.name
                          ? `${templateInfo.nameDisplay}${templateInfo.extension}` : ''
                      }
                    </Typography>
                  </Toolbar>
                </AppBar>

                {/* { templateInfo !== null &&
                  <Grid container item xs={12} className="ml-3 mr-3">
                    <Typography color="primary" variant="subtitle1">
                      {`Tipo de documento: ${templateInfo.documentType}`}
                    </Typography>
                  </Grid>
                } */}

                <Grid container item xs={12}>
                  {(bookmarks && bookmarks.length > 0 && (!documentSelected || initialBookmarkValues)) &&
                    <TemplateUsageForm
                      submitAction={(formData) => {
                        this.onClickSave(formData);
                        this.setState({ openDialogSaveDocument: true });

                        // if (!documentSelected && templateInfo.documentType === contractType) {
                        //   this.setState({openDialogSaveContract: true});
                        // } else if (!documentSelected) {
                        //   this.setState({openDialogSaveDocument: true});
                        // }
                      }}
                      onClickPreview={(formData) => {
                        this.onClickPreview(formData);
                      }}
                      fields={bookmarks}
                      initialValues={initialBookmarkValues}
                      onCancel={onClose}
                    />
                  }
                </Grid>
              </Paper>
            </Grid>
            <Grid item className={classes.templateContainer} xs>
              {urlPdf !== null &&
                <div id="doc-viewer" className={classes.paperTemplate}>
                  <Worker
                    workerUrl="./pdfjs/pdf.worker.js"
                  >
                    <Viewer
                      fileUrl={urlPdf}
                      defaultScale={SpecialZoomLevel.PageFit}
                    />
                  </Worker>
                </div>
              }
            </Grid>
          </Grid>
        </Dialog>

        {openDialogSaveDocument &&
          <FormSavePreview
            openDialog={openDialogSaveDocument}
            submitActions={(formData, folder) => this.onSubmitForm(formData, folder)}
            onCancel={() => { this.setState({ openDialogSaveDocument: false, collaborators: [] }); }}
            tagsSelect={business !== null ? business.tags.map(item => ({ label: item, value: item })) : []}
            company={business}
          />
        }

        {/* { openDialogSaveContract &&
          <FormSaveContractPreview
            openDialog={openDialogSaveContract} 
            submitActions={(formData, folder) => this.onSubmitForm(formData, folder)}
            onCancel={ () =>  {this.setState({openDialogSaveContract: false, collaborators: []}); } }
            tagsSelect={business !== null ? business.tags.map(item => ({label: item, value: item })) : []}
            company={business}
          />
        } */}

        <Alert
          open={alertStatusError.show}
          title={alertStatusError.message}
          type="error"
          onConfirm={() => {
            this.setState({
              alertStatusError: { show: false, message: '' },
            })
          }}
        />

        <Alert
          open={alertStatus}
          title={`Se ha guardado correctamente.`}
          type="success"
          onConfirm={() => {
            this.setState({ alertStatus: false, openDialogSaveContract: false, openDialogSaveDocument: false });
            onClose();
          }}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(TemplateUsage);