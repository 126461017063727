import React, { Component } from "react";
import { Button, IconButton } from "@material-ui/core";
import Header from "../../dashboard/header";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";
// import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
// import BusinessManagement from "./business-management";
import BusinessContract from "./business-contract";
import { BusinessHttp } from "../../../core/http/business.http";
import TemplateList from "../../templates/template-list";
import { FoldersHttp } from "../../../core/http/folders.http";
import { BusinessManagementFn } from "./BusinessManagementFn";

class BusinessDetail extends Component {
  businessHttp = new BusinessHttp();
  foldersHttp = new FoldersHttp();

  constructor(props) {
    super(props);

    this.state = {
      value: "1",
      business: {},
      folders: null,
    };
  }

  componentDidMount() {
    this.findBusinessByUid();
    this.findFoldersCompany();
  }

  findBusinessByUid() {
    const { id } = this.props.match.params;
    this.businessHttp.findBusinessByUid(
      id,
      (resp) => {
        this.setState({ business: resp });
      },
      () => { }
    );
  }

  findFoldersCompany() {
    const { id } = this.props.match.params;
    this.foldersHttp.foldersCompanies(
      id,
      (resp) => {
        const folders = resp.subfolders.filter(
          (folder) => folder.name !== "Contratos"
        );
        this.setState({ folders: folders });
      },
      () => { }
    );
  }

  render() {
    const { value, business, folders } = this.state;
    return (
      <div>
        <Header
          title={`Tablero ${business.id ? business.name : ""}`}
          actions={[
            <Button variant="outlined">Exportar</Button>,
            <IconButton variant="outlined">
              <NotificationsIcon color="primary" />
            </IconButton>,
            <IconButton variant="outlined">
              <EventIcon color="primary" />
            </IconButton>,
          ]}
        />

        <TabContext value={value}>
          <TabList
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(evt, value) => this.setState({ value })}
            aria-label="simple tabs example"
          >
            {/*
              Issue 389 - Quitar manejo particular de contratos
            <Tab label="Gobierno Corporativo" value="1" />
            <Tab label="Contratos" value="2" disabled={!business.id} />
            <Tab label="Plantillas" value="3" disabled={!business.id} /> */}
          </TabList>

          <TabPanel value="1">
            <BusinessManagementFn
              idCompany={this.props.match.params}
              name={business.name}
              folders={folders ? folders : []}
              business={business}
              currentUser={business}
            />
          </TabPanel>

          <TabPanel value="2">
            <BusinessContract business={business} />
          </TabPanel>

          <TabPanel value="3">
            <TemplateList business={business} />
          </TabPanel>
        </TabContext>
      </div>
    );
  }
}

export default BusinessDetail;
