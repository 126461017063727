
import "./popup-user.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { CardUserFn } from "./CardUserFn";
import { UserHttp } from "../../../../core/http/user.http";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { DraggableList } from "../../../draggableList/DraggableList";
import { useDispatch, useSelector } from "react-redux";
import { activityActions } from "../../../../core/actions/activity.actions";
import { AddMultipleNoSigners } from "./AddMultipleNoSigners";


export const PopUpExternalUser = ({ open, onCancel, onSubmit, labelType = "external", documentId }) => {

  const dispatch = useDispatch();

  const usersSelected = useSelector(state => state.activityReducer.allNoSigners);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  const handleDragg = (newList) => {

  }

  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected);
  }

  const handleCancel = () => {
    onCancel();
  }

  useEffect(() => {
    const userHttp = new UserHttp();
    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      documentId,
      "external",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        dispatch(activityActions.saveInternalUsersNotToSign(s));
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
  }, []);


  return (
    <Dialog className="popup-user" open={open}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  marginRight:"10px"}}>
        <DialogTitle>Destinatarios seleccionados</DialogTitle>
        {
          !load && (
            <AddMultipleNoSigners />
          )
        }
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>
              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  <DraggableList
                    items={usersSelected}
                    setItems={handleDragg}
                  >
                    {usersSelected.map((item) => (
                      <CardUserFn
                        key={item.userId}
                        labelType={labelType}
                        onlyShow={false}
                        id={item.userId}
                        name={item.name}
                        email={item.email}
                        color={item.color}
                        type={item.type}
                        rfc={item.rfc}
                        useRFC={item.hasRFC}
                        onChange={() => { }}
                      />
                    ))}
                  </DraggableList>
                </Grid>

                {usersSelected.length === 0 && (
                  <div className="text-center mt-2">
                    No hay usuarios seleccionados
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div>
        <DialogActions>


          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            // disabled={usersSelected.length === 0}
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>

        </DialogActions>
      </div>
    </Dialog>
  );
}

