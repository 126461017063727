import { axiosGestorCapitalRequest, authHeader } from '../../configs/axios.config';

export class GestorCapitalHttp {
 
  getShareHolders(id , success , error) {
    axiosGestorCapitalRequest.get(`/gestionCapital/company/${id}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  createShareHolder(data , success , error) {
    axiosGestorCapitalRequest.post(`/shareHolder`, data , { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  updateShareHolder(id , data , success , error) {
    axiosGestorCapitalRequest.put(`/shareHolder/${id}`, data , { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  deleteShareHolder(id , success , error) {
    axiosGestorCapitalRequest.delete(`/shareHolder/${id}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  createStock(data , success , error) {
    axiosGestorCapitalRequest.post(`/stock`, data , { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  updateStock(id , data , success , error) {
  axiosGestorCapitalRequest.put(`/stock/${id}`, data , { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  finalizeProcces(id , success , error) {
    axiosGestorCapitalRequest.get(`/gestionCapital/company/${id}/finalize`, { headers: authHeader() })
        .then(({ data }) => success(data))
        .catch(errorResp => error(errorResp))
  }
  
  checkStatusProcces(id , success , error) {

      axiosGestorCapitalRequest.get(`gestionCapital/company/${id}/status`, { headers: authHeader() })
          .then(({ data }) => success(data))
          .catch(errorResp => error(errorResp))
      }

}
