import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { RenderTextField, RenderSelect, RenderMultiSelect, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import RenderDragAndDrop from '../../core/redux-form-fields/render-drag-and-drop';
import { DocumentsHttp } from '../../core/http/documents.http';

class FormTemplate extends Component {

  state = {
    documentTypes: [],
    tags: [],
  };

  documentsHttp = new DocumentsHttp();

  componentDidMount() {
    const { templateSelected } = this.props
    this.getDocumentTypes();

    if (templateSelected !== null) {
      this.documentsHttp.getDocumentInfo(templateSelected.id, document => {
        const tags = (document && document.Tags)
          ? document.Tags.map(tag => {
              if (tag !== ''){
                return {label: tag, value: tag}
              } else {return null}
            })
          : [];
        
        this.setState({ tags: tags });

        this.props.initialize({
          id: document ? document._id : templateSelected.id,
          name: document ? document.NameDisplay : '' ,
          description: document ? document.Description : '',
          tags: tags,
          // documentType: document ? document.DocumentTypeId: '',
        });
      }, error => {});
    }
  }

  getDocumentTypes() {
    this.documentsHttp.getDocumentTypes((resp) => {
      const documentTypes = resp.map(item => ({label: item.Name, value: item._id}));
      this.setState({ documentTypes: documentTypes });
    }, (error) => {});
  }

  render() {
    const {
      handleSubmit,
      submitActions,
      initialValues,
      templateSelected,
      onCancel
    } = this.props;

    const { documentTypes, tags } = this.state;

    return (
      <form className={isMobileOnly ? "p-3" : "p-5"} onSubmit={handleSubmit(submitActions)}>
        <Grid container>
          <Grid item sm={12}>
            <h3>
              {templateSelected !== null ? 'Editar plantilla' : 'Añadir plantilla'}
            </h3>
            <Field
              label="Nombre de la plantilla"
              name="name"
              required
              disabled = {templateSelected !== null}
              component={RenderTextField}
            />
            <Field
              label="Etiquetas"
              name="tags"
              options={tags}
              isClearable
              component={RenderMultiSelect}
            />
            {/* <Field
              label="Tipo de documento"
              name="documentType"
              required
              options={documentTypes}
              component={RenderSelect}
            /> */}

            <h5>Documento</h5>
            <Field
              // description="Arrastra el documento aquí para subirlo"
              name="file"
              accept={'.docx,application/msword'}
              required={initialValues ? true : false}
              component={RenderDragAndDrop}
            />

            {/* <h5>Imagen (opcional)</h5>
            <Field
              description="Arrastra la imagen aquí para subirla"
              name="image"
              accept={'.jpg,application/jpg'}
              component={RenderDragAndDrop}
            /> */}
            <div className="text-right pt-3">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={ () => onCancel() }
              >
                Cancelar
              </Button>
              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {
    name: ''
  }

  if (!values.name) {
    errors.name = 'El nombre de la plantilla es requerido';
  }

  if (values.name) {
    if (values.name > 100) {
      errors.name = 'El nombre de la plantilla no puede ser mayor a 100 caracteres';
    }
  }

  // if (!values.documentType) {
  //   errors.documentType = 'Selecciona una opción';
  // }

  if (!values.file && !values.id) {
    errors.file = 'Archivo requerido';
  }

  return errors;
}

export default reduxForm({
  form: 'FormTemplate',
  validate
})(FormTemplate);