import axios from 'axios';

export const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`
});

export const securityInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/security`
  // baseURL: `http://localhost:8070/api/security`
});

export const authHeader = () => ({ Authorization: `Bearer ${sessionStorage.access_token}` })

export const axiosBusinessRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosCalendarRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosRequestBlockChain = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOCUMENT_URL}/api/document`
  // baseURL: 'http://localhost:8072/api/document'
});

export const vaultInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOCUMENT_URL}/api/vaults`
  // baseURL: 'http://localhost:8072/api/vaults'
})

export const azureInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOCUMENT_URL}/api/storage/azure`
  // baseURL: 'http://localhost:8072/api/storage/azure'
})

export const axiosSignatureRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/signature`,
  // baseURL: `http://localhost:8075/api/signature`,
  headers: authHeader()
});

export const axiosTemplateRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosCollaboratorRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosCollaboratorUsersRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader()
});

export const axiosGestorCapitalRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: authHeader()
});

