import { Button, TableCell } from '@material-ui/core';
import React, { Component } from 'react';
import { ReviewersHttp } from '../../../../core/http/reviewers.http';

export class ReviewButton extends Component {
  
  reviewersHttp = new ReviewersHttp();
  
  constructor(props) {
    super(props);

    this.state = {
      isReview: false
    }
  }

  componentDidMount() {
    this.reviewersHttp.isReviewed(this.props.item.documentId, (resp) => {
      this.setState({ isReview: resp });
    });
  }

  render() {
    const { onClick } = this.props;
    const { isReview } = this.state;

    return (
      <React.Fragment>
        <TableCell className="no-td">
          <Button
            color="primary"
            variant="contained"
            onClick={() => onClick() }
          >
            Revisiones
          </Button>
        </TableCell>
        
        {
          isReview &&
            <TableCell className="no-td">
              <div className="circle-check">
                <em className="fa fa-check"></em>
              </div>
            </TableCell>  
        }
      </React.Fragment>
    );
  }
} 