import React, { useEffect, useState } from 'react'
import { Selector } from 'axeleratum-sgc-frontend-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, TextField, IconButton, Button, InputAdornment,
  Checkbox, FormControlLabel, FormGroup,
} from '@material-ui/core';

import Business from './business';
import Users from './users/users';
import './team.scss'
import Roles from './roles/roles';
import { usersActions } from '../../core/actions';
import Header from '../dashboard/header'

import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";
import SearchIcon from '@material-ui/icons/Search';

const elements = [
  {
    value: { id: 1, title: 'Usuarios' },
    label: 'Usuarios'
  }, {
    value: { id: 1, title: 'Bóvedas' },
    label: 'Bóvedas'
  }, {
    value: { id: 1, title: 'Roles de equipo' },
    label: 'Roles'
  }
];

const TeamFn = () => {

  const dispatch = useDispatch();

  const currentPage = useSelector(state => state.userReducer.currentPage)

  const [elementSelect, setElementSelect] = useState(elements[0].value)
  const [searchValue, setSearchValue] = useState("")
  const [checkEmail, setCheckEmail] = useState(false)

  const handleClickSearch = () => {
    dispatch(usersActions.setSearchUsers(searchValue))
    dispatch(usersActions.findAllv2({ page: 1, search: searchValue, includeEmail: checkEmail }))
  }

  const onCheckEmail = () => {
    setCheckEmail(prev => {
      dispatch(usersActions.setIncludeEmail(!prev))
      return !prev
    })
  }
  return (
    <>
      <Header
        title={elementSelect.title}
        actions={[
          <IconButton variant="outlined">
            <NotificationsIcon color="primary" />
          </IconButton>,
          <IconButton variant="outlined">
            <EventIcon color="primary" />
          </IconButton>,
        ]}
      />
      {
        elementSelect !== elements[2].value &&
        <Grid container alignItems='center' spacing={3}>
          <Grid item md={5} xs={12} sm={5}>
            <TextField
              value={searchValue}
              margin="dense"
              label={elements[0].value === elementSelect ? `Texto a buscar en el Nombre ${checkEmail ? 'y el Email' : ''}` : "¿Que buscas?"}
              variant="outlined"
              fullWidth={true}
              onChange={(evt) => setSearchValue(evt.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {
            elements[0].value === elementSelect && (
              <Grid item container md={5} xs={12} sm={5} alignItems='center' justifyContent='flex-start' spacing={3}>
                <Grid item md={3} xs={6} sm={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkEmail}
                          onChange={onCheckEmail}
                          name="email"
                          color="primary"
                        />
                      }
                      label="Email"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md xs={6} sm={6}>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleClickSearch}
                    startIcon={<SearchIcon />}
                  >
                    buscar
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      }

      <Grid className="mt-3" container spacing={4}>
        <Selector
          title='Equipo'
          sizeContainer={3}
          xs={12}
          elements={elements}
          elementSelected={elementSelect}
          onSelectElement={(element) => setElementSelect(element)}
        />

        {
          !(elementSelect === elements[2].value) &&
          <Grid item md={9} xs={12}>
            {
              elementSelect === elements[0].value &&
              <Users />
            }

            {
              elementSelect === elements[1].value &&
              <Business />
            }
          </Grid>
        }

        {(elementSelect === elements[2].value) &&
          <Roles />
        }


      </Grid>

    </>
  );
}

export default TeamFn;
