import {
	FormControl,
	Grid,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
// import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";
import { GenericDialog } from "../../dialogs/Generic-with-children/GenericDialog";
import { AlertConfirm } from "../../from-library/alerts/AlertConfirm";
import { getCurrentUser } from "../../../core/helpers";

const objectSignature = {
	link: "Liga por correo",
	email: "Correo con Contraseña",
	rfc: "R.F.C.",
	token: "Token",
};

const objectStroke = {
	any: "Cualquiera",
	desc: "Descriptiva",
	stroke: "Dibujo",
	name: "Nombre",
	hash: "Digestión",
	qr: "QR",
};

const useStyles = makeStyles((theme) => ({
	selectorsContainer: {
		marginTop: "10px",
		marginLeft: "17px"
	},
}));


export const FormModifiedSigners = (props) => {
	const {
		name,
		email,
		rfc = "",
		id,
		submitActions,
		open,
		setOpen,
		hasNom151,
		imageType,
		signType,
		userId,
	} = props;
	const classes = useStyles();

	const [errors, setErrors] = useState({ name: "", email: "", rfc: "" });
	const [options, setOptions] = useState([]);
	const [strokeOptions, setStrokeOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState('');
	const [selectedStroke, setSelectedStroke] = useState('');
	const [userEmail, setUserEmail] = useState(email);
	const [dataRfc, setDataRfc] = useState(rfc ? rfc : '');
	const [openConfirm, setOpenConfirm] = useState(false);
	const [modified, setModified] = useState(false);


	const handleChangeStroke = (e) => {
		setSelectedStroke(e.target.value);
	};

	const handleChange = (e) => {
		setSelectedOption(e.target.value);
	};

	const availableOptions = () => {
		const { availableSignatures } = getCurrentUser();

		const optionsMap = availableSignatures.map((el) => ({
			title: objectSignature[el],
			value: el,
		}));

		const strokeMap = Object.keys(objectStroke).map((el) => ({
			title: objectStroke[el],
			value: el,
		}));

		const optionsSet = !!dataRfc
			? optionsMap
			: optionsMap.filter((op) => op.value !== "rfc");

		setOptions(optionsSet);
		setStrokeOptions(strokeMap);
		setSelectedStroke(imageType ?? "any");
		setSelectedOption(signType);
	};

	const handleSubmit = () => {
		const errors = { email: null, name: null, rfc: null };
		if (!name && !errors.name) {
			errors.name = "El nombre del usuario es requerido";
		}

		if (!userEmail && !errors.email) {
			errors.email = "El email es requerido";
		}

		if (dataRfc && !errors.rfc) {
			const rfcReg = new RegExp(
				/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i
			);

			if (!rfcReg.test(dataRfc)) {
				errors.rfc = "RFC invalido";
			}
		}

		setErrors(errors);
		if (!!errors.name || !!errors.email || !!errors.rfc) return;

		submitActions({
			name: name.trim(),
			email: userEmail.trim().toLowerCase(),
			rfc: dataRfc?.trim(),
			id,
			imageType: selectedStroke,
			authority: selectedOption
		});
		setOpenConfirm(false);
	};

	const handleChangeEmail = (e) => {
		setUserEmail(e.target.value);
	};

	const handleChangeRfc = (e) => {
		setDataRfc(e.target.value);
	};

	const handleAccept = () => {
		if (userId.includes('-')) {
			handleSubmit()
		} else if (
			userEmail.trim().toLowerCase() !== email.trim().toLowerCase() ||
			dataRfc.trim().toLowerCase() !== rfc.trim().toLowerCase()
		) {
			setOpenConfirm(true)
		} else {
			handleSubmit()
		}
	}

	const validateField = () => {
		const errors = { email: null, name: null, rfc: null };

		if (!userEmail && !errors.email) {
			errors.email = "El email es requerido";
		} else {
			const emailReg = new RegExp(/\S+@\S+\.\S+/);
			if (!emailReg.test(userEmail)) {
				errors.email = "Email invalido";
			}
		}

		if (dataRfc && !errors.rfc) {
			const rfcReg = new RegExp(
				/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i
			);

			if (!rfcReg.test(dataRfc)) {
				errors.rfc = "RFC invalido";
			}
		}

		setErrors(errors);
	};

	useEffect(() => {
		availableOptions();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let changed = false;
		if (options.length === 0 || strokeOptions === 0) return

		if (userEmail.trim().toLowerCase() !== email.trim().toLowerCase()) {
			changed = true;
		}
		if (dataRfc.trim().toLowerCase() !== (rfc ?? "").trim().toLowerCase()) {
			changed = true;
		}
		if (selectedOption.trim().toLowerCase() !== signType.trim().toLowerCase()) {
			changed = true;
		}
		if (selectedStroke.trim().toLowerCase() !== (imageType ?? "any").trim().toLowerCase()) {
			changed = true;
		}
		setModified(changed);
	}, [userEmail, dataRfc, selectedOption, selectedStroke])


	return (
		<GenericDialog
			open={open}
			setOpen={setOpen}
			nameComponent={"Datos del firmante"}
			nameButton1={"CANCELAR"}
			nameButton2={"ACEPTAR"}
			onAccept={handleAccept}
			disableAccept={!modified}
		>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						margin="dense"
						label="Nombre*"
						value={name}
						disabled
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						margin="dense"
						label="Email*"
						value={userEmail}
						error={!!errors.email}
						helperText={errors.email}
						onChange={handleChangeEmail}
						onBlur={validateField}
					/>


				</Grid>

				<Grid item xs={12}>
					<TextField
						variant="outlined"
						fullWidth
						margin="dense"
						label="RFC"
						value={dataRfc}
						error={!!errors.rfc}
						helperText={errors.rfc}
						onChange={handleChangeRfc}
						onBlur={validateField}
					/>
				</Grid>

				<Grid item container className={classes.selectorsContainer}>
					<Grid item xs={4}>
						<FormControl>
							<span className="label-metodo">Método</span>
							<Select
								value={selectedOption}
								onChange={(e) => handleChange(e)}
								title={objectSignature[selectedOption]}
								disabled={!hasNom151}>
								{options.map((el) => (
									<MenuItem key={el.value}
										// value={any}
										value={el.value}
									>
										{el.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={4}>
						<FormControl>
							<span className="label-metodo">Trazo</span>
							<Select
								value={selectedStroke}
								onChange={handleChangeStroke}
								title={objectStroke[selectedStroke]}>
								{strokeOptions.map((el) => (
									<MenuItem key={el.value} value={el.value}>
										{el.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>

			{openConfirm && (
				<AlertConfirm
					open={openConfirm}
					onCancel={() => setOpenConfirm(false)}
					onConfirm={handleSubmit}
					textContent="Debido a que éste es un usuario catalogado, se actualizarán sus datos en el catálogo. ¿Deseas continuar?"
				/>
			)}
		</GenericDialog>
	);

};
