import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, Typography, withStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderIcon from '@material-ui/icons/Folder';
import BusinessIcon from '@material-ui/icons/Business';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useFolderSelector } from '../../../hooks/useFolderSelector';
import { AlertConfirm, Alert } from 'axeleratum-sgc-frontend-library';
import { SelectCompany } from './SelectCompany';
import MuiAlert from '@material-ui/lab/Alert';
import { DocumentsWithProcessList } from './DocumentsWithProcessList';

const useStyles = () => ({
  rootTree: {
    flexGrow: 1,
    maxWidth: 400,
  },
  iconTreeItem: {
    fontSize: '18px',
    verticalAlign: 'text-bottom',
  },
  iconMoreItems: {
    fontSize: '18px',
    verticalAlign: 'bottom',
  }
});


const MoveElement = ({
  vault, origin, fromCompany = false,
  onClose, itemSelected, onSuccess, onFail,
  classes, allowOtherCompanies }) => {

  const initialize = {
    onSuccess,
    onFail,
    companySelected: vault,
    itemSelected,
    origin,
    fromCompany,
  }

  const {
    getSubfolders,
    clearCompanies,
    setShowCompanies,
    setFolderSelected,
    setOpenConfirm,
    setCompany,
    setFolders,
    setOpenList,
    setDocumentWithProcess,
    setTitleError,
    setOpenAlert,
    moveElement,
    folderSelected,
    openConfirm,
    folders,
    showCompanies,
    company,
    totalDocuments,
    companyName,
    loading,
    openList,
    documentWithProcess,
    titleError,
    openAlert
  } = useFolderSelector(initialize);

  const renderChildren = (folders) => {
    return (
      (folders.length > 0) && folders.map((folder, index2) =>
        <TreeItem
          key={index2}
          nodeId={folder.id}
          label={
            <div>
              {!folder.childrenLoaded && <MoreHorizIcon className={classes.iconMoreItems} />}
              {folder.childrenLoaded && <FolderIcon className={classes.iconTreeItem} />}
              {folder.name}
            </div>
          }
          onClick={async () => {
            if (!folder.childrenLoaded) {
              folder.children = await getSubfolders(folder);
              folder.childrenLoaded = true;
            }
            setFolderSelected(folder);
          }}
        >
          {renderChildren(folder.children)}
        </TreeItem>
      )
    );
  }

  const loadingComponent = (
    <Grid container style={{ justifyContent: "center", width: "100%" }}>
      <MuiAlert severity="info">Moviendo documentos...</MuiAlert>
    </Grid>
  );

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={true}
      >
        <Grid
          className="p-2"
          container
          item
          xs={12}
          style={{ background: '#1e88e5' }}
          justifyContent="center"
        >
          <Typography variant="h6" style={{ color: '#fff' }}>
            Bóveda
          </Typography>
        </Grid>
        <DialogContent style={{ height: '250px' }}>
          <Box>
            <TreeView
              className={classes.rootTree}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {(folders && folders.length > 0 && !loading) && folders.map((folder, index) =>
                <TreeItem
                  key={index}
                  nodeId={folder.id}
                  label={
                    <div>
                      {!folder.childrenLoaded && <MoreHorizIcon className={classes.iconMoreItems} />}
                      {folder.childrenLoaded && <BusinessIcon className={classes.iconTreeItem} />}
                      {folder.name}
                    </div>
                  }
                  onClick={async () => {
                    if (!folder.childrenLoaded) {
                      folder.children = await getSubfolders(folder);
                      folder.childrenLoaded = true;
                    }
                    setFolderSelected(folder);
                  }}
                >
                  {renderChildren(folder.children)}
                </TreeItem>
              )}
            </TreeView>
            {(!folders && !loading) && <CircularProgress />}
            {loading && loadingComponent}
          </Box>
        </DialogContent>
        <Grid className="p-3" container item xs={12} justifyContent="flex-end">
          {
            allowOtherCompanies && (
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => { setShowCompanies(true) }}
                disabled={loading}
              >
                Otra Bóveda
              </Button>
            )
          }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              clearCompanies();
              onClose()
            }}

            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            className="ml-2"
            variant="contained"
            color="primary"
            onClick={() => { setOpenConfirm(true) }}
            disabled={folderSelected === null || folderSelected.notSelectable || loading}
          >
            Mover
          </Button>
        </Grid>
      </Dialog>

      <AlertConfirm
        open={openConfirm}
        onCancel={() => { setOpenConfirm(false); }}
        onConfirm={() => {
          moveElement();
        }}
        textContent={
          !!itemSelected.extension
            ? folderSelected?.path
              ? `Se moverá al folder '${folderSelected?.name}' de la bóveda '${companyName}'`
              : `Se moverá al folder raiz de la bóveda '${companyName}'`
            : folderSelected?.path
              ? `Se moverán ${totalDocuments?.length} documentos al folder '${folderSelected?.name}' de la bóveda '${companyName}'`
              : `Se moverán ${totalDocuments?.length} documentos al folder raiz de la bóveda '${companyName}'`
        }
      />

      <SelectCompany
        open={showCompanies}
        selectedCompany={company}
        onClose={(selected) => {
          if (selected.name != company.name) {
            setFolders(null);
          }
          setCompany(selected);
          setShowCompanies(false);
        }}
      />

      {
        openAlert && (
          <Alert
            open={openAlert}
            title={titleError}
            type="error"
            onConfirm={() => {
              setOpenAlert(false);
              setOpenList(true);
            }}
          />
        )
      }

      {
        openList && (
          <DocumentsWithProcessList
            open={openList}
            onClose={() => {
              setOpenList(false);
              setTitleError('');
              setDocumentWithProcess([]);
            }}
            title={"Documentos en proceso de revisión o firma"}
            documents={documentWithProcess}
          />
        )
      }

    </div>
  );
}


export default withStyles(useStyles)(MoveElement);
