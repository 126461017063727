import { useState, useEffect } from 'react';
import { UserHttp } from '../http/user.http';
import { RolesHttp } from '../http/roles.http';
import permissionScheme from './permission-scheme';
import permissionGroups from './permission-groups';

function getUserRoles() {
  return new Promise((resolve, reject) => {
    const userId = sessionStorage.userId
    const userHttp = new UserHttp();
    userHttp.findUsersById({ id: userId }, (resp) => {
      const roles = resp.roles.map(role => role.id);
      resolve(roles);
    }, (error) => {
      reject(error)
    }
    );
  });
}

function getAllRoles() {
  return new Promise((resolve, reject) => {
    const rolesHttp = new RolesHttp();
    rolesHttp.findAllRoles((resp) => {
      resolve(resp)
    }, (error) => {
      reject(error)
    })
  });
}

function mixRoles(roles) {
  const mixRoles = roles.reduce((prev, current) => {
    let newPrev = prev;
    if (prev.length > 0) {
      current.permissionGroups.forEach(currentGroup => {
        prev.forEach((prevGroup, index) => {
          if (currentGroup.name === prevGroup.name) {
            if (Boolean(currentGroup.permissions.length)) {
              let uniqueArray = [...new Set([...currentGroup.permissions, ...prevGroup.permissions])];
              newPrev[index].permissions = uniqueArray;
            }
          }
        })
      })
      return newPrev;
    } else {
      newPrev.push(...current.permissionGroups);
      return newPrev;
    }
  }, [])
  return mixRoles;
}

async function getUserPermissionsGroup() {
  const userRoles = await getUserRoles();
  const allRoles = await getAllRoles();
  const filteredRoles = allRoles.filter((rol) => {
    return userRoles.includes(rol.id);
  });

  if (filteredRoles.length > 1) {
    const uniquePermissionGroups = mixRoles(filteredRoles);
    return uniquePermissionGroups;
  } else {
    if (filteredRoles.length !== 0) {
      return filteredRoles[0].permissionGroups;
    } else {
      return [];
    }
  }
}

async function validatePermissions(permission) {
  let userPermissionsGroup = [];

  if (sessionStorage.getItem('sgc_permissions')) {
    userPermissionsGroup = JSON.parse(sessionStorage.getItem('sgc_permissions'));
  } else {
    userPermissionsGroup = await getUserPermissionsGroup();
    sessionStorage.setItem('sgc_permissions', JSON.stringify(userPermissionsGroup))
  }
  return new Promise((resolve, reject) => {
    if (!userPermissionsGroup) resolve(false);
    userPermissionsGroup.forEach(group => {
      if (group.name === permission.groupName || group.name === "Administración del Sistema") {
        if (group.permissions.length !== 0) {
          group.permissions.forEach(p => {
            if (p.name === permission.permissionName || p.name === "Todos") {
              resolve(p.enabled);
            }
          });
        }
      }
    })
    resolve(false);
  }
  );
}

const CanI = ({ permission, render }) => {
  const [enabled, setEnabled] = useState(false);

  async function getData() {
    const isEnabled = await validatePermissions(permission);
    setEnabled(isEnabled);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [enabled]);

  return render(enabled);
}

export {
  CanI,
  validatePermissions,
  permissionScheme,
  permissionGroups,
  getUserPermissionsGroup
};