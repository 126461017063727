import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ValidateInput from '../utils/ValidateInput'
import LinearLoading from '../utils/LinearLoading'
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { GestorCapitalHttp } from '../../core/http/gestor-capital.http';

import './inversores.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'red',
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {padding: theme.spacing(1)},
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {margin: 0, padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DialogEditAction(props) {
    const [open, setOpen] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [arrayAction, setArrayAction] = useState([]);
   // const [arrayActionCopy, setArrayActionCopy] = useState([]);
    const [tempData, setTempData] = React.useState([]);
    const [showEditAction, setShowEditAction] = React.useState(false);
    const [touchedAction, setTouchedAction] = React.useState(true);
    const [touched, setTouched] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [shareholder, setShareholder] = React.useState({
        name: "", amount: "" ,stocks: "",companyId: props.idCompany.id, account:[]});

    useEffect(() => {
            setArrayAction(props.data);
           // setArrayActionCopy(props.data);
           setCompanyId(props.idCompany.id);
        }, []);
   
    const gestorCapitalHttp = new GestorCapitalHttp();


    const clearState = () => {
        setArrayAction(props.data);
        setErrorMessage("")
        setTouched("");
    };

    const change = (key,value) => {
        const temp = {...shareholder};
        temp[key] = value;
        setShareholder(temp);   
        
        const temp2 = {...tempData};
        temp2[key] = value;
        setTempData(temp2);  
    };

    const shareholderTemp = (data) => {

        const temp = {...shareholder};
        //const temp2 = {...tempData};
        let account = [];
        
        data.account.map((srch,i)=>{
            let obj = {
                shareHolderId : srch.shareHolderId,
                stocks  : srch.stocks,
            };
            account.push(obj)
        })

        temp["name"] = data.name;
        temp["amount"] = data.amount;
        temp["stocks"] = data.stocks;
        temp["account"] = account;

        setShareholder(temp); 
    }

    const changeStockW = (key, value , id , idx ) => {


        const temp2 = {...tempData};
        temp2.account[idx][key] = value;
        setTempData(temp2); 

        const temp = {...shareholder};
        
        let account = temp.account === undefined ? [] : temp.account ;
        let obj = {
            shareHolderId : id,
            stocks  : value,
        };

       let objTemp =  account.filter( a => a.shareHolderId === obj.shareHolderId);
       
       if(objTemp.length <= 0){
           account.push(obj)
       }else{
        account.map((srch,i)=>{
            if(srch.shareHolderId === obj.shareHolderId){
                account[i].stocks = obj.stocks;
            }
        })
       }

        temp.account = account.length > 0 ?  account : [];
        
        setShareholder(temp);     

    };

    const changeImport = (key, value,idx) => {
        //const temp = {...tempData.account};
        const temp = {...tempData};
        temp.account[idx][key] = value;
        console.log("key suys",key)
        setTempData(temp)
    };
  
    const temp = (key, idx) => {
        setTouchedAction(false);
        setTempData(arrayAction[idx]);
        shareholderTemp(arrayAction[idx]);
    };
    
    const openEditAction = () => setShowEditAction(true);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false)
        clearState();
        setShowEditAction(false);
        setTouchedAction(true);
    }

    const handlePopulate = () => {
        props.refresh();
    };

    const saveUser = () => {
        setLoad(true);

     if(!shareholder.name.length  ) {
             setLoad(false);
                 setTouched(true);
                     setErrorMessage("Complete todos los campos");
         } 
             else {        
                 gestorCapitalHttp.updateStock(
                    tempData.id, shareholder,
                 (resp) => {
                     setLoad(false);
                         setOpen(false);
                         setShowEditAction(false);
                                handlePopulate();
                                    clearState();
                 }, (error) => {
                    handleError(error.response.data)
                 }
             );
         }
    };

    const handleError = (error) => {
        const err = error.message
        setLoad(false);
        setTouched(true);
        setErrorMessage(err!=null ? err : "Ocurrio un error");

    };

    return (
        <div>
            <Button
                variant='contained'
                color='primary'
                className="w-100"
                disabled={props.proccesStart}
                onClick={handleClickOpen}>
                    +  Modificar Tipo Accion
                </Button>

            <Dialog onClose={handleClose} fullWidth={true} style={{borderRadius:'10px'}}
                maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={open}>

                    <DialogTitle style={{backgroundColor: 'white',borderRadius:'10px'}} id="customized-dialog-title">
                        Modificar tipo de acciòn
                        </DialogTitle>

                <LinearLoading show={load} delay={15}/>

                <DialogContent style={{backgroundColor: 'white'}}>

                    <Typography component={'span'}>
                        <List>
                        <p hidden={showEditAction} style={{paddingLeft: '2rem',paddingBottom: '3rem'}}>Selecciona el tipo de accion que quieres editar :</p>
                    { 
                    showEditAction === false ? 
                        arrayAction.length ?
                            arrayAction.map((data,idx) => (
                                <div style={{paddingLeft: '2rem',paddingBottom: '10px'}}>
                                        <input id={idx}  type="radio" value={idx} onClick={(e)=>temp('name', e.target.value)} name={"action"}/>
                                        <label id={idx} style={{paddingLeft: '10px',paddingBottom:"10px"}} >{data.name}</label><br/> 
                                </div>
                            ))
                            :
                            <h1>No hay datos</h1>    
                    : " " }  
                                {

                                showEditAction===false ? "" :
                                
                                    <div style={{marginTop: '1rem'}}>
                                    
                                            { 
                                            tempData ? 
                                            <div>

                                                <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                                    <ValidateInput invalid={touched && !tempData.name} message='Este campo es requerido'>                                
                                                        <TextField fullWidth value={tempData.name} name="name" onChange={(e)=>change('name', e.target.value)} required id="name" label="Nombre de la acciòn" variant="outlined" />
                                                    </ValidateInput>
                                                </div>
                                                <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                                    <ValidateInput invalid={touched && !tempData.stocks} message='Este campo es requerido'>                                
                                                        <TextField fullWidth value={tempData.stocks} name="acciones" onChange={(e)=>change('stocks', e.target.value)} required id="stocks" label="Cantidad de acciones emitidas" variant="outlined" />
                                                    </ValidateInput>
                                                </div>
                                                <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                                        <ValidateInput  invalid={touched && !tempData.amount} message='Este campo es requerido'>                                
                                                            <TextField fullWidth value={tempData.amount} name="amount" onChange={(e)=>change('amount', e.target.value)} required id="amount" label="Valor nominal de la accion" variant="outlined" />
                                                        </ValidateInput>
                                                </div>
                                            </div>
                                            : "" }
                                            
                                            {
                                                tempData ?
                                                tempData.account.map((option,idx) => (
                                                    <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>                                                    
                                                        <ValidateInput invalid={touched && !option.stocks} message='Este campo es requerido'>                                
                                                            <Tooltip title={"Accionista "+option.shareHolderName}>  
                                                                <TextField fullWidth value={option.stocks} name="importe" onChange={(e)=>changeStockW('stocks', e.target.value, option.shareHolderId, idx)} required id="importe" label={"Cantidad acciones del accionista "+ option.shareHolderName} variant="outlined" />
                                                            </Tooltip>
                                                        </ValidateInput>
                                                    </div>
                                                ))
                                                : " "
                                            }
                                            
                                    </div>
                                    
                                }
                            
                           
                        </List>
                    </Typography>
                                    {errorMessage &&
                            <Alert className="top"
                                severity='error'
                                onClose={() => setErrorMessage(null)}>
                                { errorMessage }
                            </Alert>
                                    }
                </DialogContent>
                <DialogActions style={{backgroundColor: 'white'}}>
                    <Button autoFocus variant="outlined" onClick={handleClose} color="secondary">
                            Cancelar
                        </Button>
                        { showEditAction===false ? 
                            <Button autoFocus variant="contained" disabled={touchedAction} onClick={openEditAction} color="primary">
                                Editar Tipo de Accciòn
                                </Button>
                        :  <Button autoFocus variant="contained" onClick={saveUser} color="primary">
                                Guardar Cambio
                            </Button>
                  } 
                                               
                </DialogActions>
            </Dialog>
        </div>
    );
}