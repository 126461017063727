import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, Grid, Typography, withStyles } from '@material-ui/core';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderIcon from '@material-ui/icons/Folder';
import BusinessIcon from '@material-ui/icons/Business';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = () => ({
  rootTree: {
    flexGrow: 1,
    maxWidth: 400,
  },
  iconTreeItem: {
    fontSize: '18px',
    verticalAlign: 'text-bottom',
  },
  iconMoreItems: {
    fontSize: '18px',
    verticalAlign: 'bottom',
  }
});

class FolderSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      folderSelected: null,
    };
  }

  renderChildren(folders) {
    const {classes} = this.props
    return (
      (folders.length > 0) && folders.map((folder, index2) =>
        <TreeItem
          key={index2}
          nodeId={folder.id}
          label={
            <div>
              { !folder.childrenLoaded && <MoreHorizIcon className={classes.iconMoreItems}/> }
              { folder.childrenLoaded && <FolderIcon className={classes.iconTreeItem} /> }
              { folder.name }
            </div>
          }
          onClick={() => {
            if (!folder.childrenLoaded) {
              this.props.onClick(folder);
            }

            this.setState({folderSelected: folder})
          }}
        >
          {this.renderChildren(folder.children) }
        </TreeItem>
      )
    );
  }

  render() {
    const {
      open,
      onClose,
      onSelected,
      classes,
      folders,
    } = this.props;
    const { folderSelected } = this.state;
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={open}
        >
          <Grid
            className="p-2"
            container
            item
            xs={12}
            style={{background: '#1e88e5'}}
            justify="center"
          >
            <Typography variant="h6" style={{color: '#fff'}}>
              Bóveda
            </Typography>
          </Grid>
          <DialogContent style={{height:'250px'}}>
            <Box>
              <TreeView
                className={classes.rootTree}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                { (folders && folders.length > 0) && folders.map((folder, index) =>
                  <TreeItem
                    key={index}
                    nodeId={folder.id}
                    label={
                      <div>
                        { !folder.childrenLoaded && <MoreHorizIcon className={classes.iconMoreItems}/> }
                        { folder.childrenLoaded && <BusinessIcon className={classes.iconTreeItem} /> }
                        { folder.name }
                      </div>
                    }
                    onClick={() => {
                      if (!folder.childrenLoaded) {
                        this.props.onClick(folder);
                      }

                      this.setState({folderSelected: folder})
                    }}
                  >
                    {this.renderChildren(folder.children)}
                  </TreeItem>
                )}
              </TreeView>
            </Box>
          </DialogContent>
          <Grid className="p-3" container item xs={12} justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => onSelected(folderSelected)}
              disabled={ folderSelected === null || folderSelected.notSelectable }
            >
              Seleccionar
            </Button>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(FolderSelector);