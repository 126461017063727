import { authHeader, vaultInstance } from "../../../configs/axios.config"



export const getVaultsUser = async () => {
  const { data } = await vaultInstance({
    headers: authHeader()
  })
  return data
}

export const getVaultsUserDashboard = async () => {
  const { data } = await vaultInstance({
    url: '/dashboard',
    headers: authHeader()
  })
  return data
}

export const getFileSystem = async (vaultId, path) => {
  const { data } = await vaultInstance({
    url: `/${vaultId}`,
    headers: authHeader(),
    params: {
      p: path
    }
  })
  return data
}

export const getElementsSearchedBar = async (query) => {
  const { data } = await vaultInstance({
    url: '/search',
    headers: authHeader(),
    params: query
  })
  return data
}


