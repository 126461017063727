import React from "react";
import { Route } from "react-router";
import Templates from "../../components/templates/templates";
import Team from '../../components/team/TeamFn';
// import Vault from "../../components/vault/vault";
import DashboardHome from "../../components/dashboard-home/dashboard-home";
import BusinessDetail from "../../components/dashboard-home/business-detail/business-detail";
import { DocumentsInformation } from "../../components/dashboard-home/DocumentsInformation";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import VaultFilesFn from "../../components/vault/VaultFilesFn";
import { VaultFn } from "../../components/vault/VaultFn";


const DashboardRouting = (props) => {
  const { match } = props;

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/home/documents-information`} component={DocumentsInformation} />
        <Route path={`${match.path}/home/calendar/:calendarKey`} component={DashboardHome} />
        <Route path={`${match.path}/home/:id`} component={BusinessDetail} />
        <Route path={`${match.path}/home`} component={DashboardHome} />
        <Route path={`${match.path}/templates`} component={Templates} />
        <Route path={`${match.path}/vault/:vaultId`} component={VaultFilesFn} />
        <Route path={`${match.path}/vault`} component={VaultFn} />
        {/* <Route path={`${match.path}/vault-file/:id_folder`} component={VaultFilesFn} /> */}
        <Route path={`${match.path}/team`} component={Team} />
        <Route path={`${match.path}/`} component={DashboardHome} />
      </Switch>
    </div>
  );
};

export default DashboardRouting;
