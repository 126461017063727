import React, { useEffect, useRef } from 'react'
import clsx from 'clsx';
import FolderIcon from '@material-ui/icons/Folder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody, makeStyles, IconButton } from '@material-ui/core'
import { DocumentUndefined, ExcelIcon, PdfIcon, WordIcon } from '../../../icons';
import { timeAgo } from '../../../core/utils';
import { FolderFileSystemIcon } from '../../../icons/FolderFileSystemIcon';
import { useVault } from '../../../hooks/useVault';

const headCells = ["Nombre", "Autor", "Última modificación", ""];

const useStyles = makeStyles(() => ({
  tableRoot: {
    borderCollapse: 'revert'
  },
  tableRowSelected: {
    backgroundColor: '#5C7BF0 !important',
    '& td': {
      color: 'white',
      '& h3': {
        color: 'white'
      }
    }
  },
  nameFileCell: {
    display: 'flex',
    alignItems: 'center'
  },
  nameFile: {
    marginLeft: '10px'
  },
  iconFolderRoot: {
    fontSize: '2rem',
    //cursor: 'pointer'
  },
  iconFolderSelected: {
    color: 'white !important'
  },
  cellItem: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  tableContainer: {
    maxHeight: '500px'
  },
  rowDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& h3': {
      color: 'gray',
    },
    '& svg': {
      color: '#f44336',
    },
    '& td': {
      color: 'gray',
    }
  }
}))

export const TableFolderFileSystem = (props) => {

  const classes = useStyles()
  const { onClickOptions, handleDoubleClick } = props;
  const {
    documents,
    folders,
    itemSelected,
    setItemSelected,
    goToPath,
  } = useVault();

  const itemRef = useRef(null);

  const onClickItem = (type, file) => {
    if (type === "document") {
      setItemSelected(file)
    }

    if (type === "folder") {
      setItemSelected({ ...file, isFolder: true, isRoot: false })
    }
  }

  const onDoubleClick = (event, type, file) => {
    if (type === 'document') {
      handleDoubleClick(file)
    }
    if (type === 'folder') {
      const pathTo = `${file.path ? file.path + '/' : ''}${file.name}`;
      goToPath(pathTo)
    }
  }


  const iconDocument = (extension, props) => {
    if (extension === '.pdf')
      return <PdfIcon  {...props} />
    if (extension === '.docx')
      return <WordIcon  {...props} />
    if (extension === '.xlsx')
      return <ExcelIcon  {...props} fill={"#fff"} />

    return <DocumentUndefined {...props} />
  }

  useEffect(() => {
    if (itemSelected?.extension) {
      itemRef.current.focus();
    }
  }, [itemSelected])

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        aria-labelledby="tableTitle"
        size={'small'}
        aria-label="enhanced table"
        padding="none"
        className={classes.tableRoot}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {headCells.map((cell, index) => (
              <TableCell
                key={index + Date.now()}
                align={'left'}
                padding={'normal'}
              >
                {cell}
              </TableCell>
            ))}

          </TableRow>
        </TableHead>

        <TableBody>

          {
            folders?.map(folder => {
              const isSelected = (folder.id === itemSelected?.id && folder.name === itemSelected?.name);
              return (
                <TableRow
                  key={folder.id + folder.name}
                  hover
                  onClick={() => onClickItem("folder", folder)}
                  onDoubleClick={(event) => onDoubleClick(event, "folder", folder)}
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  selected={isSelected}
                  className={clsx(classes.tableRow, {
                    [classes.tableRowSelected]: isSelected
                  })}
                >
                  <TableCell padding={'normal'} className={classes.nameFileCell}>
                    <div style={{ textAlign: 'center' }}>
                      <FolderFileSystemIcon
                        className={clsx(classes.iconFolderRoot, {
                          [classes.iconFolderSelected]: isSelected
                        })}
                      />
                    </div>
                    <div className={classes.nameFile}>
                      <h3>{folder.name}</h3>
                    </div>
                  </TableCell>
                  <TableCell
                    align="justify"
                    padding={'normal'}
                    className={classes.cellItem}>
                    {folder.author ? folder.author : 'Desconocido'}
                  </TableCell>
                  <TableCell
                    align="justify"
                    padding={'normal'}
                    className={classes.cellItem}>
                    {folder.updatedAt ? timeAgo.format(new Date(folder.updatedAt)) : ""}
                  </TableCell>
                  <TableCell>
                    {
                      folder.name !== "Trash" ? (
                        <IconButton
                          onClick={(event) => onClickOptions(event, "folder", folder)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : <></>
                    }
                  </TableCell>
                </TableRow>
              )
            })
          }
          {
            documents?.map(document => {
              const isSelected = (document.id === itemSelected?.id && document.name === itemSelected?.name);
              return (
                <TableRow
                  ref={isSelected ? itemRef : null}
                  key={document.id + document.name + document.extension}
                  hover
                  onClick={() => onClickItem("document", document)}
                  onDoubleClick={(event) => onDoubleClick(event, "document", document)}
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  selected={isSelected}
                  className={clsx(classes.tableRow, {
                    [classes.tableRowSelected]: isSelected
                  })}
                >
                  <TableCell padding={'normal'} className={classes.nameFileCell}>
                    <div>
                      {iconDocument(document.extension, {
                        className: clsx(classes.iconFolderRoot, { [classes.iconFolderSelected]: isSelected }),
                      })}

                    </div>
                    <div className={classes.nameFile}>
                      <h3>{document.name}{document.extension}</h3>
                    </div>
                  </TableCell>
                  <TableCell
                    align="justify"
                    padding={'normal'}
                    className={classes.cellItem}>
                    {document?.userBy?.completeName ? document.userBy.completeName : 'Desconocido'}
                  </TableCell>
                  <TableCell
                    align="justify"
                    padding={'normal'}
                    className={classes.cellItem}>
                    {timeAgo.format(new Date(document.updatedAt))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => onClickOptions(event, "document", document)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })
          }

        </TableBody>
      </Table>
    </TableContainer>
  )
}
