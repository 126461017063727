import React, { Component } from "react";
import {
  Grid,
  TextField,
  Button,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { DashboardCard } from "../dashboard-card";
import TableBusiness from "./table-business";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { FoldersHttp } from "../../../core/http/folders.http";
import DialogFormContract from "../../vault/dialog-form-contract";
import DialogFormFolder from "../../vault/dialog-form-folder";
import {
  Alert,
  uuid,
  AlertConfirm,
  DocumentPdfViewer,
  FilterIcon,
} from "axeleratum-sgc-frontend-library";
import { CollaboratorsHttp } from "../../../core/http/collaborators.http";
import {
  validatePermissions,
  permissionScheme,
} from "../../../core/utils/can-i";
import { sortData, timeAgo } from "../../../core/utils";
import { connect } from "react-redux";
import { DocumentsHttp } from "../../../core/http/documents.http";
import saveAs from "file-saver";
import { CalendarHttp } from "../../../core/http/calendar.http";
import { BusinessHttp } from "../../../core/http/business.http";
import DocumentReview from "../../vault/document-review";
import DialogFormFilter from "../../vault/dialog-form-filter";

const menuAdd = [
  {
    value: "contract",
    label: "Subir Documento",
    active: false,
  },
  {
    value: "directory",
    label: "Folder",
    active: false,
  },
];

var menuItems = [
  {
    value: "open",
    label: "Abrir documento",
    active: false,
  },
  {
    value: "update",
    label: "Editar",
    active: false,
  },
  {
    value: "delete",
    label: "Eliminar",
    active: false,
  },
];

class BusinessContract extends Component {
  foldersHttp = new FoldersHttp();
  documentsHttp = new DocumentsHttp();
  collaboratorHttp = new CollaboratorsHttp();
  calendarHttp = new CalendarHttp();
  companyHttp = new BusinessHttp();

  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      contracts: [],
      contractsFiltered: [],
      anchorEl: false,
      anchorItemSelected: false,
      alertStatus: false,
      alertStatusError: { show: false, message: "" },
      alertDelete: false,
      alertStatusDelete: false,
      openDialogNewFolder: false,
      openDialogNewContract: false,
      typeForm: "create",
      collaborators: [],
      folderSelected: null,
      contractSelected: null,
      openDocumentPdfViewer: false,
      filePdf: null,
      titlePdf: "",
      pendingActivities: [],
      pendingActivitiesLoad: false,
      pendingDocuments: [],
      pendingDocumentsLoad: false,
      usersLoad: false,
      users: [],
      contractsExpireLoad: false,
      contractsExpire: [],
      enableReview: false,
      enableSign: false,
      loading: false,
      openFilterDialog: false,
      authors: [],
      disble: false,
    };
  }

  componentDidMount() {
    this.getContractFolder();
    this.getPendingActivities();
    this.getInvitations();
    this.getUsersByCompanyId();
    this.getContractsExpire();
    this.getPermissions();
  }

  getPermissions() {
    validatePermissions(
      permissionScheme.documentManagement.accessDocuments
    ).then((enabled) => {
      if (enabled) {
        menuItems[0].active = true;
      }
    });

    validatePermissions(
      permissionScheme.documentManagement.uploadDocument
    ).then((enabled) => {
      if (enabled) {
        menuAdd[0].active = true;
      }
    });

    validatePermissions(
      permissionScheme.documentManagement.modifyDocument
    ).then((enabled) => {
      if (enabled) {
        menuItems[1].active = true;
      }
    });

    validatePermissions(
      permissionScheme.documentManagement.deleteDocument
    ).then((enabled) => {
      if (enabled) {
        menuItems[2].active = true;
      }
    });

    validatePermissions(permissionScheme.documentManagement.createFolder).then(
      (enabled) => {
        if (enabled) {
          menuItems[2].active = true;
          menuAdd[1].active = true;
        }
      }
    );
  }

  getContractsExpire() {
    this.setState({ contractsExpireLoad: true });
    this.collaboratorHttp
      .getContractsExpire(this.props.business.id)
      .then(({ data }) => {
        this.setState({ contractsExpire: data, contractsExpireLoad: false });
      })
      .catch(() => {
        this.setState({ contractsExpireLoad: false });
      });
  }

  getContractFolder() {
    this.setState({ loading: true });
    this.foldersHttp.getContractFolderByCompanyId(
      this.props.business.id,
      "Contratos",
      (resp) => {
        const contractsFolder = {
          folderId: resp._id,
          name: resp.name,
          author: resp.createdBy ? resp.createdBy.completeName : "Desconocido",
          lastUpdated: resp.lastModified
            ? timeAgo.format(new Date(resp.lastModified))
            : "",
          type: "folder",
          isFolder: true,
          path: resp.path ? resp.path : "",
          parentFolder: null,
        };
        this.setState({ contractsFolder: contractsFolder });
        this.getContractSubfoldersDocuments(contractsFolder);
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  getContractSubfoldersDocuments(folder) {
    this.setState({ loading: true });
    this.foldersHttp.getFolderInfo(
      folder.folderId,
      this.props.business.id,
      (data) => {
        const folders = sortData(
          data.subfolders.map((item) => {
            return {
              folderId: item._id,
              name: item.name,
              author: item.createdBy
                ? item.createdBy.completeName
                : "Desconocido",
              lastUpdated: item.updatedAt
                ? timeAgo.format(new Date(item.updatedAt))
                : "",
              lastUpdatedDate: new Date(item.updatedAt),
              type: "folder",
              isFolder: true,
              onlyExistInMongo: item.onlyExistInMongo,
              onlyExistInKaleido: item.onlyExistInKaleido,
              ownerId: document.createdBy
                ? document.createdBy._id
                : "Desconocido",
              path: item.path,
              parentFolder: folder,
            };
          })
        );

        const documents = sortData(
          data.documents.map((document) => {
            return {
              name: `${document.Name}${document.Extension}`,
              id: document._id,
              isFolder: false,
              type: "file",
              onlyExistInMongo: document.OnlyExistInMongo,
              onlyExistInKaleido: document.OnlyExistInKaleido,
              hash: document.Hash,
              author: document.UserBy
                ? document.UserBy.completeName
                : "Desconocido",
              lastUpdated: document.updatedAt
                ? timeAgo.format(new Date(document.updatedAt))
                : "",
              lastUpdatedDate: new Date(document.updatedAt),
              tags: document.Tags,
              ownerId: document.Owner ? document.Owner : "Desconocido",
              templateId: document.TemplateId,
              hasTemplate: !!document.TemplateId,
              documentTypeId: document.DocumentTypeId,
            };
          })
        );

        const authors = [];
        folders.forEach((item) => {
          if (!authors.some((author) => author.value === item.author)) {
            authors.push({ label: item.author, value: item.ownerId });
          }
        });

        documents.forEach((item) => {
          if (!authors.some((author) => author.value === item.author)) {
            authors.push({ label: item.author, value: item.ownerId });
          }
        });

        this.setState({
          folders: [...folders],
          contracts: documents,
          contractsFiltered: documents,
          loading: false,
          contractSelected: null,
          authors: [...authors],
        });
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  handleClickAddMenu(value) {
    switch (value) {
      case "directory":
        this.setState({
          openDialogNewFolder: true,
          anchorEl: null,
          typeForm: "create",
        });
        break;
      case "contract":
        this.setState({
          openDialogNewContract: true,
          anchorEl: null,
          typeForm: "create",
        });
        break;
      default:
        break;
    }
  }

  handleSearch(query, type) {
    if (query === "") {
      this.setState({
        contractsFiltered: this.state.contracts,
      });
    } else {
      switch (type) {
        case "query":
          const resultQuery = this.state.contracts.filter(
            (document) =>
              document.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          this.setState({ contractsFiltered: resultQuery });
          break;
        default:
          break;
      }
    }
  }

  handleFilter = (values) => {
    values.date = values.date
      ? new Date(`${values.date}T00:00`).toLocaleDateString()
      : null;
    const filtered =
      this.state.contracts &&
      this.state.contracts.filter((company) => {
        let isValid = true;
        if (
          values.name &&
          !company.name.toLowerCase().includes(values.name.toLowerCase())
        ) {
          return false;
        }
        if (values.author && values.author !== company.author) {
          return false;
        }
        if (
          values.date &&
          values.date !== company.lastUpdatedDate.toLocaleDateString()
        ) {
          return false;
        }
        if (
          values.tagList.length > 0 &&
          (company.tags === null ||
            !company.tags.some((item) =>
              values.tagList.some((item2) => item === item2.value)
            ))
        ) {
          return false;
        }
        return isValid;
      });

    this.setState({
      contractsFiltered: filtered,
      handledContracts: filtered,
      isFiltered: true,
    });
  };

  handleSaveFolder(formData) {
    const { folderSelected, contractsFolder } = this.state;
    const path = folderSelected
      ? `${folderSelected.path}/${folderSelected.name}`
      : "Subir documento";
    console.log("business contract...");
    this.foldersHttp.createFolder(
      this.props.business.id,
      {
        isCompany: false,
        name: formData.name,
        folderId: !!folderSelected ? folderSelected.folderId : contractsFolder.folderId,
        path
      },
      (data) => {
        this.setState({ alertStatus: true });

        const folder =
          folderSelected !== null ? folderSelected : contractsFolder;
        this.getContractSubfoldersDocuments(folder);
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: `${error.response.data
              ? error.response.data
              : "Ocurrió un error al guardar."
              }`,
          },
        });
      }
    );
  }

  submitSave(data) {
    const type = this.state.typeForm;
    if (type === "create") {
      this.createContract(data);
      this.setState({ openDialog: false });
    } else if (type === "update") {
      this.editContract(data);
    }
  }

  createContract = (formData) => {
    const { folderSelected, contractsFolder } = this.state;
    const formD = new FormData();
    const collaborators = formData.users ? formData.users : [];

    if (formData.file) {
      formD.append("document", formData.file[0]);
      formD.append(
        "extension",
        /(?:\.([^.]+))?$/.exec(formData.file[0].name)[0]
      );
      formD.append("size", formData.file[0].size);
    } else {
      this.setState({
        alertStatusError: {
          show: true,
          message: "Es necesario cargar un archivo.",
        },
      });
      return;
    }

    formD.append("name", formData.name ? formData.name.trim() : "");
    formD.append(
      "description",
      formData.description ? formData.description.trim() : "Sin Descripción"
    );
    formD.append(
      "path",
      folderSelected === null
        ? "Contratos"
        : `${folderSelected.path}/${folderSelected.name}`
    );
    formD.append("companyId", this.props.business.id);
    formD.append("owner", this.props.currentUser.userId);
    formD.append("endDate", formData.endDate);

    if (formData.tags) {
      formData.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value);
      });
    }

    formD.append(
      "folderId",
      folderSelected === null
        ? contractsFolder.folderId
        : folderSelected.folderId
    );

    this.documentsHttp.createDocument(
      this.props.business.name,
      formD,
      (data) => {
        this.submitCollaborators(data._id, collaborators);
        this.setState({ alertStatus: true });

        const folder =
          folderSelected !== null ? folderSelected : contractsFolder;
        this.getContractSubfoldersDocuments(folder);
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: `${error.response.data
              ? error.response.data
              : "Ocurrió un error al guardar."
              }`,
          },
        });
      }
    );
  };

  editContract(formData) {
    const formD = new FormData();
    const { folderSelected, contractsFolder, contractSelected } = this.state;
    const collaborators = formData.users ? formData.users : [];

    if (formData.file) {
      formD.append("document", formData.file[0]);
      formD.append(
        "extension",
        /(?:\.([^.]+))?$/.exec(formData.file[0].path)[0]
      );
      formD.append("size", formData.file[0].size);
      formD.append("name", formData.name);
      formD.append("oldName", contractSelected.name);
      formD.append("oldExtension", contractSelected.extension);
    }

    formD.append("description", formData.description);
    formD.append(
      "path",
      folderSelected === null
        ? "Contratos"
        : `${folderSelected.path}/${folderSelected.name}`
    );
    formD.append(
      "folderId",
      folderSelected === null
        ? contractsFolder.folderId
        : folderSelected.folderId
    );
    formD.append("documentId", contractSelected.id);
    formD.append("owner", this.props.currentUser.userId);
    formD.append("companyId", this.props.business.id);
    formD.append("endDate", formData.endDate);

    if (formData.tags) {
      formData.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value);
      });
    }

    if (contractSelected.documentTypeId) {
      formD.append("documentTypeId", contractSelected.documentTypeId);
    }

    if (contractSelected.hasTemplate) {
      formD.append("templateId", contractSelected.templateId);
    }

    this.documentsHttp.editDocument(
      formD,
      (data) => {
        this.submitCollaborators(data._id, collaborators);
        this.setState({ alertStatus: true });

        const folder =
          folderSelected !== null ? folderSelected : contractsFolder;
        this.getContractSubfoldersDocuments(folder);
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: `${error.response.data
              ? error.response.data
              : "Ocurrió un error al guardar."
              }`,
          },
        });
      }
    );
  }

  deleteContract() {
    // debugger;
    const { contractSelected, folderSelected, contractsFolder } = this.state;
    const dataDocument = {
      documentId: contractSelected.id,
      path:
        folderSelected === null
          ? "Contratos"
          : `${folderSelected.path}/${folderSelected.name}`,
      name: contractSelected.name,
      companyId: this.props.business.id,
      owner: this.props.currentUser.userId,
    };

    this.setState({ alertDelete: false });

    this.documentsHttp.deleteDocument(
      dataDocument,
      (resp) => {
        this.setState({
          alertStatusDelete: true,
          anchorItemSelected: null,
        });

        const folder =
          folderSelected !== null ? folderSelected : contractsFolder;
        this.getContractSubfoldersDocuments(folder);
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: `${error.response.data
              ? error.response.data
              : "Ocurrió un error al eliminar."
              }`,
          },
        });
      }
    );
  }

  openDocumentViewer() {
    const { contractSelected, folderSelected } = this.state;
    const params = {
      documentId: contractSelected.id,
      path: `${folderSelected !== null
        ? folderSelected.path + "/" + folderSelected.name
        : "Contratos"
        }/${contractSelected.name}`,
      companyId: this.props.business.id,
      owner: this.props.currentUser.userId,
    };

    const auxName = contractSelected.name.split(".");
    const extension = auxName[auxName.length - 1];

    this.checkReviewOrSign(contractSelected.id);

    this.documentsHttp.downloadDocument(
      params,
      (resp) => {
        if (extension.toLowerCase() === "pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );
          this.setState({
            filePdf: url,
            titlePdf: contractSelected.name,
            openDocumentPdfViewer: true,
          });
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, contractSelected.name);
        }
      },
      (error) => {
        this.setState({
          alertStatusError: {
            show: true,
            message: "Ocurrió un error al abrir documento. Intente más tarde.",
          },
        });
      }
    );
  }

  getCollaborators() {
    this.collaboratorHttp.getCollaborators(
      this.state.contractSelected.id,
      (resp) => {
        const collaborators = [];

        if (resp.length > 0) {
          resp.forEach((item) => {
            collaborators.push({ label: item.completeName, value: item.id });
          });
        }

        this.setState({
          openDialogNewContract: true,
          typeForm: "update",
          anchorItemSelected: null,
          collaborators: collaborators,
        });
      },
      (error) => {
        this.setState({
          openDialogNewContract: true,
          typeForm: "update",
          anchorItemSelected: null,
          collaborators: [],
        });
      }
    );
  }

  saveCollaborators(collaborators) {
    this.collaboratorHttp.saveCollaborators(
      collaborators.documentId,
      collaborators.idCollaborators,
      collaborators.emails,
      (resp) => {
        this.setState({ collaborators: [] });
      },
      (error) => {
        this.setState({
          collaborators: [],
          alertStatusError: {
            show: true,
            message: "Ocurrió un error al guardar colaboradores.",
          },
        });
      }
    );
  }

  updateCollaborators(collaborators) {
    this.collaboratorHttp.updateCollaborators(
      collaborators.documentId,
      collaborators.idCollaborators,
      collaborators.emails,
      (resp) => {
        this.setState({ collaborators: [] });
      },
      (error) => {
        this.setState({
          collaborators: [],
          alertStatusError: {
            show: true,
            message: "Ocurrió un error al guardar colaboradores.",
          },
        });
      }
    );
  }

  submitCollaborators(documentId, collaborators) {
    const collaboratorIds = this.state.collaborators;
    const newUserIds = [];
    const newUserEmails = [];

    collaborators.forEach((item) => {
      if (
        collaboratorIds.length === 0 ||
        !collaboratorIds.some((user) => user.value === item.value)
      ) {
        newUserIds.push(item.value);
        newUserEmails.push(item.email);
      }
    });

    if (collaboratorIds.length === 0 && newUserIds.length > 0) {
      const documentCollaborators = {
        documentId: documentId,
        idCollaborators: newUserIds,
        emails: newUserEmails,
      };

      this.saveCollaborators(documentCollaborators);
    } else if (collaboratorIds.length !== 0) {
      const documentCollaborators = {
        documentId: documentId,
        idCollaborators: collaborators.map((item) => item.value),
        emails: newUserEmails,
      };

      this.updateCollaborators(documentCollaborators);
    }
  }

  handleClickItemMenu = (value) => {
    switch (value) {
      case "delete":
        this.setState({ alertDelete: true, anchorItemSelected: null });
        break;
      case "update":
        this.getCollaborators();
        break;
      case "open":
        this.setState({ anchorItemSelected: null });
        this.openDocumentViewer();
        break;
      default:
        break;
    }
  };

  handleFolderSelect(folder) {
    const folderSelected = folder.parentFolder !== null ? folder : null;
    this.setState({
      folderSelected: folderSelected,
      folders: [],
      contractsFiltered: [],
    });

    if (folder != null) {
      this.getContractSubfoldersDocuments(folder);
    } else {
      this.getContractSubfoldersDocuments(this.state.contractsFolder);
    }
  }

  getPendingActivities() {
    this.setState({ pendingActivitiesLoad: true });
    this.calendarHttp
      .getPendingActivities()
      .then(({ data }) => {
        this.setState({
          pendingActivities: data,
          pendingActivitiesLoad: false,
        });
      })
      .catch(() => {
        this.setState({ pendingActivities: [], pendingActivitiesLoad: false });
      });
  }

  getInvitations() {
    this.setState({ pendingDocumentsLoad: true });
    this.calendarHttp
      .getInvitationsById()
      .then(({ data }) => {
        this.setState({
          pendingDocuments: data,
          pendingDocumentsLoad: false,
        });
      })
      .catch(() => {
        this.setState({ pendingDocuments: [], pendingDocumentsLoad: false });
      });
  }

  getUsersByCompanyId() {
    this.setState({ usersLoad: true });
    this.companyHttp
      .getUsersByCompanyId(window.location.href.split("home/")[1])
      .then(({ data }) => {
        this.setState({ users: data, usersLoad: false });
      })
      .catch(() => {
        this.setState({ users: [], usersLoad: false });
      });
  }

  openDocument(documentId) {
    this.documentsHttp.downloadDocumentById(
      documentId,
      (resp, extension) => {
        this.setState({ loadFile: false });
        if (extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );
          this.setState({
            filePdf: url,
            titlePdf: document.name,
            openDocumentPdfViewer: true,
          });
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${document.name}${extension}`);
        }
      },
      (error) => {
        this.setState({
          openAlert: true,
          typeMessage: "error",
          message: "Ocurrió un error al abrir documento. Intente más tarde.",
          loadFile: false,
        });
      }
    );
  }

  checkReviewOrSign(documentId) {
    this.calendarHttp.checkReviewOrSign(
      documentId,
      (resp) => {
        this.setState({ enableReview: resp.review, enableSign: resp.sign });
      },
      () => { }
    );
  }

  render() {
    const { business } = this.props;
    const {
      accessDocumentsIsEnabled,
      anchorEl,
      anchorItemSelected,
      alertStatus,
      alertStatusError,
      alertDelete,
      alertStatusDelete,
      openDialogNewFolder,
      openDialogNewContract,
      folders,
      contractsFiltered,
      contractSelected,
      pendingActivities,
      pendingActivitiesLoad,
      pendingDocuments,
      pendingDocumentsLoad,
      usersLoad,
      users,
      contractsExpireLoad,
      contractsExpire,
      openFilterDialog,
      authors,
    } = this.state;

    return (
      <div>
        <Grid container spacing={2}>
          <DashboardCard
            number={pendingActivities.length}
            text="Actividades pendientes"
            type="activities"
            style="detail"
            id={"activities"}
            loading={pendingActivitiesLoad}
            enabledExpand
            onExpand={() => this.getPendingActivities()}
          >
            <List component="div" disablePadding>
              {pendingActivitiesLoad ? (
                <ListItem style={{ paddingLeft: "0px" }}>
                  <ListItemText
                    className="DashboardCard-listItemText"
                    secondary={
                      <span>
                        <em className="fa fa-spinner fa-spin"></em>
                        &nbsp;&nbsp;Cargando actividades...
                      </span>
                    }
                  />
                </ListItem>
              ) : (
                pendingActivities.map((item) => (
                  <div key={uuid()}>
                    <Divider />
                    <ListItem style={{ paddingLeft: "0px" }}>
                      <ListItemText
                        className="DashboardCard-listItemText"
                        secondary={item.name}
                      />

                      <ListItemSecondaryAction style={{ right: "-8px" }}>
                        <IconButton
                          onClick={() => {
                            this.calendarHttp.findActivityById(
                              item.id,
                              (data) => {
                                localStorage.setItem(
                                  "selectActivity",
                                  JSON.stringify(data)
                                );
                                document.getElementById("calendar").click();
                              },
                              () => { }
                            );
                          }}
                        >
                          <ArrowForwardIcon color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ))
              )}
            </List>
          </DashboardCard>

          <DashboardCard
            number={pendingDocuments.length}
            text="Documentos enviados a firma"
            type="invitations"
            style="detail"
            id={"documents"}
            loading={pendingDocumentsLoad}
            enabledExpand
            onExpand={() => this.getInvitations()}
          >
            <List component="div" disablePadding>
              {pendingDocumentsLoad ? (
                <ListItem style={{ paddingLeft: "0px" }}>
                  <ListItemText
                    className="DashboardCard-listItemText"
                    secondary={
                      <span>
                        <em className="fa fa-spinner fa-spin"></em>
                        &nbsp;&nbsp;Cargando Documentos...
                      </span>
                    }
                  />
                </ListItem>
              ) : (
                pendingDocuments.map((item) => (
                  <div key={uuid()}>
                    <Divider />
                    <ListItem style={{ paddingLeft: "0px" }}>
                      <ListItemText
                        className="DashboardCard-listItemText"
                        secondary={item.document.documentName}
                      />

                      <ListItemSecondaryAction style={{ right: "-8px" }}>
                        <IconButton
                          onClick={() => {
                            this.calendarHttp.findActivityById(
                              item.activity.id,
                              (data) => {
                                localStorage.setItem(
                                  "selectActivity",
                                  JSON.stringify(data)
                                );
                                document.getElementById("calendar").click();
                              },
                              () => { }
                            );
                          }}
                        >
                          <ArrowForwardIcon color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ))
              )}
            </List>
          </DashboardCard>

          <DashboardCard
            number={users.length}
            text="Miembros de equipo"
            type="members"
            style="detail"
            id={"members"}
            loading={usersLoad}
            enabledExpand
          >
            <List component="div" disablePadding>
              {users.map((item) => (
                <div key={uuid()}>
                  <Divider />
                  <ListItem style={{ paddingLeft: "0px" }}>
                    <ListItemAvatar>
                      <Avatar style={{ border: "2px solid white" }}>
                        {item.completeName.toUpperCase().charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className="DashboardCard-listItemText"
                      primary={item.completeName}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </DashboardCard>

          <Grid container item xs={12}>
            <Grid item xs={10}>
              <TextField
                margin="dense"
                label="¿Qué buscas?"
                variant="outlined"
                fullWidth={true}
                onChange={(evt) => {
                  this.handleSearch(evt.target.value, "query");
                }}
              />
            </Grid>

            <Grid xs={2} sm={1} container justify="center" >
              <>
                <IconButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => this.setState({ openFilterDialog: true })}
                >
                  <FilterIcon color="primary" />
                </IconButton>
              </>
            </Grid>

            <DialogFormFilter
              openDialog={openFilterDialog}
              submitActions={(values) => {
                this.handleFilter(values);
                this.setState({
                  openFilterDialog: false,
                });
              }}
              onCancel={() => {
                this.handleSearch("", "tag");
                this.setState({ openFilterDialog: false });
              }}
              authors={authors}
              tags={
                business !== null
                  ? business.tags.map((tag) => ({
                    label: tag,
                    value: tag,
                  }))
                  : []
              }
            />

            <Grid container justify="center" sx={12} sm={1}>
              <>
                <Button
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  variant="contained"
                  color="primary"
                  onClick={(evt) =>
                    this.setState({ anchorEl: evt.currentTarget })
                  }
                  enabled
                >
                  +&nbsp;Nuevo
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => this.setState({ anchorEl: null })}
                >
                  {menuAdd.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(evt) => {
                        this.handleClickAddMenu(item.value);
                      }}
                      disabled={!item.active}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <h4>Folders</h4>
          </Grid>

          <TableBusiness
            business={business}
            folders={folders}
            contracts={contractsFiltered}
            onFolderSelected={(folder) => {
              this.handleFolderSelect(folder);
            }}
            onClickContract={(value) => {
              this.setState({ contractSelected: value });
            }}
            onClickContractOptions={(event, value) => {
              this.setState({
                anchorItemSelected: event.currentTarget,
                contractSelected: value,
              });
            }}
            onDoubleClickFile={(event, value) => {
              this.setState({
                contractSelected: value,
              });
              this.openDocumentViewer();
            }}
            folderSelected={this.state.folderSelected}
            documentSelected={this.state.contractSelected}
            loading={this.state.loading}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorItemSelected}
            keepMounted
            open={Boolean(anchorItemSelected)}
            onClose={() => this.setState({ anchorItemSelected: null })}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={(evt) => {
                  this.handleClickItemMenu(item.value);
                }}
                disabled={!item.active}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>

        {openDialogNewFolder && (
          <DialogFormFolder
            openDialog={openDialogNewFolder}
            submitActions={(formData) => this.handleSaveFolder(formData)}
            onCancel={() => this.setState({ openDialogNewFolder: false })}
            typeForm={this.state.typeForm}
          />
        )}

        {openDialogNewContract && (
          <DialogFormContract
            openDialog={openDialogNewContract}
            submitActions={(formData) => this.submitSave(formData)}
            onCancel={() => {
              this.setState({ openDialogNewContract: false });
            }}
            tagsSelect={
              business !== null
                ? business.tags.map((tag) => ({
                  label: tag,
                  value: tag,
                }))
                : []
            }
            documentSelected={contractSelected}
            company={business}
            typeForm={this.state.typeForm}
            collaborators={this.state.collaborators}
            currentUser={this.props.currentUser}
          />
        )}

        {this.state.openDocumentPdfViewer && (
          <DocumentPdfViewer
            url={this.state.filePdf}
            title={this.state.titlePdf}
            open={this.state.openDocumentPdfViewer}
            onClose={() => {
              this.setState({
                openDocumentPdfViewer: false,
                enableReview: false,
                enableSign: false,
              });
            }}
            review={this.state.enableReview}
            onReview={() => {
              this.setState({
                documentReview: { id: this.state.documentIdOpen },
                openDocumentReview: true,
              });
            }}
            sign={this.state.enableSign}
            onSign={() => {
              new CalendarHttp()
                .getFirm(contractSelected.id)
                .then(({ data }) => {
                  window.open(data.documentUrl);
                  this.setState({ refresh: true });
                })
                .catch((error) => {
                  this.setState({
                    alertStatusError: {
                      open: true,
                      message:
                        "Tuvimos problemas para conectarnos con FirmaMex",
                    },
                  });
                });
            }}
          />
        )}

        {this.state.openDocumentReview && (
          <DocumentReview
            openDialog={this.state.openDocumentReview}
            onClose={() => {
              this.setState({ openDocumentReview: false });
            }}
            document={contractSelected}
          />
        )}

        <Alert
          open={alertStatus}
          title="Se ha guardado correctamente"
          type="success"
          onConfirm={() => {
            this.setState({
              alertStatus: false,
              openDialogNewFolder: false,
              openDialogNewContract: false,
            });
          }}
        />

        <AlertConfirm
          open={alertDelete}
          onCancel={() => {
            this.setState({ alertDelete: false });
          }}
          onConfirm={() => this.deleteContract()}
          textContent="El contrato será eliminado. ¿Deseas continuar?"
        />

        <Alert
          open={alertStatusDelete}
          title="Se ha eliminado correctamente"
          type="success"
          onConfirm={() => {
            this.setState({ alertStatusDelete: false });
          }}
        />

        <Alert
          open={alertStatusError.show}
          title={alertStatusError.message}
          type="error"
          onConfirm={() => {
            this.setState({
              alertStatusError: { show: false, message: "" },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(BusinessContract);
