import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, makeStyles, styled } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isExpired } from "../../core/utils";
import { authActions } from "../../core/actions";
import { Redirect } from "react-router";
import Control from "../control/control";
import "./header.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  actions: {
    display: "flex",
    marginBottom: "10px"
  },
  actionButton: {
    marginLeft: "20px",
    "& .MuiIconButton-root": {
      color: "white !important",
      width: "30px !important",
      height: "30px !important",
      minWidth: "0 !important",
      borderRadius: "8px",
      border: "1px solid " + theme.palette.primary.main,
    },
    "& .MuiButton-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const keep_active_session = useSelector(
    (state) => state.authReducer.keep_active_session
  );
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const isExpiredToken = isExpired(sessionStorage.getItem("access_token"));

    if (keep_active_session && isExpiredToken) {
      dispatch(authActions.refreshToken());
    } else if (!keep_active_session && isExpiredToken) {
      dispatch(authActions.logout());
    }
  }, [keep_active_session]);

  return (
    <Grid container className={"header"}>
      <Grid item md={7} xs={12} sm={6}>
        <h1 className="title-position">{props.title}</h1>
      </Grid>
      <Grid
        item
        md={5}
        sm={6}
        xs={12}
        className={classes.actions}
        container
        justifyContent="flex-end"
      >
        <Control />
      </Grid>
      {!isLoggedIn && <Redirect to={"/"} />}
    </Grid>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
};

export default Header;
