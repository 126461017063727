import { axiosRequest, authHeader, securityInstance } from "../../configs/axios.config";
import { colors } from 'axeleratum-sgc-frontend-library'

function getColor() {
  const color = {
    i: (Math.floor(Math.random() * 16) + 1)
  }
  const out = colors['primary-500'][color.i];

  return out;
}
export class UserHttp {

  findAll(success, error) {
    axiosRequest.get('/users', { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findAllCount(success, error) {
    axiosRequest.get('/users/count', { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  getTeamUsers({ page = 1, search = "", includeEmail = false }, success, error) {
    const queries = `page=${page}&search=${search}&includeEmail=${includeEmail}`
    securityInstance.get(`/team/users?${queries}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findAllActives(success, error) {
    axiosRequest.get('/users/actives', { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findUsersById({ id }, success, error) {
    axiosRequest.get(`/users/${id}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  createUser(user, success, error) {
    let companies = ''

    user.companies.forEach((company, index) => {
      if ((index + 1) === user.companies.length) {
        companies += `companyId=${company.value}`
      } else {
        companies += `companyId=${company.value}&`
      }
    })

    if (user.companies.length === 0) {
      companies = 'companyId='
    }

    const newUser = {
      email: user.email.trim().toLowerCase(),
      rfc: user.rfc,
      completeName: user.completeName.trimStart().trimEnd(),
      roles: user.roles ? user.roles.map(role => ({ id: role.value })) : [],
      color: getColor(),
      externalProvider: user.externalProvider
    }

    axiosRequest.post(`/users?${companies}`, newUser, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editUser(user, id, success, error) {
    let companies = ''

    user.companies.forEach((company, index) => {
      if ((index + 1) === user.companies.length) {
        companies += `companyId=${company.value}`
      } else {
        companies += `companyId=${company.value}&`
      }
    })

    if (user.companies.length === 0) {
      companies = 'companyId='
    }

    const newUser = {
      email: user.email.trim().toLowerCase(),
      rfc: user.rfc,
      completeName: user.completeName.trimStart().trimEnd(),
      roles: user.roles ? user.roles.map(role => ({ id: role.value })) : [],
      externalProvider: user.externalProvider
    }
    axiosRequest.put(`/users/${id}?${companies}`,
      newUser, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  blockUser({
    blocked,
    id
  }, success, error) {
    axiosRequest.put(`/users/block/${id}/${blocked}`, {}, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  deleteUser({ id }, success, error) {
    axiosRequest.put(`/users/delete/${id}`, {}, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  assignRoles({
    id,
    roles }, success, error) {
    axiosRequest.put(`/users/assign-roles/${id}`, {
      roles
    }, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findAllUsersByDocumentId(documentId, type, success, error) {
    axiosRequest.get(`/users/getAllUsers${documentId ? `/${documentId}` : ''}`, { headers: authHeader() })
      .then(({ data }) => {
        const out = [];

        data.forEach(item => {
          let isAdd = false;

          if (!item.deleted) {

            if (type === 'external') {
              isAdd = true;

              out.push({
                ...item,
                name: item.completeName,
                userId: item.id
              });
            }

            if (item.externalProvider && (!isAdd) && (type === 'firm')) {
              isAdd = true;

              out.push({
                ...item,
                name: item.completeName,
                userId: item.id
              });
            }

            item.roles.forEach(rol => {
              rol.permissionsInfo.forEach(per => {
                if (per.name === 'Todos' && (!isAdd)) {
                  isAdd = true;

                  out.push({
                    ...item,
                    name: item.completeName,
                    userId: item.id
                  });
                }

                if (per.name === 'Firmar documentos' && (!isAdd) && (type === 'firm')) {
                  isAdd = true;

                  out.push({
                    ...item,
                    name: item.completeName,
                    userId: item.id
                  });
                }

                if (per.name === 'Revisar Documento' && (!isAdd) && (type === 'revision')) {
                  isAdd = true;

                  out.push({
                    ...item,
                    name: item.completeName,
                    userId: item.id
                  });
                }
              })
            });
          }
        });

        success(out);
      })
      .catch(errorResp => error(errorResp));
  }

  allowSaveUsers() {
    return axiosRequest.get(`/users/allow-save-user`, { headers: authHeader() });
  }

}
