import React, { Component } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { connect } from "react-redux";
import { authActions, alertActions } from "../../core/actions";
import CreateAccount from "./create-account";
import RestorePassword from "./restore-password";
import SignIn from "./sign-in";
import img from "../../assets/example-image.png";
import { isMobile } from "axeleratum-sgc-frontend-library";
import "./onboarding.css";
import logoKasiaObscuro from "../../assets/img/logoKasiaHorizontalFondoObscuro.png";
import logoKasiaBlanco from "../../assets/img/logoKasiaHorizontalFondoBlanco.png";
import backgroundImage from "../../assets/img/background.png";

class Onboarding extends Component {
  SIGN_IN = "sign-in";
  CREATE_ACCOUNT = "create-account";
  RESTORE_PASSWORD = "restore-password";

  carouselData = [
    {
      /* title: 'Legaltech', */
      img,
      subtitle: "Sub title",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid!`,
    },
    {
      /* title: 'Legaltech 2', */
      img,
      subtitle: "Sub title 2",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid! 2`,
    },
    {
      /* title: 'Legaltech 2', */
      img,
      subtitle: "Sub title 2",
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum reiciendis nisi aspernatur
        fuga pariatur quia in, natus ipsa, ex ad adipisci. Aperiam, asperiores repellat sunt magnam
        minima id sit aliquid! 2`,
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      selectedForm: this.props.createAccount
        ? this.CREATE_ACCOUNT
        : this.SIGN_IN,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn) {
      this.props.history.replace("/dashboard");
      this.props.closeAlert();
    }

    if (
      prevState.selectedForm !== this.state.selectedForm &&
      this.props.alert.message
    ) {
      this.props.closeAlert();
    }

    if (
      prevState.selectedForm === this.state.selectedForm &&
      this.state.selectedForm === this.CREATE_ACCOUNT &&
      this.props.alert.type &&
      this.props.alert.type === "success"
    ) {
      this.setState({ selectedForm: this.SIGN_IN });
      this.props.history.replace("/" + sessionStorage.getItem("clientKey"));
    }
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.props.history.replace("/dashboard");
    }
  }

  renderForm() {
    const { selectedForm } = this.state;
    const { login, signUp, alert, activeSession, sendEmailPassword } =
      this.props;

    if (selectedForm === this.SIGN_IN) {
      return (
        <SignIn
          submitActions={(form) => {
            const param = document.location.hash.split('/')[1];
            login(form.email.trim().toLowerCase(), form.password, param);
          }}
          onCreateAccount={() =>
            this.setState({ selectedForm: this.CREATE_ACCOUNT })
          }
          onRecoverPassword={() =>
            this.setState({ selectedForm: this.RESTORE_PASSWORD })
          }
          alert={alert}
          handleSession={(ev) => {
            activeSession(ev.target.checked);
          }}
        />
      );
    }

    if (selectedForm === this.CREATE_ACCOUNT) {
      return (
        <CreateAccount
          submitActions={(form) => {
            const path = window.location.hash.split('/');
            const id = path[3]
            const clientKey = path[1]
            sessionStorage.setItem("clientKey", clientKey)
            const newUser = {
              id,
              username: form.email.trim(),
              completeName: form.completeName,
              password: form.password,
              email: form.email.trim().toLowerCase(),
            };
            signUp(newUser);
          }}
          onCancel={() => this.setState({ selectedForm: this.SIGN_IN })}
          errors={alert}
        />
      );
    }

    if (selectedForm === this.RESTORE_PASSWORD) {
      return (
        <RestorePassword
          onReturnSingIn={() => this.setState({ selectedForm: this.SIGN_IN })}
          submitActions={(form) => {
            const param = document.location.hash.split('/')[1];
            sendEmailPassword(form, param);
          }}
          alert={alert}
        />
      );
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Grid container>
        <Grid
          item
          container
          md={6}
          xs={12}
          sm={12}
          className="h-100"
          direction="column"
          alignItems="center"
          justify="center"
          p-b={6}
        >
          <Grid item md={8} sm={7}>
            {children ? (
              children
            ) : (
              <>
                <Hidden mdUp>
                  <img
                    style={{
                      width: "25%",
                      minWidth: "200px",
                      display: "block",
                      margin: "auto",
                    }}
                    alt="Käsiä Solutions"
                    src={logoKasiaBlanco}
                  />
                </Hidden>
                {this.renderForm()}
              </>
            )}
          </Grid>
        </Grid>

        <div className="xs-not-show">
          <Grid
            item
            container
            className="onboarding-right-panel"
            md={6}
            justify="center"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Grid
              container
              item
              className="animate__animated animate__backInUp"
              md={12}
              justify="center"
              style={{ zIndex: "100" }}
            >
              <img
                className="right-panel-logo"
                alt="Käsiä Solutions"
                src={logoKasiaObscuro}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    alert: state.alertReducer,
    keep_active_session: state.authReducer.keep_active_session,
  };
};

const mapDispatchToProps = {
  login: authActions.login,
  signUp: authActions.signUp,
  activeSession: authActions.activeSession,
  closeAlert: alertActions.close,
  sendEmailPassword: authActions.sendEmailPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
