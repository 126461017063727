

export const SignaturesAvailableIcon = ({ color, width, style }) => {
  return (
    <svg width={width} xmlns="http://www.w3.org/2000/svg" focusable={false} viewBox="0 0 24 24" aria-hidden={true} style={style}>
      <path fill={color} d="m12.98991,3.25249l-10,10l0,3.75l3.75,0l10,-10l-3.75,-3.75zm-7.08,11.75l-0.92,0l0,-0.92l8,-8l0.92,0.92l-8,8zm13.79,-10.96c0.39,-0.39 0.39,-1.02 0,-1.41l-2.34,-2.34a0.9959,0.9959 0 0 0 -1.41,0l-1.96,1.96l3.75,3.75l1.96,-1.96z" id="svg_1" />
      <path fill={color} d="m0,16.97222l24,0l0,4l-24,0l0,-4z" id="svg_2" stroke-width="0" />
    </svg>
  )
}
