import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { activityActions } from "../../../core/actions/activity.actions";
import { useEffect, useState } from "react";
import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";

export const FormTemporalUsers = ({ submitActions, onCancel, open, hideRfc = false }) => {
  const dispatch = useDispatch();
  const userExists = useSelector(state => state.activityReducer.userExistError);
  const nameExists = useSelector(state => state.activityReducer.userNameError);
  const rfcExists = useSelector(state => state.activityReducer.userRFCError);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "", rfc: "" })

  const handleSubmit = () => {
    const errors = { email: userExists, name: nameExists, rfc: rfcExists }
    if (!name && !errors.name) {
      errors.name = 'El nombre del usuario es requerido';
    }

    if (name) {
      if (name > 50) {
        errors.name = 'El nombre del usuario no puede ser mayor a 100 caracteres';
      }
    }

    if (!email && !errors.email) {
      errors.email = 'El email es requerido';
    }

    if (rfc && !errors.rfc) {
      const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

      if (!rfcReg.test(rfc)) {
        errors.rfc = 'RFC invalido';
      }
    }

    setErrors(errors);
    if (!!errors.name || !!errors.email || !!errors.rfc) return

    dispatch(activityActions.deleteError());
    const id = uuidv4();
    submitActions({
      id,
      userId: id,
      name: name.trim(),
      email: email.trim().toLowerCase(),
      rfc: rfc.trim(),
      temporal: true,
    })
  }

  const handleChangeName = (name) => {
    setName(name);
  }

  const handleChangeEmail = (email) => {
    setEmail(email);
  }

  const handleChangeRfc = (rfc) => {
    setRfc(rfc);
  }
  useEffect(() => {
    setErrors(err => ({ ...err, email: userExists }));
  }, [userExists]);

  useEffect(() => {
    setErrors(err => ({ ...err, name: nameExists }));

  }, [nameExists]);

  useEffect(() => {
    setErrors(err => ({ ...err, rfc: rfcExists }));
  }, [rfcExists]);



  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre*"
              value={name}
              message={errors.name}
              onChange={(e) => handleChangeName(e)}
              onBlur={() => dispatch(activityActions.validateTemporalUserName(name))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email*"
              value={email}
              type="email"
              message={errors.email}
              onChange={(e) => handleChangeEmail(e)}
              onBlur={() => dispatch(activityActions.validateTemporalUser(email))}
            />
          </Grid>
          {
            !hideRfc && (
              <Grid item xs={12}>
                <TextFieldNative
                  label="RFC"
                  value={rfc}
                  message={errors.rfc}
                  onChange={(e) => handleChangeRfc(e)}
                  onBlur={() => dispatch(activityActions.validateTemporalUserRFC(rfc))}
                />
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            dispatch(activityActions.deleteError());
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
