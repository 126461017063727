import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../../core/redux-form-fields/render-text-field';
import { List, ListItem, ListItemText, Divider, makeStyles, Grid, Button, ListItemSecondaryAction, Typography } from '@material-ui/core';
import { CirclePicker } from 'react-color';

import { RolesHttp } from '../../../core/http/roles.http';
import PermissionGroupItem from './permission-group-item';
import { SwitchField } from './switch-field';
import { Separator } from '@react-pdf-viewer/core';
import { CollectionsBookmarkOutlined } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  gutters: {
    padding: '0'
  },
  inputItem: {
    padding: '0'
  },
  divider: {
    margin: '23px 0px'
  },
  colorPicker: {
    [theme.breakpoints.up('md')]: {
      width: '80% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important'
    },
    '& span': {
      '& div': {
        [theme.breakpoints.up('md')]: {
          width: '36px !important',
          height: '36px !important',
        },
        [theme.breakpoints.down('sm')]: {
          width: '16px !important',
          height: '16px !important',
        },
        '& span': {
          '& div': {
            borderRadius: '8px !important'
          }
        }
      }
    }
  },
  currentColor: {
    width: '106px',
    height: '82px',
    marginRight: '10px',
    borderRadius: '8px'
  },
  buttonSave: {
    float: 'right'
  },
  listItemText: {
    [theme.breakpoints.down('md')]:{
      marginRight: '70px'
    },
    [theme.breakpoints.up('md')]:{
      marginRight: '56px'
    }
  }
}
));

const FormRoles = ({submitActions, handleSubmit, permissions, roleSelected, initialize}) => {
  const classes = useStyles()
  const [colorSelected, setColorSelected] = useState('')
  const [permissionsSelected, setPermissionsSelected] = useState([])
  const [permissionsEdited, setPermissionsEdited] = useState([])
  const [checkAllCompanies, setAllCompanies] = useState(false)
  
  useEffect(() => {
    if (roleSelected) {
      const httpRoles = new RolesHttp()
      httpRoles.findRolById({id: roleSelected.value}, data => {
        initialize({ 
          name: data.name
        });
        setColorSelected(data.color)
        setAllCompanies(data.allCompanies)
        setPermissionsSelected(data.permissionGroups)
        cargaPermisosDelRol(data.permissionGroups)
      }, error => {})
    } else {
      initialize({ 
        name: ''
      });
      setPermissionsSelected([])
      setAllCompanies(false)
      setColorSelected('#b0bec5')
    }
  },[initialize, roleSelected])

  function cargaPermisosDelRol(permissionGroups){
    while(permissionsEdited.length > 0){
      permissionsEdited.pop();
    }
    
    permissionGroups.forEach(pg => {
      if (pg.permissions.length > 0){
        pg.permissions.forEach(p => {
          let item = {"id": "", 
                      "value": true};
          item.id = p.id;
          permissionsEdited.push(item)
        })
      }
    })
    setPermissionsEdited(permissionsEdited)
  }

  
  function handleSetColor(value){
    setColorSelected(value.hex)
  }

  function handleBeforeSubmit(evt) {
    evt.preventDefault()
    const switches = []
    const elements = evt.target.elements
    

    for (let i = 0 ; i < elements.length; i++){
      const element = elements[i];

      if (element.type === 'checkbox') {
        if ((element.name !== 'permission-group' && element.name !== 'allCompanies') && element.checked) {
          switches.push({id: element.name})
        }
      }
    }

    permissionsEdited.forEach(p => {
      if (!switches.includes(p.id)) {
        switches.push({id: p.id})
      }
    })  
    
    const newDataRole = {
      name: elements[0].value,
      permission: switches,
      color: colorSelected,
      allCompanies: false,
    }
    submitActions(newDataRole)
  }

  /*****************************************************************************************
  **  MDR handlePermisison1 es la función que guarda el estado de los permisos            **
  ** cada vez que se le da un click a un permiso, enlaza con el componente de permission  **
  ******************************************************************************************/

  const handlePermissions1 = (item, grupoDePermisos, source) => {
    if(source === "G"){
      handlePermissionsGroup(item, grupoDePermisos);
    } else {
      handlePermissions(item)
    }
  }

  const handlePermissions = (item) => {
    if (!item.value){
      permissionsEdited.forEach((p, index, object) => {
        if(p.id === item.id){
          object.splice(index, 1)
        }
      })
    } else {
      permissionsEdited.push(item)
    }
    setPermissionsEdited(permissionsEdited)
  }

  const handlePermissionsGroup = (value, grupoDePermisos) => {
    grupoDePermisos.permissions.forEach(permiso => {
      let item = {
        id: permiso.id,
        value: true
      }
      if(!value){
        let encontrado = false;
        permissionsEdited.forEach((p, index, object) => {
          if(p.id === item.id) {
            object.splice(index, 1) 
          }
        })
      }else{
        let encontrado = false
        permissionsEdited.forEach((p, index, object) => {
          if(p.id === item.id) {
            encontrado = true
          }
        })
        if(!encontrado){
          permissionsEdited.push(item)
        }
      }
    })
    setPermissionsEdited(permissionsEdited)
  }

  /*******************************************************************
  **  FIN DE ADECUACIONES PARA EL MANEJO DE ROLES                   **
  ********************************************************************/

  return (
    <form  onSubmit={ (evt) => handleBeforeSubmit(evt) }>   
      <List dense className={classes.root}>
        {/*  Se elimina el switch de todos las empreas para los roles
        <ListItem classes={{gutters: classes.gutters}} disabled>
          <ListItemText primary="Nombre"  />
          <ListItemSecondaryAction>
            <Typography variant="body1" display='inline' color={'primary'} gutterBottom>Todas las bóvedas</Typography>
            <SwitchField
              id={`allCompanies`}
              checked={ checkAllCompanies }
              name={`allCompanies`}
              onClick={ (value) => {setAllCompanies(value)}}
            />                
          </ListItemSecondaryAction>
        </ListItem>
         */}
        <ListItem className={classes.inputItem}>
          <Field
              id="name"
              name="name"
              required={ true }
              placeholder="Nombre del rol"
              label="Nombre"
              component={ RenderTextField }
            />
        </ListItem>

        <ListItem classes={{gutters: classes.gutters}} disabled>
          <ListItemText primary="Color"  />
        </ListItem>
        <ListItem className={classes.inputItem}>
          <Grid container>
            <Grid item md={4} xs={12} sm={12}>
              <div className={classes.currentColor} style={{backgroundColor: colorSelected}}>
              </div>
            </Grid>
            
            <Grid item md={8} xs={12} sm={12}>
              <CirclePicker
                className={classes.colorPicker}
                colors={[
                  '#f44336', '#e91e63', '#9c27b0', '#673ab7',
                  '#3f51b5', '#2196f3', '#0276aa', '#03a9f4',    
                  '#008394', '#00695f', '#357a38', '#b23c17',
                  '#b2102f', '#4615b2', '#00a152', '#14a37f',
                  '#b26500', '#b28900',
                ]}  
                circleSize={40}
                onChangeComplete={(color) => handleSetColor(color)}
                />
            </Grid>
            
          </Grid>
        </ListItem>
        <Divider classes={{root: classes.divider}} component="li" />

        <ListItem classes={{gutters: classes.gutters}} disabled>
          <ListItemText primary="Permisos"  />
        </ListItem>

        {
          permissions.filter((item)=>item.label !== 'Administración del Sistema').map((permissionGroup, index) => {       
            return (
              <PermissionGroupItem
                permissionGroup={permissionGroup}
                classes={classes}
                roleSelected={roleSelected}
                permissionsSelected={permissionsSelected}
                key={index}
                handlePermissions1={handlePermissions1}
              />
            )
          }) 
        }

      </List>
      <Button
          className="ml-2"
          type="submit"
          variant="contained"
          color="primary"
          classes={{root: classes.buttonSave}}
        >
          Guardar
        </Button>
    </form>
  )
}

const validate = (values) => {
  const errors = { 
    name: '',
  }
  
  if (!values.name) {
    errors.name = 'El nombre es requerido';
  }

  return errors;
}

export default reduxForm({
  form: 'FormRoles',
  validate
})(FormRoles);