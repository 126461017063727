export const authConstants = {
  LOGIN_SUCCESS: 'login_success',
  LOGIN_FAILURE: 'login_failure',
  LOGOUT: 'logout',
  SIGNUP_SUCCESS: 'signup_success',
  SIGNUP_FAILURE: 'signup_failure',
  ACTIVE_SESSION: 'active_session',
  SEND_EMAIL_PASSWORD_FAILURE: 'send_email_password_',
  SENT_EMAIL_PAWSSWORD: 'sent_email_pawssword',
  REFRESH_TOKEN_SUCESS: 'refresh_token_success',
  REFRESH_TOKEN_FAILURE: 'refresh_token_failure',
  RESTORE_PASSWORD: 'restore_password',
  RESTORE_PASSWORD_SUCCESS: 'restore_password_success,',
  RESTORE_PASSWORD_FAILURE: 'restore_password_failure'
}