import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Fab,
  Drawer,
} from "@material-ui/core";
// import Add from "@material-ui/icons/Add";
import DialogFormDocument from "./DialogFormDocumentFN";
import AlertInfo from "@material-ui/lab/Alert";
import {
  DocumentPdfViewer,
  isMobileOnly,
} from "axeleratum-sgc-frontend-library";
import Details from "./details";
import { Alert } from "../from-library/alerts/Alert";
import { DocumentsHttp } from "../../core/http/documents.http";
import { FoldersHttp } from "../../core/http/folders.http";
import DocumentReview from "./document-review";
import saveAs from "file-saver";
import { CalendarHttp } from "../../core/http/calendar.http";
import DialogFormFolder from "./dialog-form-folder";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RestoreFile from "./restore-file";
import { FormActivityNative } from "../control/calendar/forms/form-activity-native";
import { menuItems, createSignatureOptions, createReviewOptions, documentTransactions, menuOptions } from "../../core/utils/menuOptions";
import MoveElement from "./selectFolder/MoveElement";
import { FormSignatureActivity } from "../control/calendar/forms/new-activity/signature/FormSignatureActivity";
import { DialogScannedCopy } from "./scanned-copy/DialogScannedCopy";
import { DialogSignatureStatus } from "../dialogs/activities/DialogSignatureStatus";
import { AlertConfirm } from "../from-library/alerts/AlertConfirm";
import { TableFolderFileSystem } from "../from-library/table-folder/TableFolderFileSystem";
import { OptionsMenu } from "./components/OptionsMenu";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { useVault } from "../../hooks/useVault";
import { permissions } from "../../core/utils/authorities";
import { convertBytes } from "../../core/helpers";

const alertConfirmTypes = {
  DELETE_DOCUMENT_REVISION: "DELETE_DOCUMENT_REVISION",
  DELETE_FOLDER: "DELETE_FOLDER",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  UPDATE_FOLDER: "UPDATE_FOLDER"
}

const invalidFolderNames = [
  "templates",
  "plantillas",
  "libros corporativos",
  "trash",
];

export const FileSystem = () => {

  const documentsHttp = new DocumentsHttp();
  const calendarHttp = new CalendarHttp();
  const foldersHttp = new FoldersHttp();

  const params = useParams();
  const location = useLocation();

  //options menu
  const [optionsMenu, setOptionsMenu] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [userPermissions, setUserPermissions] = useState({})
  const [anchorOptionsFile, setAnchorOptionsFile] = useState(null)
  const [notVisibleMenuOptions, setNotVisibleMenuOptions] = useState([]);
  // pdf viewer
  const [filePdf, setFilePdf] = useState(null);
  const [titlePdf, setTitlePdf] = useState(null);
  const [enableSign, setEnableSign] = useState(false);
  const [openDocumentPdfViewer, setOpenDocumentPdfViewer] = useState(false);
  //Documents Reviews
  const [openDocumentReview, setOpenDocumentReview] = useState(false);
  const [openDocumentFileReview, setOpenDocumentFileReview] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [loadActivity, setLoadActivity] = useState(false);
  //Documents signatures
  const [openActivitySign, setOpenActivitySign] = useState(false);
  const [openActivityDetail, setOpenActivityDetail] = useState(false);
  const [activityDetail, setActivityDetail] = useState(null);
  //folders
  const [openDialogNewFolder, setOpenDialogNewFolder] = useState(false);
  //documents
  const [openRestoreFile, setOpenRestoreFile] = useState(false);
  const [openDialogScannedCopy, setOpenDialogScannedCopy] = useState(false);
  //forms
  const [openDialogNewDocument, setOpenDialogNewDocument] = useState(false);
  //Alerts
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState("error")
  //Alert Confirm
  const [alertConfirmOpen, setAlertConfirmOpen] = useState(false)
  const [alertConfirmMessage, setAlertConfirmMessage] = useState("")
  const [alertConfirmActionType, setAlertConfirmActionType] = useState("")
  //move elements
  const [openMoveElement, setOpenMoveElement] = useState(false);

  const {
    documents,
    folders,
    loading,
    itemSelected,
    vault,
    currentUser,
    arrayPath,
    currentFolderId,
    requestFileSystem,
    saveArrayPath,
    setOpenBackDrop,
    setItemSelected,
  } = useVault();




  const setAlertConfirmDefault = () => {
    setAlertConfirmOpen(false)
    setAlertConfirmMessage("")
    setAlertConfirmActionType("")
  }
  const setAlertDefault = () => {
    setAlertOpen(false)
    setAlertTitle("")
    setAlertType("")
  }

  const openAlert = (title, type) => {
    setAlertTitle(title)
    setAlertType(type)
    setAlertOpen(true)
  }

  const openAlertConfirm = (message, type) => {
    setAlertConfirmOpen(true)
    setAlertConfirmMessage(message)
    setAlertConfirmActionType(type)
  }
  const handleCloseOptionsFile = () => { setAnchorOptionsFile(null) }

  const deleteDocumentItem = () => {
    setOpenBackDrop(true)
    setAnchorOptionsFile(null);

    const dataDocument = {
      documentId: itemSelected.id,
      path: itemSelected.path,
      name: `${itemSelected.name}${itemSelected.extension}`,
      companyId: vault.id,
      owner: currentUser.userId,
    };

    documentsHttp.deleteDocument(
      dataDocument,
      (resp) => {
        setOpenBackDrop(false)
        openAlert("Se ha eliminado correctamente", "success");
        requestFileSystem()
        setItemSelected(null);
      },
      (error) => {
        const message =
          error && error.response.data
            ? error.response.data
            : "Ocurrió un error al eliminar.";
        openAlert(message, "error")
        setOpenBackDrop(false)
        console.error("error guardando");
      }
    );
  };

  const deleteFolder = () => {
    setAnchorOptionsFile(null);
    setOpenBackDrop(true)

    foldersHttp.deleteFolder(
      itemSelected.id,
      vault.id,
      (data) => {
        openAlert("Se ha eliminado correctamente", "success")
        requestFileSystem()
        setOpenBackDrop(false)
      },
      (error) => {
        const message = `${error.response.data
          ? error.response.data
          : "Ocurrió un error al eliminar."}`
        openAlert(message, "error")
        setOpenBackDrop(false)
      }
    );
  }

  const getCollaborators = () => {
    setAnchorOptionsFile(null);
    setOpenDialogNewDocument(true);
  }

  const handleOpenCreationSign = () => {
    const maxCapacity = 20971520 // valor equivalente a 20MB (20*1024e2)
    console.log(itemSelected.size)

    if (itemSelected.size < (maxCapacity)) {
      setOpenActivitySign(true)
    } else {
      openAlert("No se puede enviar el documento a firma porque tiene un tamaño mayor a 20 MB", 'error')
    }
  }

  const updateFolder = (formData) => {
    setOpenDialogNewFolder(false)
    setOpenBackDrop(true)
    const invalidName = invalidFolderNames.some(
      (name) => name === formData.name.toLowerCase()
    );



    const mensaje = "No se puede crear este folder con la palabra reservada: " + formData.name;

    if (invalidName) {
      openAlert(mensaje, "error")
      return;
    }

    const objUpdate = {
      name: formData.name.trim(),
      isCompanyRoot: !currentFolderId,
      folderId: itemSelected.id,
      parentId: currentFolderId ?? vault.id,
      isCompany: false
    }
    foldersHttp.updateFolder(
      objUpdate,
      (data) => {
        openAlert("Se ha guardado correctamente", "success")
        requestFileSystem()
        setOpenBackDrop(false)
      },
      (error) => {
        const message = `${error.response.data
          ? error.response.data
          : "Ocurrió un error al actualizar."
          }`
        openAlert(message, "error")
        setOpenBackDrop(false)
      }
    );
  }

  const openSignStatus = () => {
    const success = (activity) => {
      setOpenActivityDetail(true);
      setActivityDetail(activity);
    }
    const error = (err) => {
      const message = `${error.response.data
        ? error.response.data
        : "Ocurrió un error con el servidor."
        }`
      openAlert(message, "error")
    }
    calendarHttp.getActivityTypeByDocumentId(itemSelected.id, "firm", success, error);
  }

  const handleClickOptionsFile = (value) => {
    setAnchorOptionsFile(null);
    switch (value) {
      case "delete":
        if (itemSelected?.statusBpm === "Preparar Revisión") {
          openAlertConfirm(`El documento será eliminado. ¿Deseas continuar?`, alertConfirmTypes.DELETE_DOCUMENT_REVISION)
        } else {
          deleteDocumentItem()
        }
        break;
      case "update":
        getCollaborators();
        break;
      case "result_revision":
        setOpenDocumentReview(true);
        break;
      case "open":
        openDocument();
        break;
      case "updateFolder":
        setOpenDialogNewFolder(true);
        break;
      case "deleteFolder":
        if (itemSelected.hasElements) {
          deleteFolder()
        } else {
          openAlertConfirm(`El folder será eliminado. ¿Deseas continuar?`, alertConfirmTypes.DELETE_FOLDER)
        }
        break;
      case "restore":
        setOpenRestoreFile(true);
        break;
      case "immediatelyRevision":
        setOpenDocumentFileReview(true);
        break;
      case "revisionActivity":
        setOpenActivity(true);
        break
      case "signActivity":
        //Aqui creo mi funcion que determina si abre o no abre determinado por el peso del archivo
        handleOpenCreationSign();
        // setOpenActivitySign(true);

        break;
      case "move":
        setOpenMoveElement(true);
        break;
      case "signatureStatus":
        openSignStatus();
        break;
      case "scannedCopy":
        setOpenDialogScannedCopy(true);
        break;
      default:
        break;
    }
  }

  const set_OptionsMenu = (optionsArray, document) => {

    const { revisionStatus } = createReviewOptions;
    const { isFolder, extension, hasReview } = document;

    const arrayOptions = []

    const isPDF = extension === ".pdf";
    if (!isFolder && !isPDF) {
      setOptionsMenu([
        menuOptions.openDocumentOption,
        ...menuItems,
      ])
      return
    }

    const isOwner = !itemSelected?.isFolder && document?.userBy._id === currentUser.userId;

    if (isOwner) {
      arrayOptions[0] = menuOptions.updateOption
      arrayOptions[1] = menuOptions.moveOption
      arrayOptions[2] = menuOptions.deleteOption
    }
    if (
      !isOwner &&
      !userPermissions[permissions.moveDocumentsAndFolders] &&
      !userPermissions[permissions.moveDocumentsAndFoldersFromCompany]
    ) {
      arrayOptions[0] = menuOptions.updateOption
      arrayOptions[1] = menuOptions.deleteOption
    } else if (!isOwner && (userPermissions[permissions.moveDocumentsAndFolders] || userPermissions[permissions.moveDocumentsAndFoldersFromCompany])) {
      arrayOptions[0] = menuOptions.updateOption
      arrayOptions[1] = menuOptions.moveOption
      arrayOptions[2] = menuOptions.deleteOption
    }

    const arrayState = [
      menuOptions.openDocumentOption,
      ...optionsArray,
    ]

    if (hasReview) {
      arrayState.push(revisionStatus)
    }
    setOptionsMenu([...arrayState, ...arrayOptions, menuOptions.restoreOption]);
  }

  const evaluateUserAuth = (document) => {
    const { statusBpm } = document
    const { reviewAlwaysRequired, allowScannedCopy } = currentUser;

    const { signActivity, signatureStatus } = createSignatureOptions;
    const { immediatelyRevision, revisionActivity } = createReviewOptions;
    const { scannedCopy } = documentTransactions;
    switch (statusBpm) {
      case "Preparar Revisión":
        if (userPermissions[permissions.allAuthorities] || userPermissions[permissions.createReviewActivity]) {
          if (reviewAlwaysRequired) {
            set_OptionsMenu([immediatelyRevision, revisionActivity], document);
          } else {
            set_OptionsMenu([signActivity, immediatelyRevision, revisionActivity], document);
          }
        }
        break;
      case "En Revisión":
        set_OptionsMenu([], document);
        break;
      case "Aprobado":
        if (userPermissions[permissions.allAuthorities] || userPermissions[permissions.createSignatureActivity]) {
          set_OptionsMenu([signActivity], document);
        }
        break;
      case "En Firmas":
      case "Rechazo de Firma":
        set_OptionsMenu([signatureStatus], document);
        break;
      case "Aprobado Con Firma":
        if (allowScannedCopy) {
          set_OptionsMenu([signatureStatus, scannedCopy], document);
        } else {
          set_OptionsMenu([signatureStatus], document);
        }
        break;
      case "Flujo de firma cancelado":
        set_OptionsMenu([signatureStatus], document);
        break;
      default:
        set_OptionsMenu([], document);
        break;
    }

  }

  const onClickOptions = (event, type, file) => {
    setOptionsMenu([])
    if (type === "document") {
      documentsHttp.getDocumentInfo(file.id,
        ({ StatusBpm, HasReview }) => {
          evaluateUserAuth({ ...file, statusBpm: StatusBpm, hasReview: HasReview })
        }, error => console.error(error))
    }

    if (type === "folder") {
      const arrayOptions = [];

      if (userPermissions[permissions.moveDocumentsAndFolders] ||
        userPermissions[permissions.moveDocumentsAndFoldersFromCompany]) {
        arrayOptions[0] = menuOptions.editFolder
        arrayOptions[1] = menuOptions.moveFolder
        arrayOptions[2] = menuOptions.deleteFolder
      } else {
        arrayOptions[0] = menuOptions.editFolder
        arrayOptions[1] = menuOptions.deleteFolder
      }
      setOptionsMenu(arrayOptions)
    }

    setAnchorOptionsFile(event.currentTarget)
  }

  const checkReviewOrSign = (documentId) => {
    calendarHttp.checkReviewOrSign(
      documentId,
      (resp) => {
        console.log(resp);
        setEnableSign(resp.sign);
      },
      (error) => { }
    );
  }

  const openDocument = (doc) => {
    setOpenBackDrop(true);
    const document = doc ?? itemSelected;
    const documentName = `${document.name}${document.extension}`
    const params = {
      documentId: document.id,
      path: `${document.path ? document.path + '/' : ''}${documentName}`,
      companyId: vault.id,
      owner: currentUser.userId,
    };

    checkReviewOrSign(document.id);

    documentsHttp.downloadDocument(
      params,
      (resp) => {
        if (document.extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );
          setFilePdf(url);
          setTitlePdf(documentName);
          setOpenDocumentPdfViewer(true);
          setOpenBackDrop(false);
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, documentName);
        }
      },
      (error) => {
        openAlert("Ocurrió un error al abrir documento. Intente más tarde.", "error")
        setOpenBackDrop(false);
      }
    );
  }

  const editDocumentInfo = (data) => {
    setOpenDialogNewDocument(false)
    setOpenBackDrop(true)
    documentsHttp.editDocumentInformation(
      { id: itemSelected.id, ...data, tags: data.tags.map(el => el.value) },
      (data) => {
        setOpenBackDrop(false)
        openAlert("Se ha guardado correctamente", "success")
        requestFileSystem()
      },
      (error) => {
        const message = `${error.response.data
          ? error.response.data
          : "Ocurrió un error al actualizar el documento."}`
        openAlert(message, "error")
        setOpenBackDrop(false)
      }
    );
  }

  const restoreFile = (formData, folder) => {
    setOpenBackDrop(true)
    setOpenRestoreFile(false);

    const data = {
      documentId: itemSelected.id,
      companyId: folder.companyId,
      name: formData.name,
      path: `${folder.path}`,
      folderId: folder.path === "" ? null : folder.id,
      owner: currentUser.userId,
    };

    documentsHttp.restoreDocument(
      data,
      (resp) => {
        openAlert("Se ha restaurado correctamente", "success");
        setOpenBackDrop(false)
        requestFileSystem()
      },
      (error) => {
        let message = ""
        if (error.response.status === 400) {
          message = `${error.response.data
            ? error.response.data
            : "Ocurrió un error al restaurar."
            }`
        } else {
          setOpenBackDrop(false)
          message = `${error.response.data
            ? error.response.data
            : "Ocurrió un error al restaurar."
            }`
        }
        openAlert(message, "error")
      }
    );
  }

  const submitSave = (data) => {
    editDocumentInfo(data);
  };

  const handleAlertConfirm = () => {
    setAlertConfirmDefault()
    if (alertConfirmActionType === alertConfirmTypes.DELETE_DOCUMENT_REVISION) {
      deleteDocumentItem()
    }
    if (alertConfirmActionType === alertConfirmTypes.DELETE_FOLDER) {
      deleteFolder()
    }

  }

  const handleCancelAlertConfirm = () => {
    setAlertConfirmDefault()
    setAnchorOptionsFile(null);

  }

  const handleAlertClick = () => {
    setAlertDefault();
  }

  useEffect(() => {
    const { authorities } = currentUser;
    const objPermissions = authorities.reduce((prev, curr) => ({ ...prev, [curr]: true }), {})
    setUserPermissions(objPermissions);

    if (loading) return
    requestFileSystem()
  }, [location.search, params.vaultId])

  useEffect(() => {
    if (vault) {
      saveArrayPath(vault.name)
    }
  }, [vault, itemSelected])

  useEffect(() => {

    if (arrayPath?.length >= 1) {
      if (arrayPath[1] === "Trash") {
        setNotVisibleMenuOptions([
          "share", "result_revision", "delete",
          "move", "signActivity", "scannedCopy",
          "revisionActivity", "immediatelyRevision",
        ]);
      } else {
        setNotVisibleMenuOptions(["restore"]);
      }
    }
  }, [arrayPath])


  if (loading) return (
    <Grid container style={{ justifyContent: "center", width: "100%" }}>
      <AlertInfo severity="info">Cargando archivos...</AlertInfo>
    </Grid>
  )

  if ([...documents, ...folders].length === 0) return (
    <Grid container style={{ justifyContent: "center", width: "100%" }}>
      <AlertInfo severity="info">Sin datos para mostrar</AlertInfo>
    </Grid>
  )

  return (
    <>

      <Grid
        container
        style={{ width: "100%" }}
        className="animate__animated animate__fadeIn"
      >
        <Grid item md={9} sm={8} xs={12}>
          <TableFolderFileSystem
            onClickOptions={onClickOptions}
            handleDoubleClick={(doc) => openDocument(doc)}
          />
        </Grid>
        {isMobileOnly && (
          <Fab
            size="medium"
            color="primary"
            aria-label="details"
            className="details-document-button"
            disable={!itemSelected}
            onClick={() => setOpenDetails(true)}
          >
            <ExpandLessIcon />
          </Fab>
        )}

        {!isMobileOnly ? (
          <Grid item xs={12} sm={4} md={3}>
            <Details itemSelected={itemSelected} company={vault} />
          </Grid>
        ) : (
          <Drawer
            anchor="bottom"
            open={openDetails}
            onClose={() => setOpenDetails(false)}
          >
            <Grid container item direction="row" justify="flex-end">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => setOpenDetails(false)}
              >
                <ExpandMoreIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid
              container
              item
              xs={12}
              ref={(node) => {
                if (node) {
                  node.style.setProperty("margin-top", "0px", "important");
                }
              }}
            >
              <Details itemSelected={itemSelected} company={vault} />
            </Grid>
          </Drawer>
        )}
      </Grid >

      {openDocumentPdfViewer && (
        <DocumentPdfViewer
          url={filePdf}
          title={titlePdf}
          open={openDocumentPdfViewer}
          onClose={() => {
            setOpenDocumentPdfViewer(false);
            setEnableSign(false)
          }}
          review={false}
          onReview={() => {
            setOpenDocumentReview(true);
          }}
          sign={enableSign}
          onSign={() => {
            calendarHttp.getFirm(itemSelected.id)
              .then(({ data }) => {
                window.open(data.documentUrl);
              })
              .catch((error) => {
                console.log("error haciendo request para obtener firmas");
                openAlert("Tuvimos problemas para conectarnos con Firmamex", "error")
              });
          }}
        />
      )}

      {openDialogNewDocument && (
        <DialogFormDocument
          openDialog={openDialogNewDocument}
          addCollaboratorsToDocumentIsEnabled={false}
          submitActions={(formData) => submitSave(formData)}
          onCancel={() => {
            setOpenDialogNewDocument(false);
            setAnchorOptionsFile(null);
          }}
          tagsSelect={vault.tags.map((tag) => ({ label: tag, value: tag }))}
          company={vault}
          documentSelected={itemSelected}
          typeForm={"update"}
          collaborators={[]}
          currentUser={currentUser}
        />
      )}

      {
        (openDocumentReview || openDocumentFileReview) && (
          <DocumentReview
            immediatelyRevision={openDocumentFileReview}
            openDialog={openDocumentReview || openDocumentFileReview}
            onClose={() => {
              setOpenDocumentReview(false);
              setOpenDocumentFileReview(false);
            }}
            closeModal={() => {
              setOpenDocumentReview(false);
              setOpenDocumentFileReview(false);
            }}
            document={itemSelected}
            user={currentUser}
          />
        )
      }

      {openDialogNewFolder && (
        <DialogFormFolder
          openDialog={openDialogNewFolder}
          submitActions={(formData) => updateFolder(formData)}
          onCancel={() => setOpenDialogNewFolder(false)}
          initialValues={itemSelected}
          typeForm={"update"}
        />
      )}

      {openRestoreFile && (
        <RestoreFile
          open={openRestoreFile}
          company={vault}
          folderName={itemSelected.name.split(".")[0]}
          enableName={true}
          onCancel={() => setOpenRestoreFile(false)}
          submitAction={(data, folder) => restoreFile(data, folder)}
        />
      )}

      {(openActivity) && (
        <FormActivityNative
          disabled
          type={'revision'}
          open={openActivity}
          load={loadActivity}
          document={{
            file: {
              documentId: itemSelected.id,
              name: itemSelected.name,
              users: []
            },
            name: itemSelected.name,
            type: "cloud",
            withOrder: false,
            stickersValue: null,
            reviewers: [],
          }}
          initialValues={{}}
          onSubmit={(activity) => {
            setLoadActivity(true);
            setOpenActivity(false);
            openAlert("Actividad creada", "success")
            requestFileSystem()
          }}
          onCancel={() => setOpenActivity(false)}
        />
      )}

      {(openActivitySign) && (
        <FormSignatureActivity
          open={openActivitySign}
          onCancel={() => setOpenActivitySign(false)}
          document={{
            file: {
              documentId: itemSelected.id,
              name: itemSelected.name,
              users: []
            },
            name: itemSelected.name,
            type: "cloud",
            withOrder: false,
            stickersValue: null,
            reviewers: [],
            externals: []
          }}
          onSubmit={() => {
            setLoadActivity(true);
            setOpenActivitySign(false);
            openAlert("Actividad creada", "success")
            requestFileSystem()
          }}
        />
      )}

      {(openMoveElement) && (
        <MoveElement
          fromCompany={!currentFolderId}
          allowOtherCompanies={!!userPermissions[permissions.moveDocumentsAndFoldersFromCompany]}
          vault={vault}
          origin={currentFolderId ?? vault.id}
          itemSelected={itemSelected}
          onClose={() => { setOpenMoveElement(false) }}
          onSuccess={(response) => {
            requestFileSystem()
            setOpenMoveElement(false)
            openAlert(response, "success")
          }}
          onFail={(response) => {
            openAlert(response, "error")
          }}
        />
      )}

      {
        (openActivityDetail && activityDetail) && (
          <DialogSignatureStatus
            open={openActivityDetail}
            setOpen={(value) => setOpenActivityDetail(value)}
            item={activityDetail}
            onExitDetail={() => {
              setOpenActivityDetail(false)
              setActivityDetail(null);
            }}
            onUpdate={() => openSignStatus()}
          />
        )
      }

      {
        (openDialogScannedCopy && itemSelected) && (
          <DialogScannedCopy
            open={openDialogScannedCopy}
            onCancel={() => setOpenDialogScannedCopy(false)}
            handleSubmit={(msg) => {
              setOpenDialogScannedCopy(false);
              openAlert(msg, 'success')
            }}
            handleError={(message) => {
              openAlert(message, 'error')
            }}
            documentId={itemSelected.id}
            documentName={itemSelected.name}
          />
        )
      }
      <AlertConfirm
        open={alertConfirmOpen}
        onCancel={handleCancelAlertConfirm}
        onConfirm={handleAlertConfirm}
        textContent={alertConfirmMessage}
      />

      <Alert
        maxWidth="md"
        open={alertOpen}
        title={alertTitle}
        type={alertType}
        onConfirm={handleAlertClick}
      />

      <OptionsMenu
        options={optionsMenu.filter(opc => !notVisibleMenuOptions.some(hide => opc.value === hide))}
        anchorEl={anchorOptionsFile}
        handleCloseMenu={handleCloseOptionsFile}
        handleClickMeuItem={handleClickOptionsFile}
      />


    </>
  )
}
