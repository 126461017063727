import { axiosBusinessRequest, authHeader } from "../../../configs/axios.config"


export const getCollaborators = async (documentId) => {
  try {

    const { data } = await axiosBusinessRequest.get(`/collaborators/${documentId}`, {headers: authHeader()});

    return data.map(user => (
      {
        id: user.id, 
        completeName: user.completeName, 
        color: user.color
      }
    ));
    
  } catch (error) {
    console.error(error)
    throw error
  }
}