import React, { Component } from 'react';
import Header from '../dashboard/header';
import { Button, IconButton, Grid, } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";
import { DashboardCard } from './dashboard-card';
import { BusinessGroup } from '../from-library/business-group/business-group';
import { UserHttp } from '../../core/http/user.http';
import AlertInfo from "@material-ui/lab/Alert";
import { Alert } from 'axeleratum-sgc-frontend-library';
import { CalendarHttp } from '../../core/http/calendar.http';
import { CalendarSync } from './calendar-sync';
import { Link } from 'react-router-dom';
import { getVaultsUserDashboard } from '../../core/http/functionRequests';

class DashboardHome extends Component {

  userHttp = new UserHttp();
  calendarHttp = new CalendarHttp();

  constructor(props) {
    super(props);

    this.state = {
      businessList: null,
      alertError: false,
      messageError: '',
      members: 0,
      showSync: false,
      calendarSuccess: false,
      calendarError: false,
      pendingActivities: [],
      pendingActivitiesLoad: false,
    }
  }

  componentDidMount() {
    this.getPendingActivities();
    this.findAllUsers();

    if (window.location.href.includes('?code=') && window.location.href.includes('&state=12345')) {
      const code = window.location.href.split('?code=')[1].replace('&state=12345#/dashboard', '');
      this.setState({ showSync: true });

      new CalendarHttp().getOutlookSync(code).then(() => {
        this.setState({ showSync: false, calendarSuccess: true });
      }).catch(() => {
        this.setState({ showSync: false, calendarError: true });
      });
    } else if (window.location.href.includes('?code=')) {
      const code = window.location.href.split('?code=')[1];
      const token = code.replace('#/dashboard', '')
      this.setState({ showSync: true });

      new CalendarHttp().syncCalendar(decodeURIComponent(token).replace('+', '%20')).then(() => {
        this.setState({ showSync: false, calendarSuccess: true });
      }).catch(() => {
        this.setState({ showSync: false, calendarError: true });
      });
    }
  }

  getPendingActivities() {
    this.setState({ pendingActivitiesLoad: true });
    this.calendarHttp.getPendingActivities().then(({ data }) => {
      this.setState({ pendingActivities: data.filter(el => el.show), pendingActivitiesLoad: false });
      this.findAllBusiness();
    }).catch(() => {
      this.setState({ pendingActivities: [], pendingActivitiesLoad: false });
    });

  }

  findAllUsers() {
    this.userHttp.findAllCount((usersCount) => {
      this.setState({ members: usersCount });
    }, () => { });
  }

  findAllBusiness() {
    getVaultsUserDashboard().then((vaults) => {
      this.getIndicators(vaults);
    }).catch(error => { });
  }

  async getIndicators(companies) {
    var _sentForSigned = 0;
    const activitiesSignature = (await this.calendarHttp.getSignatureActivitiesCurrentUser()).data;

    for (const company of companies) {
      company.pendingFirms = activitiesSignature.filter(el => el.document.document.Company === company.id).length;
      company.firmsToExpire = this.state.pendingActivities.filter(el => el.companyId === company.id).length
      _sentForSigned = _sentForSigned + company.pendingFirms;

    }

    this.setState({ businessList: companies });
    this.setState({ sentForSigned: _sentForSigned })
  }

  render() {
    const { businessList, alertError, messageError, members, showSync, calendarSuccess, calendarError,
      sentForSigned } = this.state;

    return (
      <Grid container>

        {
          showSync &&
          <CalendarSync></CalendarSync>
        }

        <Header
          title="Mi tablero"
          actions={[
            <IconButton variant="outlined">
              <NotificationsIcon color="primary" />
            </IconButton>,
            <IconButton variant="outlined">
              <EventIcon color="primary" />
            </IconButton>,
          ]}
        />

        <Alert
          open={alertError}
          title={messageError}
          type="error"
          onConfirm={() => this.setState({ alertError: false })}
        />

        <Alert
          open={calendarSuccess}
          title="Calendario sincronizado correctamente"
          onConfirm={() => { this.setState({ calendarSuccess: false }); window.location.href = '/'; }}
          type="success"
        />

        <Alert
          open={calendarError}
          title="Hubo un problema al sincronizar el calendario"
          onConfirm={() => { this.setState({ calendarError: false }); window.location.href = '/'; }}
          type="error"
        />

        <Grid container spacing={2}>
          <DashboardCard
            number={businessList ? businessList.length : 0}
            text="Bóvedas"
            type="business"
          />

          <DashboardCard
            number={members}
            text="Miembros de equipo"
            type="members"
          />

          <DashboardCard
            number={sentForSigned}
            text="Documentos enviados a firma"
            type="invitations"
          />
        </Grid>

        <Grid className="mt-5" container spacing={2}>
          <Link to="/dashboard/home/documents-information">
            <Button variant="contained" color="primary">
              Documentos en revisión o firma
            </Button>
          </Link>
        </Grid>

        <Grid className="mt-3" container spacing={2}>
          <Grid item md={6}>
            <h4>Bóvedas</h4>
          </Grid>

          {
            !businessList ?
              <Grid container style={{ justifyContent: 'center', width: '100%' }}>
                <AlertInfo severity="info">Cargando bóvedas...</AlertInfo>
              </Grid>
              :
              <Grid container spacing={2} style={{ width: '100%' }}>
                <BusinessGroup
                  business={businessList}
                  baseUrl="/dashboard/home/"
                />
              </Grid>
          }
        </Grid>
      </Grid>
    );
  }
}

export default DashboardHome;
