import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getRandomColor } from '../functions/colors';
import uid from 'uid';
import { MenuComponent } from '../menu/menu';
import PropTypes from 'prop-types';
import { VerticalCenterDiv } from '../vertical-center-div/vertical-center-div';
import { isMobile } from 'react-device-detect';
import { UserMenu } from './user-menu';
import LockIcon from "@material-ui/icons/Lock";
import './card-business.scss';

export class CardBusiness extends Component {

  renderUserIcon(users, disabled) {
    return users.map((user, index) => {
      if (index < (isMobile ? 3 : 5)) {
        return (
          <div key={uid()} title={user?.label} className="card-business-user-position">
            <div
              className={`${disabled ? 'card-business-user disabled-background' : 'card-business-user'}`}
              style={{ background: !disabled && user.color ? user.color : '#bdbdbd' }}
            >
              <div className="card-business-user-text">
                <b>
                  {user.label?.toUpperCase().charAt(0)}
                </b>
              </div>
            </div>
          </div>
        )
      } else {
        return undefined;
      }
    });
  }

  renderOptions() {
    const { disabled, editCompanyBtnEnabled, blockCompanyBtnEnabled } = this.props;

    return (
      <Grid item md={2} xs={2}>
        <VerticalCenterDiv className="text-right">
          <MenuComponent
            buttonText={<MoreVertIcon />}
            buttonType="icon"
            items={[
              {
                label: 'Editar bóveda',
                value: 'edit',
                disabled: editCompanyBtnEnabled
              }, {
                label: `${disabled ? 'Activar bóveda' : 'Inhabilitar'}`,
                value: 'disable',
                disabled: blockCompanyBtnEnabled
              }
            ]}
            onSelectOption={(data) => this.handleSelectOption(data)}
          />
        </VerticalCenterDiv>
      </Grid>
    );
  }

  handleSelectOption(data) {
    const { selectEdit, selectDisable } = this.props;

    if (data === 'edit') {
      selectEdit();
    }

    if (data === 'disable') {
      selectDisable();
    }
  }

  render() {
    const { businessImage, businessName, users, usersList, onSubmit, disabled, enrollUserBtnEnabled } = this.props;

    return (
      <div className="CardBusiness">
        <Grid className="card-margin mb-2" container>
          <Grid container item md={1} xs={4} alignContent="center" justifyContent='center'>
            <div className='card-business-user-img m-2'>
              <LockIcon />
            </div>
          </Grid>

          <Grid container item md={3} xs={5} alignContent="center">
            <div className="ml-3">
              <strong className={`${disabled ? 'disabled' : ''}`}>{businessName}</strong>
            </div>
          </Grid>

          {
            isMobile &&
            this.renderOptions()
          }

          <Grid container item md={6} xs={12} alignContent="center">
            <Grid className={isMobile ? 'ml-3 mb-2' : ''} container justify={isMobile ? 'flex-start' : 'center'}>
              {users && this.renderUserIcon(users, disabled)}

              {
                (isMobile ? users.length >= 4 : users.length >= 6) &&
                <div className="card-business-user-position">
                  <div className="card-business-user" style={{ background: getRandomColor() }}>
                    <div className="card-business-user-text">
                      <strong>+{users.length - (isMobile ? 3 : 5)}</strong>
                    </div>
                  </div>
                </div>
              }

              <Grid className="card-business-user-add-container ml-2" item md={1}>
                <UserMenu
                  users={usersList}
                  businessUsers={users}
                  onSubmit={(out) => onSubmit(out)}
                  disabled={enrollUserBtnEnabled}
                />
              </Grid>
            </Grid>
          </Grid>

          {!isMobile && this.renderOptions()}

        </Grid>
      </div>
    );
  }
}

CardBusiness.propTypes = {
  businessImage: PropTypes.string,
  businessName: PropTypes.string,
  users: PropTypes.array,
  selectEdit: PropTypes.func,
  selectDisable: PropTypes.func,
  usersList: PropTypes.array,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool
}
