import {
  userConstants
} from '../constants';

import initialState from '../../configs/intialState.config';

export function userReducer(state = initialState.users, action) {
  switch (action.type) {
    case userConstants.SET_AVAILABLE_COMPANIES:
      return {
        ...state,
        companies: action.payload
      }

    case userConstants.CREATE_NEW_USER:
      return {
        ...state,
        creating: true
      }

    case userConstants.ASSIGN_ROLES:
    case userConstants.EDIT_USER:
      return {
        ...state,
        editing: true
      }

    case userConstants.FETCH_USERS:
      return {
        ...state,
        fetchingUsers: true
      }

    case userConstants.FETCH_DATA_TEAM_USERS:
      return {
        ...state,
        fetchingUsers: true,
      }
    case userConstants.FETCH_DATA_TEAM_USERS_SUCCESS:
      const { roles, users, companies, currentUsersPage, totalUsersPages } = action.payload;
      return {
        ...state,
        users: users ?? [],
        usersFiltered: users ?? [],
        fetchingUsers: false,
        roles: roles ?? [],
        companies: companies ?? [],
        currentPage: currentUsersPage,
        totalPages: totalUsersPages
      }

    case userConstants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersFiltered: action.payload,
        fetchingUsers: false
      }

    case userConstants.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        creating: false
      }

    case userConstants.FILTER_USERS_SUCCESS:
    case userConstants.SORT_USER:
      return {
        ...state,
        usersFiltered: action.payload
      }

    case userConstants.ASSIGN_ROLES_SUCCES:
    case userConstants.EDIT_USER_SUCCESS:
      return {
        ...state,
        editing: false
      }

    case userConstants.DELETE_USER:
      return {
        ...state,
        deleting: true
      }

    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleting: false
      }

    case userConstants.DISABLE_USER:
      return {
        ...state,
        blocking: true
      }


    case userConstants.DISABLE_USER_SUCCESS:
      return {
        ...state,
        blocking: false
      }

    case userConstants.ASSIGN_ROLES_FAILURE:
    case userConstants.EDIT_USER_FAILURE:
    case userConstants.DELETE_USER_FAILURE:
    case userConstants.CREATE_NEW_USER_FAILURE:
    case userConstants.FETCH_USERS_FAILURE:
      return {
        ...state,
        editing: false,
        creating: false,
        blocking: false,
        deleting: false
      }

    case userConstants.SAVE_USERS_SEARCH:
      return {
        ...state,
        users: action.payload.users,
        roles: action.payload.roles,
        companies: action.payload.companies,
        currentPage: action.payload.currentUsersPage,
        totalPages: action.payload.totalUsersPages,
        fetchingUsers: false
      }

    case userConstants.SET_CURRENT_PAGE_USERS:
      return {
        ...state,
        currentPage: action.payload
      }

    case userConstants.SET_SEARCH_USERS:
      return {
        ...state,
        search: action.payload
      }
    case userConstants.SET_INCLUDE_EMAIL_SEARCH:
      return {
        ...state,
        includeEmail: action.payload
      }
    default:
      return state;

  }
}
