import React, { Component } from 'react';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { Grid } from '@material-ui/core';
import AlertInfo from "@material-ui/lab/Alert";

import { SelectorRoles } from './selector-roles';

import './roles.scss'
import { RolesHttp } from '../../../core/http/roles.http';
import FormRoles from './form-roles'
import { PermissionsHttp } from '../../../core/http/permissions.http';
class Roles extends Component {
  state = {
    openDialog: false,
    alertStatus: false,
    alertType: "",
    alertMessage: "",
    roleSelected: null,
    roles: [],
    permissions: [],
    typeForm: 'edit',
    chargingRoles: false,
    showForm: false
  }

  httpRoles = new RolesHttp()
  httpPermissions = new PermissionsHttp()

  componentDidMount() {
    this.setRoles()
    this.getPermissions()
  }

  setRoles = async () => {
    this.setState({ chargingRoles: true })

    await this.httpRoles.findAllRoles(data => {
      const dataMapped = data.filter(el => el.name !== "Interno").map(role => {
        return {
          label: role.name,
          value: role.id,
          color: role.color,
          permissions: role.permission,
        }
      })

      this.setState({ roles: dataMapped, chargingRoles: false })
    }, error => {
      this.setState({ chargingRoles: false })
    })
  }

  getPermissions = () => {
    this.httpPermissions.getPermissionsGroup(data => {
      const dataMapped = data.map(permission => {
        return {
          label: permission.name,
          value: permission.id,
          permissions: permission.permissions
          //description: permission.description
        }
      })

      this.setState({ permissions: dataMapped })
    }, error => console.error(error))
  }

  handleSaveRoles = (formData) => {
    const { typeForm } = this.state

    if (typeForm === 'create') {
      this.httpRoles.createRol(formData, data => {
        this.setState({
          alertStatus: true,
          alertMessage: "El rol ha sido guardado correctamente",
          alertType: "sucess",
          roleSelected: {
            label: data.name,
            value: data.id,
            color: data.color,
            permissions: data.permission,
          }
        })
        this.setRoles()
      }, error => {
        this.setState({
          alertStatus: true,
          alertMessage: error,
          alertType: "error",
        })
      })
    } else {
      this.httpRoles.editRol(formData, this.state.roleSelected.value, data => {
        this.setRoles()
        this.setState({
          alertStatus: true,
          alertMessage: "El rol ha sido guardado correctamente",
          alertType: "sucess",
        })
      }, error => {
        console.error(error)
      })
    }
  }

  render() {
    const {
      alertStatus,
      alertMessage,
      alertType,
      roleSelected,
      roles,
      permissions,
      chargingRoles,
      showForm,
    } = this.state

    return (
      <React.Fragment>
        {!chargingRoles &&
          (
            <React.Fragment>
              <SelectorRoles
                className='Selector Selector-roles'
                title='Roles'
                sizeContainer={2}
                elements={roles}
                elementSelected={roleSelected}
                onSelectElement={(element) => this.setState({ roleSelected: element, typeForm: 'edit', showForm: true })}
                onAddRole={() => this.setState({
                  roleSelected: null,
                  typeForm: 'create',
                  showForm: true
                })}
              />

              <Grid item md={6} xs={12} style={{ display: `${showForm ? 'block' : 'none'}` }}>

                <div className='Selector padding-form'>
                  <FormRoles
                    submitActions={(form) => { this.handleSaveRoles(form) }}
                    permissions={permissions}
                    roleSelected={roleSelected}
                  />
                </div>

              </Grid>
            </React.Fragment>
          )
        }

        {(chargingRoles) && (
          <Grid container md={9} xs={12} style={{ justifyContent: 'center', height: '100%' }}>
            <AlertInfo severity="info" >Cargando roles...</AlertInfo>
          </Grid>
        )}

        <Alert
          open={alertStatus}
          title={alertMessage}
          type={alertType}
          onConfirm={() => this.setState({
            alertStatus: false,
            alertMessage: ""
          })}
        />
      </React.Fragment>
    )
  }
}

export default Roles;