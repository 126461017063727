import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  DialogContent,
  LinearProgress,
} from "@material-ui/core";
import moment from "moment";
import { TextFieldNative } from "../../fields/text-field-native";
import "./form-signature-activity.scss"
import { SelectedUsersSimbols } from "./SelectedUsersSimbols";
import { PopUpExternalUser } from "../../../popup/PopUpExternalUser";
import { useDispatch } from "react-redux";
import { activityActions } from "../../../../../../core/actions/activity.actions";
import { PopUpSignersUsers } from "../../../popup/PopUpSignersUsers";
import { CalendarHttp } from "../../../../../../core/http/calendar.http";
import { DocumentsHttp } from "../../../../../../core/http/documents.http";
import saveAs from "file-saver";
import { DocumentSignatures } from "./DocumentSignatures";
import { Alert } from "axeleratum-sgc-frontend-library";
import { SignatureNotificationConfig } from "./SignatureNotificationConfig";
import { CheckBox } from "./CheckBox";
import { getCurrentUser } from "../../../../../../core/helpers";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  container: {
    padding: "15px",
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit'
  },
  accionsContainer: {
    display: 'flex',
    alignItems: "flex-end",
    flexDirection: 'column',
    width: 'inherit',
    gap: "5px",
  }
}));

export const FormSignatureActivity = ({ open, onCancel, document, onSubmit }) => {

  const calendarHttp = new CalendarHttp();
  const documentsHttp = new DocumentsHttp();

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [message, setMessage] = useState("");
  const [typeMessage, setTypeMessage] = useState("error");
  const [openUsers, setOpenUsers] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSignNotification, setOpenSignNotification] = useState(false);
  const [file, setFile] = useState(document);
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const [filePdf, setFilePdf] = useState(null);
  const [stickersValue, setStickersValue] = useState(null);
  const [titlePdf, setTitlePdf] = useState(null);
  const [documentIdOpen, setDocumentIdOpen] = useState(null);
  const [usersToSign, setUsersToSign] = useState([]);
  const [hasNom151, setHasNom151] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);

  const classes = useStyles();

  const filterItem = (item) => stickersValue?.filter((e) => e.userId === item.userId).length !== 0;


  const finishProcess = () => {
    dispatch(activityActions.restartProcess());
  }

  const handleCancel = () => {
    finishProcess();
    onCancel();
  }

  const addExternalsToFile = (users) => {
    file.externals = users;
    setFile(file);
    setOpenExternal(false);
  }

  const handleSaveNotifications = (body) => {
    file.notifications = body;
    setFile(file);
    setOpenSignNotification(false);
  }

  const handleCheckNom = () => {
    if (hasNom151 && file.reviewers.length > 0) {
      file.reviewers = file.reviewers.map(reviewer => ({ ...reviewer, type: "link" }));
      setFile(file);
      dispatch(activityActions.setAllSigners(file.reviewers))
    }
    setHasNom151((state) => !state);
  }

  const handleSubmit = () => {
    if (!name) {
      setNameValidation("El nombre de la actividad es requerido");
      return
    }

    setNameValidation("");
    const { monthsExpirationDefault } = getCurrentUser()
    const threeMonths = moment().add(monthsExpirationDefault ?? 3, 'months').format("YYYY-MM-DD");

    const documentsExist = [];
    const documentsNew = [];
    const documents = [];

    documentsExist.push({
      documentId: file.file.documentId,
      documentName: file.file.name,
      stickers: stickersValue,
      withOrder: file.withOrder,
      notifyExpiringDay: file?.notifications?.notifyExpiringDay ?? true,
      block_expired: file?.notifications?.block_expired ?? true,
      subject: file?.notifications?.subject,
      message: file?.notifications?.message,
      remind_every: file?.notifications?.remind_every,
      expiration_date: file?.notifications?.expiration_date ? file.notifications.expiration_date : threeMonths,
      offset: new Date().getTimezoneOffset(),
      hasNom151,
      reviewers: file.reviewers.map((user, index) => ({
        email: user.email,
        userId: user.userId,
        name: user.name,
        color: user.color ? user.color : "darkgray",
        order: file.withOrder ? index : 0,
        type: user.type,
        rfc: user.rfc ?? "",
        temporal: !!user.temporal,
        stroke: user.stroke
      })),
      externals: file.externals.map(user => user.email)
      // externals: file.externals.map((user, index) => ({
      //   email: user.email,
      //   userId: user.userId,
      //   name: user.name,
      //   color: user.color ? user.color : "darkgray",
      //   rfc: user?.rfc,
      //   temporal: !!user.temporal
      // }))
    });

    setLoad(true);
    const limitDate = moment(new Date()).format("YYYY-MM-DD");

    calendarHttp.createActivity({
      id: null,
      type: 'firm',
      name,
      description,
      tags: [],
      hour: moment(new Date()).format("HH:MM"),
      date: limitDate,
      limitDate: limitDate,
      recordatory: 0,
      documentsNew,
      documentsExist,
      documents,
    }).then((data) => {
      setLoad(false);
      setOpenAlert(false);
      setMessage("Actividad creada");
      setTypeMessage("success");
      finishProcess();
      onSubmit();
    }).catch((error) => {
      let checkMessage =
        error.message === "Cannot set property 'token' of undefined"
          ? true
          : false;
      if (checkMessage === true) {
        console.log("token error ommit");
      } else {
        const msg = error.response.data
        const _err = error.response && error.response.data && error.response.status;
        const _msgError = _err === 401
          ? "No tiene permisos parea crear una actividad"
          : error.message;
        setLoad(false);
        setOpenAlert(true);
        if (_err === 400 && msg.split('"').length > 3) {
          setMessage(msg.split('"')[3]);
        } else {
          setMessage(_err !== 500
            ? _msgError
            : "Hubo un error al crear la actividad")
        }
        setTypeMessage("error")
      }
    });
  }

  const addUsersToFile = (form, withOrder) => {
    setUsersToSign(form)
    if (stickersValue?.length > 0) {
      setStickersValue(old => old.reduce((acc, curr) => {
        const existUser = form.find(el => el.userId === curr.userId);
        if (existUser) {
          return [...acc, curr]
        }
        return acc;
      }, []))
    }

    file.withOrder = withOrder;
    file.reviewers = form;
    file.stickersValue = stickersValue;

    setFile(file);
    setOpenUsers(false);
  }

  const getPdf = () => {
    setLoad(true);

    const documentId = file.file.documentId;
    const nameDocument = file.file.name;

    documentsHttp.downloadDocumentById(
      documentId,
      (resp, extension) => {
        if (extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );

          setFilePdf(url);
          setTitlePdf(nameDocument)
          setDocumentIdOpen(documentId)
          setLoad(false)

          setOpenSignDialog(true);
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${document.name}${extension}`);
        }
      },
      (error) => {
        console.log("error", error);
        setOpenAlert(true);
        setTypeMessage("error");
        setMessage("Ocurrió un error al abrir documento. Intente más tarde.");
        setLoad(false);

      }
    );
  }

  useEffect(() => {
    const { onlyNom151, hasNom151, signatureWithNomDefault } = getCurrentUser();
    if (onlyNom151) {
      setShowCheckBox(false);
      setHasNom151(true);
    } else if (!onlyNom151 && !hasNom151) {
      setShowCheckBox(false);
      setHasNom151(false);
    } else if (!onlyNom151 && hasNom151) {
      setHasNom151(signatureWithNomDefault);
      setShowCheckBox(true);
    }
  }, [])


  return (
    <>
      <Dialog open={open} id="Modal-Create-Signature-Activity">
        <DialogContent className={classes.dialogContent} id="Create-Signature-DialogContent">
          <Grid container spacing={1} className={classes.container} id="Create-Signature-Container-Grid">
            <Grid item xs={12} style={{ marginBottom: "15px" }}>
              <h3 style={{ margin: "2px" }}>{"Nueva actividad de firma"}</h3>
            </Grid>
            <Grid item xs={showCheckBox ? 9 : 12}>
              {/* <h3>Documento</h3> */}
              <span className="fa fa-file primary-text fa-2x doc" />
              <span className="doc">{document.name}</span>
            </Grid>

            {
              showCheckBox && (
                <Grid
                  container
                  item
                  xs={3}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <CheckBox
                      style={{ marginRight: "0px" }}
                      checked={hasNom151}
                      setChecked={handleCheckNom}
                    />
                  </Grid>
                </Grid>
              )
            }

            <Grid item xs={12}>
              <TextFieldNative
                label="Nombre de la actividad*"
                value={name}
                message={nameValidation}
                onChange={(e) => setName(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldNative
                label="Descripción (opcional)"
                value={description}
                message={""}
                onChange={(e) => setDescription(e)}
              />
            </Grid>
            <Grid item xs={12}>
              {
                load && (
                  <div className="col-md-12 text-center">
                    <strong className="mb-2">Procesando su documento, espere por favor</strong>
                    <LinearProgress
                      className="primary-color-bar"
                      color="primary"
                    />
                  </div>
                )
              }
              {
                !load && (
                  <div className={classes.accionsContainer}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <SelectedUsersSimbols
                        users={file.reviewers}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenUsers(true)}
                      >
                        Firmantes
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => getPdf()}
                      disabled={file.reviewers.length === 0}
                    >
                      Posicionar Etiquetas
                    </Button>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <SelectedUsersSimbols
                        users={file.externals}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenExternal(true)}
                        disabled={file.reviewers.length === 0}
                      >
                        Destinatarios No Firmantes  (opcional)
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenSignNotification(true)}
                      disabled={file.reviewers.length === 0}
                    >
                      Notificación y recordatorios (opcional)
                    </Button>
                  </div>
                )
              }
            </Grid>


            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                  className="font-color mr-2"
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={load}
                >
                  Cancelar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={load || !usersToSign.every(filterItem) || !stickersValue}
                >
                  Aceptar
                </Button>
              </div>
            </Grid>

          </Grid>
        </DialogContent>
      </Dialog>

      {
        openUsers && (
          <PopUpSignersUsers
            open={openUsers}
            onCancel={() => setOpenUsers(false)}
            onSubmit={(users, withOrder) => addUsersToFile(users, withOrder)}
            checkedOrder={file.withOrder}
            documentId={document.file.documentId}
            hasNom151={hasNom151}
          />
        )
      }
      {
        openExternal && (
          <PopUpExternalUser
            open={openExternal}
            onSubmit={(users) => addExternalsToFile(users)}
            onCancel={() => setOpenExternal(false)}
            documentId={document.file.documentId}
          />
        )
      }

      {
        openSignNotification && (
          <SignatureNotificationConfig
            open={openSignNotification}
            onSave={(data) => handleSaveNotifications(data)}
            onCancel={() => setOpenSignNotification(false)}
            prev={file.notifications ?? {}}
            documentId={document.file.documentId}
          />
        )
      }

      <DocumentSignatures
        open={openSignDialog}
        onClose={() => setOpenSignDialog(false)}
        signers={file.reviewers}
        url={filePdf}
        documentName={titlePdf}
        onSaveSigns={(stickers) => setStickersValue(stickers)}
        stickersValue={stickersValue}
      />

      <Alert
        open={openAlert}
        title={message}
        onConfirm={() => {
          handleCancel();
        }}
        type={typeMessage}
      />

    </>

  )
}
