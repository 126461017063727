import React, {useState, useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ValidateInput from '../utils/ValidateInput'
import LinearLoading from '../utils/LinearLoading'
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { GestorCapitalHttp } from '../../core/http/gestor-capital.http';
import './inversores.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'red',
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {padding: theme.spacing(1)},
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {margin: 0, padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DialogAddAction(props) {
    const [open, setOpen] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [sizeShareholder, setSizeShareholder] = React.useState();
    const [touched, setTouched] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [shareholder, setShareholder] = React.useState({
        name: "", amount: "" ,stocks: "", companyId: "", account:[]});

    useEffect(() => {
        setSizeShareholder(props.data);
        setIdCompany(props.idCompany.id);

        //setArray(props.data);
    }, [props]);
   
    const gestorCapitalHttp = new GestorCapitalHttp();

    const change = (key,value) => {
        const temp = {...shareholder};
        temp[key] = value;
        temp["companyId"] = props.idCompany.id;

        setShareholder(temp);       
    };

    const setIdCompany = (value) => {
        const temp = {...shareholder};
        temp["companyId"] = value;
        setShareholder(temp);       
    };

    const changeStockW = (key, value , id ) => {
        const temp = {...shareholder};
        
        let account = temp.account === undefined ? [] : temp.account ;
        let obj = {
            shareHolderId : id,
            stocks  : value,
        };

       let objTemp =  account.filter( a => a.shareHolderId === obj.shareHolderId);
       
       if(objTemp.length <= 0){
           account.push(obj)
       }else{
        account.map((srch,i)=>{
            if(srch.shareHolderId === obj.shareHolderId){
                account[i].stocks = obj.stocks;
            }
        })
       }

        temp.account = account.length > 0 ?  account : [];
        
        setShareholder(temp);     

    };

    const clearState = () => {
        setShareholder({name: "", amount:"",stocks:""});
        setErrorMessage("")
        setTouched("");
    };

    const handleClickOpen = () => {
        clearState();
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const saveUser = () => {
        setLoad(true);

     if(!shareholder.name.length || !shareholder.amount.length || !shareholder.stocks.length || !shareholder.account.length) {
             setLoad(false);
                 setTouched(true);
                     setErrorMessage("Complete todos los campos");
         } 
             else {        
                 gestorCapitalHttp.createStock(
                    shareholder,
                 (resp) => {
                     setLoad(false);
                         setOpen(false);
                             handlePopulate();
                                 clearState();

                 }, 
                 (error) => {
                    handleError(error.response.data)
                }, 
             );
         }
    };
    
    const handlePopulate = () => {
        props.refresh();
    };

    const handleError = (error) => {
        const err = error.message
        setLoad(false);
        setTouched(true);
        setErrorMessage(err!=null ? err : "Ocurrio un error");

    };
//console.log("SATETATA ",shareholder)
//console.log("SATETATA props",props)

    return (
        <div>
            <Button
                variant='contained'
                color='primary'
                className="w-100"
                disabled={props.proccesStart}
                onClick={handleClickOpen}>
                    +  Agregar Tipo Accion
            </Button>
            <Dialog onClose={handleClose} fullWidth={true} style={{borderRadius:'10px'}}
                maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle style={{backgroundColor: 'white',borderRadius:'10px'}} id="customized-dialog-title" >
          Agregar tipo de acciòn
                </DialogTitle>
                <LinearLoading show={load} delay={15}/>
                <DialogContent style={{backgroundColor: 'white'}} >
                    <Typography component={'span'}>
                        <List>
                            <div style={{marginTop: '2rem'}}>
                                 <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                    <ValidateInput invalid={touched && !shareholder.name} message='Este campo es requerido'>                                
                                        <TextField fullWidth required value={shareholder.name} onChange={(e)=>change('name', e.target.value)} id="name" label="Nombre de la acciòn" variant="outlined" />
                                    </ValidateInput>
                                </div>
                                <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                    <ValidateInput invalid={touched && !shareholder.stocks} message='Este campo es requerido'>                                
                                        <TextField fullWidth required value={shareholder.stocks} onChange={(e)=>change('stocks', e.target.value)} id="stocks" label="Cantidad de acciones emitidas" variant="outlined" />
                                    </ValidateInput>
                                </div>
                                <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>
                                    <ValidateInput invalid={touched && !shareholder.amount} message='Este campo es requerido'>                                
                                        <TextField fullWidth required value={shareholder.amount} onChange={(e)=>change('amount', e.target.value)} id="amount" label="Valor nominal de la accion" variant="outlined" />
                                    </ValidateInput>
                                </div>

                                {
                                    sizeShareholder ?
                                                sizeShareholder.map((option,idx) => (
                                                    <div style={{paddingBottom: '1rem', paddingLeft: '.3rem'}}>                                                   
                                                        <Tooltip title={"Accionista "+option.name}>  
                                                            <TextField fullWidth value={option.accion} name={"accion"+idx} onChange={(e)=>changeStockW("stock"+idx , e.target.value ,option.id,idx)} required label={"Cantidad acciones del accionista "+ option.name} variant="outlined" />
                                                        </Tooltip>
                                                    </div>
                                                ))
                                                : " "
                                            }
                            </div>     
                        </List>
                    </Typography>
                                    {errorMessage &&
                            <Alert className="top"
                                severity='error'
                                onClose={() => setErrorMessage(null)}>
                                { errorMessage }
                            </Alert>
                                    }
                </DialogContent>
                <DialogActions style={{backgroundColor: 'white'}}>
                        <Button autoFocus variant="outlined" onClick={handleClose} color="secondary">
                                Cancelar
                        </Button>
                        <Button autoFocus variant="contained" onClick={saveUser} color="primary">
                                Agregar Nuevo Tipo
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}