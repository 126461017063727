import { axiosBusinessRequest, authHeader } from '../../configs/axios.config';

export class BusinessHttp {

  findAllBusiness(success, error) {
    axiosBusinessRequest.get('/companies', { headers: authHeader() })
      .then(({ data }) => {
        const out = [];

        data.forEach(business => {
          const usersData = [];

          if (business.usersInfo) {
            business.usersInfo.forEach(user => {
              if ((!user.delete) && user.enabled) {
                usersData.push({
                  ...user,
                  label: user.username,
                  value: user.id
                });
              }
            });
          }

          out.push({
            id: business.id,
            name: business.name,
            users: usersData,
            tags: business.tags ? business.tags.map(tag => ({
              label: tag,
              value: tag
            })) : [],
            enabled: !business.enabled,
          });
        });

        success(out);
      }).catch((respErr) => {
        error(respErr);
      });
  }

  allCompaniesByUser() {
    return axiosBusinessRequest.get('/companies/all-companies-by-user', { headers: authHeader() });
  }

  findBusinessByUid(uid, success, error) {
    axiosBusinessRequest.get(`/companies/${uid}`, { headers: authHeader() })
      .then(({ data }) => {
        success(data);
      }).catch((respErr) => {
        error(respErr);
      });
  }

  findBusinessById = (id, success, error) => {
    axiosBusinessRequest.get(`/companies/${id}`, { headers: authHeader() })
      .then((response) => { success(response.data) })
      .catch((respErr) => error(respErr))
  }

  saveBusiness({ name, users, tags }, success, error) {
    const usersData = [];
    const tagsData = [];

    if (users) {
      users.forEach(({ value }) => {
        usersData.push(value);
      });
    }

    if (tags) {
      tags.forEach(({ label }) => {
        tagsData.push(label);
      })
    }


    axiosBusinessRequest.post('/companies', {
      name: name.trimStart().trimEnd(),
      users: usersData,
      tags: tagsData,
    }, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  async updateBusiness({ id, name, users, tags }, success, error) {
    const usersData = [];
    const tagsData = [];
    let thereWasError = false

    if (users) {
      users.forEach(user => {
        usersData.push(user.value);
      })
      await axiosBusinessRequest.put(`/companies/assign-users-rol/${id}`, { users: usersData },
        { headers: authHeader() }).then(({ data }) => {
          if (data.code !== 200) {
            thereWasError = true
            success(data)
          }
        });
    } else {
      await axiosBusinessRequest.put(`/companies/assign-users-rol/${id}`, { users: [] },
        { headers: authHeader() });
    }

    if (thereWasError) {
      return
    }

    if (tags) {
      tags.forEach(tag => {
        tagsData.push(tag.label);
      });
    }

    axiosBusinessRequest.put(`/companies/${id}`, {
      name: name.trimStart().trimEnd(),
      enabled: true,
      tags: tagsData,
    }, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  assignUsers(id, users, success, error) {
    axiosBusinessRequest.put(`/companies/assign-users-rol/${id}`, {
      users
    }, { headers: authHeader() })
      .then((resp) => success(resp))
      .catch((errorRep) => error(errorRep))
  }

  disableBusiness(business, success, error) {
    axiosBusinessRequest.put(`/companies/enabled/${business.id}`, {
      enabled: business.enabled
    }, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  getActiveCompanies(success, error) {
    axiosBusinessRequest.get(`/companies/actives`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  findAllCompaniesUser(success, error) {
    axiosBusinessRequest.get(`/companies/all-companies-by-user`, { headers: authHeader() })
      .then(({ data }) => {
        const out = [];

        data.forEach((item) => {
          out.push({
            value: item.companyId,
            label: item.name
          });
        });

        success(out);
      }).catch(errorResp => { error(errorResp) });
  }

  findAllEnabledDisabledCompaniesByUser(success, error) {
    axiosBusinessRequest.get(`/companies/get-all-companies-by-user`, { headers: authHeader() })
      .then(({ data }) => {
        const out = [];

        data.forEach((item) => {
          out.push({
            value: item.companyId,
            label: item.name
          });
        });

        success(out);
      }).catch(errorResp => { error(errorResp) });
  }

  getUsersByCompanyId(companyId) {
    if (companyId) {
      return axiosBusinessRequest.get(`/companies/get-users-by-company-id/${companyId}`, { headers: authHeader() });
    }

    return axiosBusinessRequest.get(`/companies/get-users-by-company-id`, { headers: authHeader() });
  }

  allowSaveCompanies() {
    return axiosBusinessRequest.get(`/companies/allow-save-company`, { headers: authHeader() });
  }

}
