import { authHeader, axiosRequestBlockChain, azureInstance } from "../../../configs/axios.config"
import { sortDescObjectsByValue } from "../../utils";



export const getDocumentInfo = async (documentId) => {
  try {
    const { data } = await axiosRequestBlockChain.get(`/document/details/?documentId=${documentId}`, { headers: authHeader() });
    const itemInfo = {
      author: data.UserBy ? data.UserBy.completeName : '',
      hash: data.Hash,
      description: data.Description,
      extension: data.Extension,
      isFolder: false,
      statusBpm: data.StatusBpm,
      size: data.Size,
      hasNom151: data.hasNom151
    }


    const history = data.DocumentHistory.map(history => ({
      user: history.UserBy ? history.UserBy : '',
      date: history.UpdateAt,
      action: history.Action,
      StatusBpm: history.StatusBpm,
      isFolder: false,
    }));

    const uniqueAction = history.filter((elem, index) =>
      history.findIndex(obj => obj.user === elem.user && obj.action === "firmó el " && obj.StatusBpm !== "Aprobado Con Firma") === index);

    const info = history.filter(item => item.action !== "firmó el ");

    const formatInfo = info.concat(uniqueAction);



    return {
      itemInfo,
      history: sortDescObjectsByValue(formatInfo, 'date')
    }

  } catch (error) {
    console.error(error)
    throw 'Error obteniendo informacion del documento'
  }
}

export const moveDocument = async (documentId, body) => {
  try {

    const { data } = await axiosRequestBlockChain.post(`/document/move/${documentId}`, body, { headers: authHeader() });

    return data;

  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
}

export const editDocumentInformation = async (data) => {
  try {
    const { data } = await axiosRequestBlockChain.put(`/document/information`, data, { headers: authHeader() });

    return data;
  } catch (error) {
    throw 'Error editando el documento'
  }
}

export const getPendingDocuments = async () => {
  try {
    const { data } = await axiosRequestBlockChain.get(`/pending/activities`, { headers: authHeader() });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const deleteFromStorageProvider = async (documentName) => {
  try {
    const { data } = await axiosRequestBlockChain.delete(`/delete-from-storage-provider/${documentName}`, { headers: authHeader() });
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const getSaveRequest = async (extension) => {
  try {
    const { data } = await azureInstance.get(`/get-cofig-request?extension=${extension}`, { headers: authHeader() });
    return data;
  } catch (error) {
    console.error(error);
  }
}