import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import { connect } from 'react-redux';

import NavBarMenu from "../main-menu/nav-bar";
import "./home.css";

import { authActions, alertActions } from '../../core/actions'

import DashboardIcon from "@material-ui/icons/Dashboard";
import BookIcon from "@material-ui/icons/Book";
import LockIcon from "@material-ui/icons/Lock";
import GroupIcon from "@material-ui/icons/Group";
import DashboardRouting from "../../core/routes/dashboard-routing.routes";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { Alert } from "axeleratum-sgc-frontend-library";
import logoKasia from '../../assets/img/logoKasiaHorizontalFondoBlanco.png';
import { permissionGroups } from "../../core/utils/can-i";

const menuItems = [
  {
    text: "Mi tablero",
    name: "dashboard",
    icon: DashboardIcon,
    link: "/home",
    enabled: true,
    groups: []
  },
  {
    text: "Plantillas",
    name: "templates",
    icon: BookIcon,
    link: "/templates",
    enabled: false,
    groups: [(permissionGroups.templateManagement.groupName)]
  },
  {
    text: "Bóveda",
    name: "boveda",
    icon: LockIcon,
    link: "/vault",
    enabled: false,
    groups: [(permissionGroups.documentManagement.groupName)]
  },
  {
    text: "ID Mgmt",
    name: "idmgmt",
    icon: AssignmentIndIcon,
    link: "/home",
    enabled: true,
    groups: []
  },
  {
    text: "Equipo",
    name: "equipo",
    icon: GroupIcon,
    link: "/team",
    enabled: false,
    groups: [
      (permissionGroups.userManagement.groupName),
      (permissionGroups.roleManagement.groupName),
      (permissionGroups.businessManagement.groupName)
    ]
  },
];

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      itemSelected: "",
      menu: [...menuItems],
    };
  }

  handleLogout = () => {
    const { logout } = this.props;
    this.props.history.push(`/${sessionStorage.getItem("clientKey")}`);
    logout();
  }

  render() {
    const { itemSelected, menu } = this.state;
    const { alert, currentUser } = this.props;

    return (
      <React.Fragment>
        <div className={"home"} style={{ display: "flex" }}>
          <NavBarMenu
            menuItems={menu}
            itemSelected={itemSelected}
            onLogout={() => { this.setState({ menu: [] }, () => this.handleLogout()) }}
            userName={currentUser.completename}
          />
          <Container
            className="main-container"
            style={{
              overflow: "hidden",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column"
            }}
            maxWidth="lg"
          >

            <Alert
              open={alert.message ? true : false}
              title={alert.message}
              type={alert.type}
              onConfirm={() => this.props.closeAlert()}
            />
            <DashboardRouting {...this.props} />
            <div className="footer">
              <Grid container item xs={12} className="container-footer" justify="center" alignItems="center">
                <img alt="kasia" style={{ height: '40px' }} src={logoKasia} />
              </Grid>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    keep_active_session: state.authReducer.keep_active_session,
    loggedIn: state.authReducer.loggedIn,
    alert: state.alertReducer,
    currentUser: state.authReducer.currentUser
  }
}

const mapDispatchToProps = {
  logout: authActions.logout,
  refreshToken: authActions.refreshToken,
  closeAlert: alertActions.close,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
