import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userConstants } from "../core/constants";
import { allCompaniesByUser } from "../core/http/functionRequests/companies.http";


export const useCompaniesUser = () => {

  const companies = useSelector(state => state.userReducer.companies);
  const dispatch = useDispatch();
  
  const getCompanies = async () => {
    try {
      const request = await allCompaniesByUser();

      dispatch({
        type: userConstants.SET_AVAILABLE_COMPANIES,
        payload: request
      });

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(!companies){
      getCompanies();
    }
    // eslint-disable-next-line
  }, [])
  

  return {
    companies
  }

}