import React, { Component } from "react";
import { Checkbox, Button, Grid, Box, Typography } from "@material-ui/core";
import { NotificationHttp } from "../../../core/http/notification.http";
import { uuid } from "axeleratum-sgc-frontend-library";
import { ContactlessOutlined } from "@material-ui/icons";

export class Configurations extends Component {
  notificationHttp = new NotificationHttp();

  constructor(props) {
    super(props);

    this.state = {
      load: false,
      configurations: [],
    };
  }

  componentDidMount() {
    this.findConfigurations();
  }

  findConfigurations() {
    this.setState({ load: true });

    this.notificationHttp
      .findConfigurations()
      .then(({ data }) => {
        this.setState({ load: false, configurations: data });
      })
      .catch((ex) => {
        this.setState({ load: false });
      });
  }

  saveConfigurations() {
    const { configurations } = this.state;
    const { onClose } = this.props;

    this.notificationHttp
      .saveConfigurations(configurations)
      .then(() => {})
      .catch(() => {});

    onClose();
  }

  onChangeConfig(id) {
    const { configurations } = this.state;
    let configuration = configurations.find((itemFind) => itemFind.id === id);

    if (configuration) {
      configuration.active = !configuration.active;
    }

    this.setState({ configurations });
  }

  render() {
    const { load, configurations } = this.state;

    return (
      <React.Fragment>
        <Box mx={3}>
          <Grid container xs={12}>
            <Box xs={12} style={{ marginTop: '0px' }}>
              <h4>Notificaciones</h4>
            </Box>

            <Grid item xs={12}>
              <b>Opciones para recibir notificaciones</b>
            </Grid>

            <Grid container direction="column">
              {load ? (
                <Grid item xs={12}>
                  <i className="fa fa-spinner fa-spin primary-text"></i>
                  Cargando opciones...
                </Grid>
              ) : (
                configurations.map((configuration) => (
                  <Grid item key={uuid()}>
                    <Checkbox
                      checked={configuration.active}
                      value="gilad"
                      onChange={() => this.onChangeConfig(configuration.id)}
                    />

                    <Typography style={{ display:'inline' }} paragraph variant={'subtitle2'}>{configuration.type.name}</Typography>
                  </Grid>
                ))
              )}
            </Grid>

            <Grid xs={12} container alignItems="center" justifyContent="center">
              <Box my={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.saveConfigurations()}
                >
                  Continuar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }
}
