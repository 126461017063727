import React, { Component } from "react";
import { Button, Chip, Dialog, Menu, MenuItem, Box } from "@material-ui/core";
import { uuid, DocumentPdfViewer } from "axeleratum-sgc-frontend-library";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { DocumentsHttp } from "../../../../core/http/documents.http";
import { Alert } from "axeleratum-sgc-frontend-library";
import saveAs from "file-saver";
import { ConfirmDialog } from "./confirm-dialog";
import { CalendarHttp } from "../../../../core/http/calendar.http";
import DocumentReview from "../../../vault/document-review";
import {
  validatePermissions,
  permissionScheme,
} from "../../../../core/utils/can-i";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

class ActivityComponent extends Component {
  colors = ["#9C27B0", "#FF9800", "#00BCD4", "#009688", "#F4511E"];
  documentsHttp = new DocumentsHttp();
  calendarHttp = new CalendarHttp();
  
  constructor(props) {
    super(props);
    
    this.state = {
      showAll: this.props.expand,
      anchorEl: null,
      anchorEl2: null,
      open: props.open,
      openPdf: false,
      openAlert: false,
      typeMessage: "",
      message: "",
      openConfirmAlert: false,
      openConfirmAlert2: false,
      loadFile: false,
      openDocumentReview: false,
      documentReview: null,
      openUpdate: false,
      selectItem: null,
      enableReview: false,
      enableSign: false,
      documentIdOpen: null,
    };
  }

  componentDidMount() {
    this.getPermissions();
  }

  getPermissions() {
    validatePermissions(permissionScheme.documentManagement.signDocuments).then(
      (enabled) => {
        this.setState({ signDocumentsIsEnabled: enabled });
      }
    );
    validatePermissions(
      permissionScheme.documentManagement.deleteActivity
    ).then((enabled) => {
      this.setState({ deleteActivityIsEnabled: enabled });
    });
  }

  limitText(description) {
    if (!description) {
      return "";
    }

    if (description.length > 200) {
      return `${description.substring(0, 200)}...`;
    }

    return description;
  }

  createChips(chip, colorIndex) {
    const out = (
      <Chip
        key={uuid()}
        className="mb-2 mr-1"
        label={chip}
        style={{
          background: this.colors[colorIndex.i],
          color: "#fff",
        }}
      />
    );

    if (colorIndex.i == 2) {
      colorIndex.i = 0;
    }

    colorIndex.i++;
    return out;
  }

  openDocument(documentId) {
    this.setState({ loadFile: true });
    this.checkReviewOrSign(documentId);
    this.documentsHttp.downloadDocumentById(
      documentId,
      (resp, extension) => {
        this.setState({ loadFile: false });
        if (extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );
          this.setState({
            filePdf: url,
            titlePdf: document.name,
            openPdf: true,
            documentIdOpen: documentId,
          });
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${document.name}${extension}`);
        }
      },
      (error) => {
        this.setState({
          openAlert: true,
          typeMessage: "error",
          message: "Ocurrió un error al abrir documento. Intente más tarde.",
          loadFile: false,
        });
      }
    );
  }

  handleReview(item) {
    this.setState({
      documentReview: { id: item.documentId, name: item.documentName },
      openDocumentReview: true,
    });
  }

  checkReviewOrSign(documentId) {
    this.calendarHttp.checkReviewOrSign(
      documentId,
      (resp) => {
        this.setState({ enableReview: resp.review, enableSign: resp.sign });
      },
      (error) => {}
    );
  }

  render() {
    const {
      showAll,
      open,
      openPdf,
      filePdf,
      titlePdf,
      openAlert,
      typeMessage,
      message,
      anchorEl,
      anchorEl2,
      openConfirmAlert,
      openConfirmAlert2,
      loadFile,
      openDocumentReview,
      documentReview,
    } = this.state;
    const {
      date,
      hour,
      name,
      description,
      tags,
      recordatory,
      type,
      documents,
      activityId,
      onExitDetail,
      item,
      onSelect,
      seMore,
      onDrop,
      currentUser,
    } = this.props;
    let colorIndex = { i: 0 };

    return (
      <Box
        className="Activity"
        mt={4}
        sx={{
          borderColor: "#ccc",
          borderStyle: "solid",
          borderRadius: "5px",
          borderWidth: "1px",
        }}
      >
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          {/* Se oculta menú para demostración */}

          <MenuItem
            onClick={() => {
              onSelect(item);
              this.setState({ anchorEl: null });
            }}
            disabled={item.statusBpm === "Rechazo de Firma" || item.erasable}
          >
            Editar
          </MenuItem>

          <MenuItem
            disabled={
              !(
                this.state.deleteActivityIsEnabled ||
                currentUser.user_name == item.username
              ) ||
              item.erasable ||
              item.statusBpm === "Rechazo de Firma"
            }
            onClick={() => this.setState({ openConfirmAlert2: true })}
          >
            Eliminar
          </MenuItem>
        </Menu>

        <Alert
          open={openAlert}
          title={message}
          onConfirm={() => this.setState({ openAlert: false })}
          type={typeMessage}
        />

        <ConfirmDialog
          open={openConfirmAlert2}
          title="¿Seguro que quieres eliminar la actividad?"
          onConfirm={() => {
            this.setState({ openConfirmAlert2: false });
            onDrop(activityId);
          }}
          onCancel={() => this.setState({ openConfirmAlert2: false })}
        />

        <Dialog className="activity-detail" open={open}>
          <Alert
            open={openAlert}
            title={message}
            onConfirm={() => this.setState({ openAlert: false })}
            type={typeMessage}
          />

          <ConfirmDialog
            open={openConfirmAlert}
            title="¿Seguro que quieres eliminar la actividad?"
            onConfirm={() => {
              new CalendarHttp()
                .deleteActivity(activityId)
                .then((resp) => {
                  this.setState({
                    openAlert: true,
                    typeMessage: "success",
                    message: "Actividad eliminada correctamente",
                    openConfirmAlert: false,
                  });
                })
                .catch((err) => {
                  this.setState({
                    openAlert: true,
                    typeMessage: "error",
                    message: "No se pudo eliminar la actividad",
                    openConfirmAlert: false,
                  });
                });
            }}
            onCancel={() => this.setState({ openConfirmAlert: false })}
          />

          <DocumentPdfViewer
            url={filePdf}
            title={titlePdf}
            open={openPdf}
            onClose={() => {
              this.setState({
                openPdf: false,
                enableReview: false,
                enableSign: false,
              });
            }}
            review={this.state.enableReview}
            onReview={() => {
              this.setState({
                documentReview: { id: this.state.documentIdOpen },
                openDocumentReview: true,
              });
            }}
            sign={this.state.enableSign}
            onSign={() => {
              new CalendarHttp()
                .getFirm(this.state.documentIdOpen)
                .then(({ data }) => {
                  window.open(data.documentUrl);
                })
                .catch((error) => {
                  this.setState({
                    openAlert: true,
                    typeMessage: "error",
                    message: "Tuvimos problemas para conectarnos con FirmaMex",
                  });
                });
            }}
          />

          <Menu
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={() => this.setState({ anchorEl2: null })}
          >
            <MenuItem onClick={() => this.setState({ openConfirmAlert: true })}>
              Eliminar
            </MenuItem>
          </Menu>

          <div className="Activity col-md-12 col-xs-12">
            <div className="row header">
              <div className="col-md-9 col-xs-9 text-color mt-1">
                <strong className="fa fa-archive mr-1"></strong>
                {date}
                <strong className="fas fa-clock ml-2 mr-1"></strong>
                {hour}
                <strong className="fas fa-bell ml-2 mr-1"></strong>
                {recordatory}d
              </div>

              <div className="col-md-3 col-xs-3 text-right">
                <Button
                  className="button-options"
                  onClick={(evt) =>
                    this.setState({ anchorEl2: evt.currentTarget })
                  }
                >
                  <i className="fas fa-ellipsis-v primary-text"></i>
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <h4>{name}</h4>
              </div>

              <div className="col-md-6 text-right mt-2">
                <Chip
                  label={type == "firm" ? "Firma" : "Revisión"}
                  style={{
                    background: type == "firm" ? "#E65100" : "#009688",
                    color: "#fff",
                  }}
                />
              </div>

              <div className={`col-md-12 ${showAll && "hide"}`}>
                {this.limitText(description)}
              </div>

              <div className={`col-md-12 ${!showAll && "hide"}`}>
                {description}
              </div>
            </div>

            <div
              className="row mt-3 p-2"
              style={{ height: tags ? tags.length == 0 && "60px" : "60px" }}
            >
              {tags && tags.map((chip) => this.createChips(chip, colorIndex))}
            </div>

            <div className="row table-size-scroll">
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Nombre</strong>
                      </TableCell>
                      
                      <TableCell>
                        <strong>Trazabilidad</strong>
                      </TableCell>

                      <TableCell>
                        <strong>Invitados</strong>
                      </TableCell>

                      <TableCell>
                        <strong>
                          {item.type == "firm" ? "Firmado" : "Revisión"}
                        </strong>
                      </TableCell>

                      <TableCell>
                        <strong>Creado</strong>
                      </TableCell>

                      <TableCell>
                        <strong>Fecha Limite</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {documents &&
                      documents.map((item) => (
                        <TableRow key={uuid()}>
                          <TableCell>
                            <button
                              title={item.documentName}
                              className="link"
                              onClick={() => this.openDocument(item.documentId)}
                            >
                              {loadFile && (
                                <i className="fa fa-spinner fa-spin"></i>
                              )}
                              {item.documentName.length > 12
                                ? `${item.documentName.substring(0, 12)}...`
                                : item.documentName}
                            </button>
                          </TableCell>

                          <TableCell>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                console.log('Mostrar detalle');
                                console.log(this.props);
                              }}
                            >
                              Ver
                            </Button>
                          </TableCell>

                          <TableCell>
                            {item.signerReviewer.map((user, index) => (
                              <div
                                className={`${index >= 3 ? "" : "col-md-1"}`}
                                key={uuid()}
                              >
                                {index >= 3 ? undefined : index == 2 ? (
                                  <div
                                    style={{
                                      marginLeft: `-${8 * index}px`,
                                      background: user.color,
                                    }}
                                    className="text-center circle-user"
                                  >
                                    {item.signerReviewer.length + 1 - 3}+
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      marginLeft: `-${8 * index}px`,
                                      background: user.color,
                                    }}
                                    className="text-center circle-user"
                                  >
                                    {user.name.toString().substring(0, 1)}
                                  </div>
                                )}
                              </div>
                            ))}
                          </TableCell>

                          <TableCell>
                            {type == "firm" ? (
                              <Button
                                color="primary"
                                disabled={!this.state.signDocumentsIsEnabled}
                                variant="contained"
                                onClick={() => {
                                  new CalendarHttp()
                                    .getFirm(item.documentId)
                                    .then(({ data }) => {
                                      window.open(data.documentUrl);
                                    })
                                    .catch((error) => {
                                      this.setState({
                                        openAlert: true,
                                        typeMessage: "error",
                                        message:
                                          "Tuvimos problemas para conectarnos con FirmaMex",
                                      });
                                    });
                                }}
                              >
                                Firmar
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  this.handleReview(item);
                                }}
                              >
                                Revisiones
                              </Button>
                            )}
                          </TableCell>

                          <TableCell>
                            {item.createdDate
                              ? moment(item.createdDate).format("YYYY-MM-DD")
                              : "-"}
                          </TableCell>

                          <TableCell>{date}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 text-right">
                <Button
                  className="primary-text"
                  onClick={() => {
                    this.setState({ open: !open });
                    onExitDetail && onExitDetail();
                  }}
                >
                  Ver menos
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <div className="row header">
          <div className="col-xs-10 text-color mt-1">
            <strong className="fa fa-archive mr-1"></strong>
            {date}
            <strong className="fas fa-clock ml-2 mr-1"></strong>
            {hour}
            <strong className="fas fa-bell ml-2 mr-1"></strong>
            {recordatory}d
          </div>

          <div className="col-xs-2 text-right">
            <Button
              className="button-options"
              onClick={(evt) => this.setState({ anchorEl: evt.currentTarget })}
            >
              <i className="fas fa-ellipsis-v primary-text"></i>
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-6">
            <h4>{name}</h4>
          </div>

          <div className="col-md-6 col-xs-6 text-right">
            <Chip
              label={type == "firm" ? "Firma" : "Revisión"}
              style={{
                background: type == "firm" ? "#E65100" : "#009688",
                color: "#fff",
              }}
            />
          </div>

          <div className={`col-md-12 ${showAll && "hide"}`}>
            {this.limitText(description)}
          </div>

          <div className={`col-md-12 ${!showAll && "hide"}`}>{description}</div>
        </div>

        <div className="row mt-3 p-2">
          {tags && tags.map((chip) => this.createChips(chip, colorIndex))}
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-right">
            <Button className="primary-text" onClick={() => seMore(item)}>
              Ver mas
            </Button>
          </div>
        </div>

        {openDocumentReview && (
          <DocumentReview
            openDialog={openDocumentReview}
            onClose={() => {
              this.setState({ openDocumentReview: false });
            }}
            document={documentReview}
          />
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
  };
};

export const Activity = connect(mapStateToProps)(ActivityComponent);
