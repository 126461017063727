import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "../utils/ConfirmDialog";
import HomeVault from "../kaleido-vualt/home-vault.jsx";
import getWeb3 from "../blockchain/getWeb3.js";
import { Button, Grid } from "@material-ui/core";
import AddUser from "./add-shareholder.jsx";
import EditUser from "./edit-shareholder.jsx";
import AddAction from "./add-action-shareholder.jsx";
import EditAction from "./edit-action-shareholder.jsx";
import { GestorCapitalHttp } from "../../core/http/gestor-capital.http";
import { Alert } from "axeleratum-sgc-frontend-library";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./inversores.scss";

export default class Inversor extends Component {
  gestorCapitalHttp = new GestorCapitalHttp();

  constructor(props) {
    super(props);
    this.state = {
      companyID: this.props.idCompany.id,
      messageDialog: " ",
      dataShareholder: [],
      stockShareholder: [],
      totalShareholder: [],
      totalStocks: [],
      proccesStart: false,
      proccesFinalize: false,
      homeOpenVault: false,
      openAlert: false,
      message: null,
      typeMessage: null,
      loading: false,
    };
  }

  async componentDidMount() {
    this.findShareholders();
    this.checkStatusProcces();
    this.checkDocumentExist();

    // this.web3 = await getWeb3();
    // var account = ( await this.web3.eth.accounts)
    // var accounts = ( await this.web3.eth.getAccounts())[0];
    //const accountsw = (await this.web3.ethereum.request({ method: 'eth_accounts' }));

    //console.log("WEB3 JS : ",this.web3.version);
    //console.log("WEB3 JS : ",account);
    //console.log("WEB3 JS x: ",accountsw);
  }

  async checkDocumentExist() {
    let documents = localStorage.getItem("documentsCapital");
    if (documents === "initProccess") {
      this.setState({ loading: false });
    }
  }

  async findShareholders() {
    let id = this.state.companyID;

    this.gestorCapitalHttp.getShareHolders(
      id,
      (resp) => {
        this.setState({
          dataShareholder: resp.shareHolders,
          stockShareholder: resp.stocks,
          totalShareholder: resp.totals,
          totalStocks: resp.totals.actions,
        });
      },
      () => { }
    );
  }

  async finalizeProccess() {
    let id = this.state.companyID;

    this.gestorCapitalHttp.finalizeProcces(
      id,
      (resp) => {
        this.setState({
          proccesFinalize: true,
          confirmProcces: false,
          openAlert: true,
          message: "Proceso Finalizado",
          typeMessage: "success",
        });
      },
      () => { }
    );
  }

  async checkStatusProcces() {
    let id = this.state.companyID;

    this.gestorCapitalHttp.checkStatusProcces(
      id,
      (resp) => {
        this.setState({ proccesStart: resp.processing });
      },
      () => { }
    );
  }

  refreshState() {
    this.findShareholders();
    this.checkStatusProcces();
  }
  reloadPage() {
    //window.location.reload(true);
    this.checkStatusProcces();
    setTimeout(() => window.location.reload(true), 30000);
  }

  deleteUser() {
    let { tempData } = this.state;

    this.gestorCapitalHttp.deleteShareHolder(
      tempData.id,
      (resp) => {
        this.refreshState();
        this.closeModalConfimr();
      },
      () => { }
    );
  }

  openModalConfimr = (data) => {
    this.setState({
      confirm: true,
      tempData: data,
      messageDialog: "¿Está seguro que desea eliminar este accionista?",
    });
  };

  closeModalConfimr = () => {
    this.setState({ confirm: false });
  };

  openModalConfimrProcces = (data) => {
    this.setState({ confirmProcces: true, messageDialog: data });
  };

  closeModalConfimrProcces = () => {
    this.setState({ confirmProcces: false });
  };

  render() {
    let {
      dataShareholder,
      totalShareholder,
      totalStocks,
      messageDialog,
      stockShareholder,
      typeMessage,
      message,
      openAlert,
      loading,
    } = this.state;

    const style = {
      text: {
        width: "105",
        height: "auto",
        display: "flex",
        margin: "0px",
      },
      nameDiv: {
        width: "200",
        height: "auto",
        display: "flexBox",
        margin: "0px",
      },
      total: {
        width: "85",
        height: "auto",
        display: "flex",
        margin: "0px",
        paddingTop: "25px",
      },
    };

    return (
      <Grid container>
        <Grid className="mt-3" container spacing={2}>
          <Grid item xs={6} md={8} lg={10}>
            <h4 style={{ marginBottom: 0 }}>{"Gestión de Capital"}</h4>
          </Grid>

          {loading && (
            <div className="blocker">
              <div className="popup">
                <CircularProgress color="secondary" size={88} />
              </div>
            </div>
          )}

          <Alert
            open={openAlert}
            title={message}
            onConfirm={() => {
              this.setState({ openAlert: false, loading: true }, () =>
                this.reloadPage()
              );
            }}
            type={typeMessage}
          />

          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12} id="addUser">
              <AddUser
                proccesStart={this.state.proccesStart}
                idCompany={this.props.idCompany}
                refresh={() => this.refreshState()}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} id="addAction">
              {dataShareholder && dataShareholder.length ? (
                <AddAction
                  proccesStart={this.state.proccesStart}
                  idCompany={this.props.idCompany}
                  data={dataShareholder}
                  refresh={() => this.refreshState()}
                />
              ) : (
                <Button
                  variant="contained"
                  className="w-100"
                  undefined
                  color="primary"
                  disabled="true"
                >
                  + Agregar Tipo Accion
                </Button>
              )}
            </Grid>
            <Grid item md={4} sm={6} xs={12} id="editAction">
              {stockShareholder && stockShareholder.length ? (
                <EditAction
                  proccesStart={this.state.proccesStart}
                  idCompany={this.props.idCompany}
                  data={stockShareholder}
                  refresh={() => this.refreshState()}
                />
              ) : (
                <Button
                  variant="contained"
                  className="w-100"
                  undefined
                  color="primary"
                  disabled="true"
                >
                  + Editar Tipo Accion
                </Button>
              )}
            </Grid>
            {/* <Grid item md={3} sm={6} xs={12} id="finishAction">
              {dataShareholder && dataShareholder.length ? (
                <Button
                  variant="contained"
                  className="w-100"
                  disabled={this.state.proccesStart}
                  onClick={() =>
                    this.openModalConfimrProcces(
                      "¿Esta seguro de terminar el proceso?"
                    )
                  }
                  color="primary"
                >
                  Terminar Proceso
                </Button>
              ) : (
                <Button variant="contained" className="w-100" color="primary" disabled="true">
                  Terminar Proceso
                </Button>
              )}
            </Grid> */}
          </Grid>
        </Grid>

        <Grid
          className="contenedorD"
          style={{
            overflowX: "auto",
            overflowY: "auto",
            height: "auto",
            width: "75rem",
          }}
        >
          {dataShareholder != null
            ? dataShareholder.map((option, idx) => (
              <Grid
                key={option.key}
                style={{ display: "table", backgroundColor: "white" }}
              >
                <section id="accionistas" style={style.text}>
                  <div className="divAccionista">
                    <strong style={{ fontWeight: "bold" }}>
                      Accionista {idx + 1}
                    </strong>
                  </div>
                  <div
                    className="divAccionista"
                    style={{ paddingLeft: "30px" }}
                  >
                    <EditUser
                      proccesStart={this.state.proccesStart}
                      data={option}
                      refresh={() => this.refreshState()}
                    />
                  </div>
                  <div className="divAccionista">
                    <button
                      className="boton"
                      style={{
                        backgroundColor:
                          this.state.proccesStart === true ? "gray" : "",
                      }}
                      disabled={this.state.proccesStart}
                      onClick={() => this.openModalConfimr(option)}
                    >
                      <CloseIcon style={{ color: "white", fontSize: 18 }} />
                    </button>
                  </div>
                  <div
                    className="divAccionista"
                    style={{ paddingLeft: "20px" }}
                  >
                    {option.name}
                    <p style={{ paddingBottom: "-20px" }}>RFC:{option.rfc}</p>
                  </div>
                </section>
                <section id="accionistasInfo" style={style.text}>
                  {option.account.map((data) => (
                    <div style={style.nameDiv}>
                      <div className="div">
                        <div className="subDivName">
                          <label style={{ textAlign: "justify" }}>
                            <strong>{data.stockName}</strong>
                          </label>
                        </div>
                        <div className="subDivInfo">
                          <p>{data.stocks}</p>
                        </div>
                      </div>
                      <div className="div">
                        <div className="subDiv">
                          <label>
                            <strong>{"Importe"}</strong>
                          </label>
                        </div>
                        <div className="subDivInfo">
                          <p>{data.amount}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="div">
                    <div className="subDiv">
                      <label>
                        <strong>{"Importe Total"}</strong>
                      </label>
                    </div>
                    <div className="subDivInfo">
                      <p>{option.total}</p>
                    </div>
                  </div>
                  <div className="div">
                    <div className="subDiv">
                      <label>
                        <strong>{"Porcentaje"}</strong>
                      </label>
                    </div>
                    <div className="subDivInfo">
                      <p>{option.porcentaje}</p>
                    </div>
                  </div>
                </section>
              </Grid>
            ))
            : ""}

          <section id="accionistas" style={style.text}>
            <div className="divAccionista">
              <strong style={{ fontWeight: "bold" }}>Total</strong>
            </div>
          </section>

          {totalStocks && totalStocks.length ? (
            <section id="accionistasInfo" style={style.total}>
              {dataShareholder != null
                ? totalStocks.map((data, idx) => (
                  <div style={style.text}>
                    <div className="div">
                      <div className="subDivTotales">
                        <label>
                          <strong>{data.stock}</strong>
                        </label>
                      </div>
                    </div>
                    <div className="div">
                      <div className="subDivTotales">
                        <label>
                          <strong>{data.amount}</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                ))
                : ""}
              <div style={style.text}>
                <div className="div">
                  <div className="subDivTotales">
                    <label>
                      <strong>{totalShareholder.total}</strong>
                    </label>
                  </div>
                </div>
              </div>
              <div style={style.text}>
                <div className="div">
                  <div className="subDivTotales">
                    <label>
                      <strong>{"100%"}</strong>
                    </label>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          <div>
            {dataShareholder && dataShareholder.length ? (
              ""
            ) : (
              <p>No hay informaciòn para mostrar</p>
            )}
          </div>
        </Grid>
        <ConfirmDialog
          open={this.state.confirm}
          message={messageDialog}
          onConfirm={() => this.deleteUser()}
          onClose={() => this.closeModalConfimr()}
        />
        <ConfirmDialog
          open={this.state.confirmProcces}
          message={messageDialog}
          onConfirm={() => this.finalizeProccess()}
          onClose={() => this.closeModalConfimrProcces()}
        />
        <HomeVault
          open={this.state.homeOpenVault}
          onClose={() => this.setState({ homeOpenVault: false })}
        />
      </Grid>
    );
  }
}
